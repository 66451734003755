import React from 'react'
import { Button, Dropdown, Modal } from 'semantic-ui-react'
import CommonButtons from './CommonButtons'
import close from '../assets/images/close.png'

const AudioInputOutut = ({isModalClose,open}) => {
  return (
    <Modal
    className="send-message-modal"
    onClose={isModalClose}
    closeIcon
    open={open}
    size="tiny"
  >
    {/* <Dimmer active={loader}>
      <Loader />
    </Dimmer> */}
    <div className="modal-header align-items-center">
        
      <h5 className=" mb-0">Audio Input Output Setting</h5>
      <div className="close-icon" onClick={() => isModalClose()}>
        <img src={close} alt="close" />
      </div>
    </div>
    <Modal.Content style={{ padding: '30px' }}>
        <div className='mb-3'>
            <p className='mb-1'>Select Output Device</p>
            <div className='row'>
                <div className='col-md-9'>
                    <Dropdown
                        placeholder={`Select Output Device`}
                        fluid
                        selection />
                </div>
                <Button className='ml-3'>Test</Button>
            </div>
        </div>
        <div className='mb-3'>
            <p className='mb-1'>Select Input Device</p>
            <div className='row'>
                <div className='col-md-9'>
                    <Dropdown
                        placeholder={`Select Input Device`}
                        fluid
                        selection

                    // onChange={this.onPostDataSaveHandler}
                    />
                </div>
            </div>
        </div>
        <div className='mb-3'>
            <p className='mb-1'>Select Ringtone Device</p>
            <div className='row'>
                <div className='col-md-9'>
                    <Dropdown
                        placeholder={`Select Ringtone Device`}
                        fluid
                        selection
                    />
                </div>
                 <Button className='ml-3'>Test</Button>
            </div>
        </div>
    </Modal.Content>
    <Modal.Actions className="send-message__btn">
      <CommonButtons
        content="Refresh Devices"
        style={{ background: '#9da3b1', color: 'white' }}
        // onClick={e => handleMakeCallback()}
      />
    </Modal.Actions>
  </Modal>
  )
}

export default AudioInputOutut