import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Table, Modal, Dimmer, Loader } from 'semantic-ui-react'
import CommonButtons from '../common/CommonButtons'
import { CommonNotify } from '../common/CommonNotify'
import { Button, Link, Menu, MenuItem } from '@mui/material'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

export default function ListCampaign({ onBroadCast }) {
    const navigate= useHistory()
  const [listData, setListData] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [openListModel, setOpenListModel] = useState(false)
  const [saveList, setSaveList] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [listToDelete, setListToDelete] = useState()
  const [listToUpdate, setListToUpdate] = useState()
  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen
  ] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [updatedListName, setUpdatedListName] = useState('')
  const [type, setType] = useState('')
const [contactData, setContactData] = useState([])
const [isConatctOpen, setIsConatctOpen] = useState(null)
  useEffect(() => {
    fetchLists()
    fetchAllContacts()
  }, [])

  const fetchLists = async () => {
    setIsLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/lists`
    axios
      .get(url)
      .then(res => {
        setIsLoading(false)
        setListData(res?.data?.data)
      })
      .catch(error => {
        setIsLoading(false)
      })
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const updateList = async () => {
    setIsEditModalOpen(false)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/list/${listToUpdate}`
    setIsLoading(true)

    const formData = new FormData()
    formData.append('name', updatedListName)

    axios
      .post(url, formData)
      .then(res => {
        if (res.data) {
          const onComplete = () => {
            CommonNotify('Successfully updated list!', 'success')
          }
          fetchLists(onComplete)
          setIsLoading(false)
        }
      })
      .catch(err => {
        CommonNotify(err?.response?.data?.errors?.[0] || "Can't update list!")
        setIsLoading(false)
      })
  }

  const deleteList = async () => {
    setIsDeleteConfirmationModalOpen(false)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/list/${listToDelete}`
    setIsLoading(true)
    axios
      .delete(url)
      .then(res => {
        if (res.data) {
          const onComplete = () => {
            CommonNotify('Successfully deleted list!', 'success')
          }
          fetchLists(onComplete)
          setIsLoading(false)
        }
      })
      .catch(err => {
        CommonNotify(err?.response?.data?.errors?.[0] || "Can't delete list!")
        setIsLoading(false)
      })
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false)
    setUpdatedListName('')
  }
  const createList = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/list`
    axios
      .post(url, {
        name: saveList,
        contactIds: [],
        contact_type: type || 'individual'
      })
      .then(res => {
        if (res.data) {
          setType('')
          fetchLists()
          setOpenListModel(false)
          CommonNotify('Successfully Added!', 'success')
        }
      })
      .catch(err => {
        setType('')
      })
  }
  const fetchAllContacts = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/contacts-all?order_by=name`
    axios
      .get(url)
      .then(res => {
        if (res?.data) {
         setContactData( res.data.data)
        }
      })
      .catch(error => {})
  }
  return (
    <div className="history-campaign">
      <Dimmer active={isLoading} style={{ position: 'fixed' }}>
        <Loader />
      </Dimmer>
      {listData && listData?.length > 0 ? (
        <>
          <div className="d-flex justify-content-between mb-2 pb-3">
            <h3>Lists</h3>
            <CommonButtons
              content="Create a list"
              background="blue"
              btnClass="btn-send"
              onClick={() => setOpenListModel(true)}
            />
          </div>
          <div className="mb-3">
            <p>
              This is where you organise the lists. Create, modify and manage
              custom lists for targeted interactions.
            </p>
          </div>

          <div>
            <Table className="border-0">
              <Table.Header style={{ padding: '12px 15px' }}>
                <Table.Row>
                  <Table.HeaderCell>Lists</Table.HeaderCell>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>No of contacts</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body style={{ padding: '10px 12px' }}>
                {listData &&
                  listData?.length > 0 &&
                  listData?.map((list, index) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell>{list?.name || '-'}</Table.Cell>
                        <Table.Cell>#{list?.id || 0}</Table.Cell>
                        <Table.Cell>
                          {list?.type === 'individual'
                            ? 'Contacts'
                            : 'Companies' || '-'}
                        </Table.Cell>
                        <Table.Cell>
                          <Link
                            onClick={() => setIsConatctOpen(list?.contacts_ids)}
                          >
                            {list?.contacts_ids?.length || 0}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={e => {
                              handleClick(e)
                              setListToUpdate(list.id)
                              setUpdatedListName(list.name)
                              setListToDelete(list.id)
                            }}
                          >
                            <MoreVertOutlinedIcon />
                          </Button>

                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button'
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                setIsDeleteConfirmationModalOpen(true)
                                handleClose()
                              }}
                            >
                              Delete
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setIsEditModalOpen(true)
                                handleClose()
                              }}
                            >
                              Rename
                            </MenuItem>
                            <MenuItem
                              onClick={() => navigate.push('/contacts')}
                            >
                              Add contacts
                            </MenuItem>
                          </Menu>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
              </Table.Body>
            </Table>
          </div>
        </>
      ) : null}
      {/* Edit list */}
      <Modal
        className="local-number-modal"
        open={isEditModalOpen}
        onClose={closeEditModal}
      >
        <Modal.Header>Edit List</Modal.Header>
        <Modal.Content>
          <div className="modal-content">
            <div style={{ padding: '15px' }}>
              <label style={{ marginRight: '10px' }}>Edit List : </label>
              <input
                style={{ height: '40px' }}
                placeholder="Enter your list here"
                value={updatedListName}
                onChange={e => setUpdatedListName(e.target.value)}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={closeEditModal}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={updateList}
                type="button"
                content="Update"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
      <Modal
        className="local-number-modal"
        open={isConatctOpen?.length ? true : false}
        onClose={() => setIsConatctOpen(null)}
      >
        <Modal.Header>Contacts</Modal.Header>
        <Modal.Content>
          <div className="modal-content p-4">
            <Table className="border-0">
              <Table.Header style={{ padding: '12px 15px' }}>
                <Table.Row>
                  <Table.HeaderCell>Contact Name</Table.HeaderCell>
                  <Table.HeaderCell>Phone Number</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body style={{ padding: '10px 12px' }}>
                {isConatctOpen &&
                  isConatctOpen?.length > 0 &&
                  isConatctOpen?.map((list, index) => {
                    const data= contactData?.filter((obj)=> obj?.id===list)?.[0]
                    console.log('data', data)
                    return (
                      <Table.Row key={index}>
                        <Table.Cell>{data?.name || '-'}</Table.Cell>
                        <Table.Cell>{data?.phone_number || 0}</Table.Cell>
                      </Table.Row>
                    )
                  })}
              </Table.Body>
            </Table>
          </div>
        </Modal.Content>
      </Modal>

      {/* Delete Confirmation */}
      <Modal
        className="local-number-modal"
        open={isDeleteConfirmationModalOpen}
        onClose={e => setIsDeleteConfirmationModalOpen(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to delete this list?
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={() => setIsDeleteConfirmationModalOpen(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={deleteList}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>

      {/* add list */}

      <Modal
        className="local-number-modal"
        open={openListModel}
        onClose={e => setOpenListModel(false)}
      >
        <Modal.Header>Add List</Modal.Header>
        <Modal.Content>
          <div className="modal-content">
            <div style={{ padding: '15px' }}>
              <label style={{ marginRight: '10px', marginLeft: '17px' }}>
                Type :{' '}
              </label>
              <select
                style={{ width: '152px', height: '40px', padding: '5px' }}
                name="type"
                onChange={(e, value) => {
                  setType(e?.target?.value)
                }}
                value={type}
                placeholder="type"
              >
                {[
                  { text: 'Individual', value: 'individual' },
                  { text: 'Organization', value: 'organization' }
                ].map((obj, key) => (
                  <option key={key} value={obj?.value}>
                    {obj?.text}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ padding: '15px' }}>
              <label style={{ marginRight: '10px' }}>Add List : </label>
              <input
                style={{ height: '40px' }}
                placeholder="Enter your list here"
                onChange={e => setSaveList(e.target.value)}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={e => setOpenListModel(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={() => createList()}
                type="button"
                content="Add"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}
