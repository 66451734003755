import axios from 'axios'
import moment from 'moment'
import { GET_WIDGET, GET_SUBSCRIPTION, GET_PERSONAL_LINK, GET_PLANS, TEAMS_ACTION, GET_NOTIFICATIONS_ACTIONS, GET_NOTIFICATIONS, GET_PROFILE_DATA, GET_PIPELINES } from './types'
import { useHistory, Redirect } from 'react-router-dom'
import { logOut } from '../common/ProfileModal'

const formatData = date => {
  var arr = date.split('/')
  return arr[2] + '-' + arr[1] + '-' + arr[0]
}

// Get All Home Widget
export const getWidget = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets`
  const res = await axios.get(url)

  if (res.data.data[0]) {
    localStorage.setItem('widget_id', res.data.data[0].id)
    localStorage.setItem('preview_link', res.data.data[0].preview_link)
    localStorage.setItem('data-api-key', res.data.data[0].script_id)
  }

  dispatch({
    type: GET_WIDGET,
    payload: res.data
  })
}
export const plansAction = (params) => dispatch => {
  dispatch({ type: GET_PLANS, payload: params })
}

export const teamsAction = (params) => dispatch => {
  dispatch({ type: TEAMS_ACTION, payload: params })
}

export const getSubscription = () => async dispatch => {
  const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/subscription`

  const settings = {
    url: URL,
    method: 'GET',
    timeout: 0,
    processData: false,
    mimeType: 'multipart/form-data',
    contentType: false
  }

  const res = await axios(settings)

  dispatch({
    type: GET_SUBSCRIPTION,
    payload: res.data
  })
}
export const getFunnels = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/funnels`
  await axios
    .get(url)
    .then(res => {


      dispatch({
        type: GET_PIPELINES,
        payload: res?.data?.data
      })
    })
    .catch(error => { })
}

const notInclude = ['/forgot-password', '/login', '/info', '/reset-password']
export const getPersonalLink = () => async dispatch => {
  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/profile`
  const res = await axios.get(url).catch(function (error) {
    if (error?.response?.status == 401) {
      localStorage.clear();
      if (!notInclude?.includes(window?.location?.pathname)) {

        window.location.href = '/login'
      }
    }
  })
    localStorage.setItem(
      'mobile_verification_status',
      res?.data?.data?.mobile_verification_status
    )
  localStorage.setItem('admin_verification_status', res?.data?.data?.admin_verification_status)
  dispatch({
    type: GET_PERSONAL_LINK,
    payload: res?.data?.data?.personal_link
  })
  dispatch({
    type: GET_PROFILE_DATA,
    payload: res?.data
  })
}

// Get All Home Data
export const getData = (
  startDateFilter,
  endDateFilter,
  type
) => async dispatch => {
  let start_date = new Date(startDateFilter)
  let end_date = new Date(endDateFilter)
  var start_datee = moment(start_date)
  var end_datee = moment(end_date)
  start_date = start_datee.format('DD/MM/YYYY')
  end_date = end_datee.format('DD/MM/YYYY')
  var days_difference = end_datee.diff(start_datee, 'days')
  var apiEndPoint = days_difference > 30 ? 'month' : 'day'
  start_date = formatData(start_date)
  end_date = formatData(end_date)
  var urlChart = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/chart/data/${start_date}/${end_date}/${apiEndPoint}`

  // var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/get-leads-in-custom-period/${start_date}/${end_date}`

  try {
    // const res = await axios.get(url)
    const resChart = await axios.get(urlChart)
    let dataChart = {}

    if (resChart?.data?.data) {
      const d = resChart.data.data
      const month = [
        'null',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'Decemer'
      ]
      const labelArray = ['']
      const allEvents = [0]
      const allCalls = [0]
      const allMessage = [0]
      const ScheduleCall = [0]
      const successfullCalls = [0]
      const failedCalls = [0]

      if (d.length !== 0) {
        await d.map(function (item, index) {
          apiEndPoint === 'month'
            ? labelArray.push(month[item.label])
            : labelArray.push(moment(item.label).format('DD MMM'))
          allEvents.push(item.all_leads)
          allCalls.push(
            item.live_call + item.schedule_call + item.digital_calls
          )
          allMessage.push(item.leave_message)
          ScheduleCall.push(item.schedule_call)
          successfullCalls.push(item.success_call)
          failedCalls.push(item.failed_call)
        })
      }
      dataChart = {
        labelArray: labelArray,
        allEvents: allEvents,
        allCalls: allCalls,
        allMessage: allMessage,
        ScheduleCall: ScheduleCall,
        successfullCalls: successfullCalls,
        failedCalls: failedCalls
      }
    }

    // dispatch({
    //   type,
    //   payload: { statData: res?.data?.data, dataChart }
    // })
  } catch (error) {
    // logOut()
  }
}
