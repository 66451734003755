import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Checkbox, Table } from 'semantic-ui-react'
import Title from '../../common/Title'

const SMSInfo = () => {
  const title = {
    titleTwo: 'SMS'
  }

  const [smsData, setSmsData] = useState([])
  const params=  {
     limit: 10,
      page: 1,
      // sort_field: 'id',
      // sort_value: 'DESC',
      // sort: '-id',
      // 'filter[type][in]': 'TEXT_MESSAGE',
    }
  const fetchSMS = async() => { 
     const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/tempLogs`

    await Axios
       .get(url, {
         headers: {
           Accept: 'application/json',
           'Content-Type': 'application/json'
         },
        //  params: params
       })
       .then(res => {
       console.log('res', res)
       })
   }
  
   useEffect(() => {
     fetchSMS()
   
  
   }, [])
   
   
  return (
    <div>
      <Title
        data={title}
      />
      <div className="sms-table">
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Card Number</Table.HeaderCell>
              <Table.HeaderCell>Expiry Date</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body></Table.Body>
        </Table>
      </div>
    </div>
  )
}

export default SMSInfo