import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'




import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'


const AdminLogin = props => {

  const history = useHistory()
                  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    initLogin()
  }, [])

  const initLogin = async () => {
    const apiToken = await localStorage.getItem('access_token')
    if (apiToken) {
      localStorage.clear();
    }

    const location = window.location.href
    const c = location.split("token=")

    if (c[1]) {
      loginAsAdmin(c[1])
    }
  }

  const loginAsAdmin = (apiToken) => {


    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/profile`
    let head = {
      headers: {
        Authorization: 'Bearer ' + apiToken
      }
    }

    axios
      .get(url, head)
      .then(res => {



        localStorage.setItem('phone_numbers', JSON.stringify(res.data.data.phone_numbers))
        localStorage.setItem('api_key', res.data.data.api_key)
        localStorage.setItem('access_token', apiToken)
        localStorage.setItem('is_appsumo', res.data.data.is_appsumo)
        localStorage.setItem('user_type', res.data.data.is_appsumo ? 'appsumo' : 'regular')
        localStorage.setItem('plan_name', res.data.data.plan_name)
        localStorage.setItem('working_hours_status', res.data.data.working_hours_status)
        localStorage.setItem('status', res.data.data.status)
        localStorage.setItem('profile_pic', res.data.data.profile_image ? `${process.env.REACT_APP_BASE_APP_URL}/storage` + res.data.data.profile_image : null)
        localStorage.setItem('quick_setup', res.data.data.onboarding_step + 1)
        localStorage.setItem('first_name', res.data.data.first_name)
        localStorage.setItem('role_name', res.data.data.role.name)
        localStorage.setItem('full_name', res.data.data.first_name + res.data.data.last_name)
        localStorage.setItem('last_name', res.data.data.last_name)
        localStorage.setItem('email', res.data.data.email)
        localStorage.setItem('id', res.data.data.id)
        localStorage.setItem(
          'mobile_verification_status',
          res.data.data.mobile_verification_status
        )
        localStorage.setItem('account', res.data.data.account_status)


        localStorage.setItem(
          'admin_verification_status',
          res.data.data.admin_verification_status
        )


        if (res.data.data.verified === 0) {
          localStorage.setItem('email_verification_status', '0')
        }

        if (res.data.data.verified) {
          localStorage.setItem('email_verification_status', '1')
        }



        // history.push('/home')
        window.location.href = '/conversation'
      })
      .catch(err => {
        localStorage.clear();
        history.push('/login')
      })
  }



  return (
    <>
      <Dimmer active={isLoading} style={{ position: 'fixed' }}>
        <Loader />
      </Dimmer>
    </>
  )
}

export default AdminLogin
