import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { Dimmer, Loader, Modal, Tab } from 'semantic-ui-react'
import closeIcon from '../../assets/images/closecontact.png'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import AddCompanyDetails from './addCompanyDetails'
import AddContactDetails from './addContactdetails'

const AddContactModalLead = ({
    addOpen,
    setAddOpen,
    Agentmembers,
    fetchDataOnSave,
    companyLoadedData,
    leadId,
    companyList

}) => {
    const initialContactState = {
        name: '',
        email: '',
        phone_number: '',
        country: 'Afghanistan',
        city: '',
        title: '',
        owner: '',
        phone_country: '',
        type: 'individual',
        company: ''
    }
    const intialCompanyData = [
        {
            city: '',
            country: 'Afghanistan',
            name: 'Purpulerain',
            organization_name: 'Purpulerain',
            owner: '',
            phone_country: '',
            phone_number: '',
            company: '',
            type: 'organization'
        },

        {
            city: '',
            country: 'Afghanistan',
            name: 'Betacloud',
            organization_name: 'Betacloud',
            owner: '',
            phone_country: '',
            phone_number: '',
            company: '',
            type: 'organization'
        }
    ]

    const [createContact, setcreateContact] = useState(initialContactState)
    const [companyData, setCompanyData] = useState(companyLoadedData)
    const [upload, setUpload] = useState()
    const [loading, setLoading] = useState(false)
    const [members, setMembers] = useState()

  
    const handleInputChange = (event, code, type) => {
        if (type === 'phone_number') {
            setcreateContact({
                ...createContact,
                phone_number: `+${event}`,
                phone_country: code.countryCode
            })
        } else {
            const { name, value } = event.target
            createContact[name] = value
            setcreateContact(prevContactData => ({
                ...prevContactData,
                [name]: value
            }))
        }
    }
   
    const fileChangedHandler = event => {
        setUpload(event.target.files[0])
    }

    const validData = async e => {
        var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        )
        if (
            !createContact?.name?.trim() ||
            !createContact?.phone_number?.trim() ||
            !createContact?.country?.trim() ||
            !createContact?.phone_country?.trim() ||
            !createContact?.email?.trim() ||
            !createContact?.owner

        ) {
            CommonNotify('Please Fill all Fields', 'error')
        } else if (!pattern.test(createContact.email)) {
            CommonNotify('Email address is invalid', 'error')
        } else {
            fetchAllContacts(e)
        }
    }

    const fetchAllContacts = event => {
        setLoading(true)
        event.preventDefault()

        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact`

        var bodyFormData = new FormData()
        bodyFormData.append('name', createContact.name)
        bodyFormData.append('phone_number', createContact.phone_number)
        bodyFormData.append('country', createContact.country)
        bodyFormData.append('phone_country', createContact.phone_country)
        bodyFormData.append('email', createContact.email)
        bodyFormData.append('type', createContact.type)
        bodyFormData.append('owner', createContact.owner)
        bodyFormData.append('lead_id', leadId)

        axios
            .post(url, bodyFormData)
            .then(res => {
                setAddOpen(false)
                setLoading(false)
                fetchDataOnSave()
                if (res?.data?.message[0] == 'success.message') {
                    CommonNotify('Successfully Created Contact Data ', 'success')
                }
            })
            .catch(error => {
                setLoading(false)
                CommonNotify(
                    error?.response?.data?.errors?.[0] ||
                    'Contact Exists / Required field invalid !'
                )
            })
    }
    useEffect(() => {
        setMembers(Agentmembers)

        setcreateContact({
            ...createContact,
            owner: Agentmembers[0]?.value
        })

        setCompanyData({
            ...companyData,
            owner: Agentmembers[0]?.value
        })
    }, [])

   

  
 
    return (
        <Modal open={addOpen} className="MainModal">
            <Modal.Header className="modalMainWrap">
                <h1>{ 'New Contact' }</h1>
                <div className="modalHeadWrap">
                    <img
                        src={closeIcon}
                        className=""
                        width="18"
                        onClick={() => setAddOpen(false)}
                    />
                </div>
            </Modal.Header>

            {/* <div className="sectionSwitch">
        <img src={addcontact} className="" />

        <p>Associate this contact with existing</p>
        <Checkbox toggle />
      </div> */}
            <div className="tabMenuItem">
                <AddContactDetails
                    setcreateContact={setcreateContact}
                    companyList={companyList}

                    createContact={createContact}
                    handleInputChange={handleInputChange}
                    fileChangedHandler={fileChangedHandler}
                    upload={upload}
                    members={members}
                />
            </div>
            <Modal.Actions className="modalMainWrap">
                <CommonButtons
                    onClick={e => {

                            validData(e)
                     
                    }}
                    content={'Add Contact'}
                    background="blue"
                    btnClass="btn-modal-style"
                />
            </Modal.Actions>
            <Dimmer active={loading} style={{ position: 'fixed' }}>
                <Loader />
            </Dimmer>
        </Modal>
    )
}

export default AddContactModalLead
