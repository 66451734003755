import {
  GET_WIDGET,
  GET_SUBSCRIPTION,
  GET_DATA_TODAY,
  GET_PERSONAL_LINK,
  GET_DATA_YESTERDAY,
  GET_DATA_THIS_WEEK,
  GET_DATA_LAST_WEEK,
  GET_DATA_THIS_MONTH,
  GET_DATA_LAST_MONTH,
  GET_PLANS,
  TEAMS_ACTION,
  GET_PIPELINES,
  SET_CURRENT_CHANNEL
} from '../actions/types'

const initialState = {}

export default function(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_WIDGET:
      return {
        ...state,
        widget: payload
      }

    case GET_SUBSCRIPTION:
      return {
        ...state,
        subscription: payload
      }
    case GET_PIPELINES:
      return {
        ...state,
        pipeline: payload
      }

    case GET_PERSONAL_LINK:
      return {
        ...state,
        personalLink: payload
      }

    case GET_DATA_TODAY:
      return {
        ...state,
        todayData: payload
      }

    case GET_DATA_YESTERDAY:
      return {
        ...state,
        yesterdayData: payload
      }

    case GET_DATA_THIS_WEEK:
      return {
        ...state,
        thisWeekData: payload
      }

    case GET_DATA_LAST_WEEK:
      return {
        ...state,
        lastWeekData: payload
      }

    case GET_DATA_THIS_MONTH:
      return {
        ...state,
        thisMonthData: payload
      }

    case GET_DATA_LAST_MONTH:
      return {
        ...state,
        lastMonthData: payload
      }
    case GET_PLANS:
      return {
        ...state,
        plansData: payload || []
      }
    case TEAMS_ACTION:
      return {
        ...state,
        teamsData: payload || []
      }
    case SET_CURRENT_CHANNEL:
      return {
        ...state,
        currentChannel: payload || []
      }

    default:
      return state
  }
}
