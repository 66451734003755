import classnames from 'classnames'
import React from 'react'

const accountSetup = [
  {
    id: 1,
    title: 'Profile',
    class: 'okk'
  },
  // {
  //   id: 2,
  //   title: 'Use Case',
  //   class: ''
  // },
  // {
  //   id: 3,
  //   title: 'Working hours',
  //   class: ''
  // },
  {
    id: 4,
    title: 'Setup Call Forwarding',
    class: ''
  },
  {
    id: 5,
    title: 'Go live',
    class: ''
  }
]

const accountQuickSetup = [
  // {
  //   id: 1,
  //   title: 'Profile',
  //   class: 'okk'
  // },
  {
    id: 1,
    title: 'Use Case',
    class: ''
  },
  {
    id: 2,
    title: 'Setup Call Forwarding',
    class: ''
  },
  {
    id: 3,
    title: 'Your phone number',
    class: ''
  },
  {
    id: 4,
    title: 'Working hours',
    class: ''
  },

  {
    id: 5,
    title: 'Test Call',
    class: ''
  },
  {
    id: 6,
    title: 'Received Call',
    class: ''
  },
  {
    id: 7,
    title: 'Go live',
    class: ''
  }
]

const callBack = [
  {
    id: 1,
    title: 'Profile',
    class: 'okk'
  },
  {
    id: 2,
    title: 'Setup call routing',
    class: ''
  },
  {
    id: 3,
    title: 'Working hours',
    class: ''
  },
  // {
  //   id: 4,
  //   title: 'Setup URL',
  //   class: ''
  // },
  {
    id: 4,
    title: ' Go live',
    class: ''
  }
]

const callTrackingTitle = [
  {
    id: 1,
    title: 'Billing info',
    class: 'okk'
  },
  {
    id: 2,
    title: 'Use case',
    class: ''
  },
  {
    id: 3,
    title: 'Call routing',
    class: ''
  },
  {
    id: 4,
    title: 'Install',
    class: ''
  },
  {
    id: 5,
    title: 'Welcome',
    class: ''
  }
]

const virtualTitle = [
  {
    id: 1,
    title: 'Use Case',
    class: ''
  },
  {
    id: 2,
    title: 'Your phone number',
    class: ''
  },
  // {
  //   id: 2,
  //   title: 'Profile',
  //   class: ''
  // },

  {
    id: 3,
    title: 'Billing Info',
    class: 'okk'
  },
  {
    id: 4,
    title: 'Setup call routing',
    class: ''
  },

  // {
  //   id: 6,
  //   title: 'Working hours',
  //   class: ''
  // },
  // {
  //   id: 6,
  //   title: 'Invite Team Member',
  //   class: ''
  // },
  {
    id: 6,
    title: 'Welcome',
    class: ''
  }
]
const virtualPaidTitle = [
  {
    id: 1,
    title: 'Use Case',
    class: ''
  },
  {
    id: 2,
    title: 'Your phone number',
    class: ''
  },
  // {
  //   id: 2,
  //   title: 'Profile',
  //   class: ''
  // },

  {
    id: 4,
    title: 'Setup call routing',
    class: ''
  },

  // {
  //   id: 6,
  //   title: 'Working hours',
  //   class: ''
  // },
  // {
  //   id: 6,
  //   title: 'Invite Team Member',
  //   class: ''
  // },
  {
    id: 6,
    title: 'Welcome',
    class: ''
  }
]

const WizardBox = props => {
  const { step } = props

  const handleManageTitle = () => {
    const title = localStorage.getItem('flowType')
    const quickSetup = localStorage.getItem('quickSetupAc')
    if (+quickSetup === 1) {
      return 'Quick Account Setup'
    }else if (+quickSetup === 2) {
      return 'Paid Account Setup'
    } else {
      switch (title) {
        case 'callback':
          return 'Callback Setup'
        case 'callTraking':
          return 'Call Tracking Setup'
        case 'invited':
          return 'Account Setup'
        case 'virtualPhone':
          return 'Virtual Phone Setup'

        default:
          return 'Quick Setup'
      }
    }
  }

  const handleSelectMenu = () => {
    const title = localStorage.getItem('flowType')
    const quickSetup = localStorage.getItem('quickSetupAc')
    if (+quickSetup === 1) {
      return accountQuickSetup
    } else if (+quickSetup === 2) {
      console.log('first', quickSetup)
      return virtualPaidTitle
    } else {
      switch (title) {
        case 'callback':
          return callBack
        case 'callTraking':
          return callTrackingTitle
        case 'invited':
          return accountSetup
        case 'virtualPhone':
          return virtualTitle

        default:
          return callBack
      }
    }
  }
  return (
    <div className="quicksetup-wizard">
      <div className="wizard">
        <h2 className="wizard-title"> {handleManageTitle()} </h2>
        <div className="wizard-holder">
          {handleSelectMenu()?.map((item, index) => {
            return (
              <span
                className={
                  step >= index + 1
                    ? classnames(
                        `wizard-item active ${
                          step === index + 1 ? 'c-active' : ''
                        }`
                      )
                    : 'wizard-item'
                }
                key={item.id}
                // onClick={()=> accountStep(item)}
              >
                {item.title}
              </span>
            )
          })}
        </div>
        <span className="wizard-step-title">
          {handleSelectMenu()[step - 1]?.title}
        </span>
      </div>
    </div>
  )
}

export default WizardBox
