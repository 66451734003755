import React, { Component } from 'react'
import moment from 'moment'
import {
  Label,
  Menu,
  Dropdown,
  Dimmer,
  Loader,
  Input,
  Button,
  Segment,
  Sidebar,
  Image,
  Modal,
  Pagination,
  Icon,
  Popup
} from 'semantic-ui-react'
import { Checkbox } from 'semantic-ui-react'
import axios from 'axios'
import { ReactComponent as CardView} from '../assets/images/cardView-black.svg';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'
import CommonDateRangePicker from '../components/daterangepicker/CommonDateRangePicker'
import ManageLeadTable from '../components/leads/leadTable/LeadTable'
import RightPane from '../components/leads/leadRightPane/rightPopUp'
import LeadBanner from '../components/leads/LeadBanner'

import queryString from 'query-string'

import Title from '../common/Title'

import { CommonNotify } from '../common/CommonNotify'
import CommonButton from '../common/CommonButtons'
import CommonSelect from '../common/CommonSelect'
import Grid from '@mui/material/Grid'
import {
  Autocomplete,
  Box,
  IconButton,
  ListItem,
  TextField,
  Typography
} from '@mui/material'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import DraftsIcon from '@mui/icons-material/Drafts'
import SendIcon from '@mui/icons-material/Send'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import StarBorder from '@mui/icons-material/StarBorder'
import {
  MarkChatRead,
  MoreHoriz,
  NotificationsPaused
} from '@mui/icons-material'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'

import leadLogo from '../assets/images/lead-logo.svg'
import exportIcon from '../assets/images/export.svg'
import {
  dayData,
  dayLabels,
  weekLabels,
  weekData,
  monthLabels,
  monthData
} from '../lib/LeadChartData'
import deleteIcon from '../assets/images/delete-icon.png'

import { CSVLink } from 'react-csv'
import { getLeadOwner } from '../config/leadAPI'

import { ExportToExcel } from '../components/leads/exportExcel'
import ListSegments from '../components/leads/ListSegments'
import BulkOperationMenu from './bulkOperationMenu'

import leadIntrest from '../assets/images/leadInterest.png'
import leadScoreIcon from '../assets/images/leadScore.png'
import leadStageIcon from '../assets/images/leadStage.png'
import tagsIcon from '../assets/images/tags.png'
import userIcon from '../assets/images/user.svg'
import dotIcon from '../assets/images/dot.svg'
import { ReactComponent as AdvanceView} from '../assets/images/advance-view.svg'
import { ReactComponent as ListView} from '../assets/images/list.svg'
import { Tooltip } from '@mui/material'

const statusOption = [
  {
    key: 'Awaiting Review',
    text: 'Pending',
    value: '1'
  },
  { key: 'Reviewed', text: 'Assigned', value: '2' },
  {
    key: 'Disqualified',
    text: 'Closed',
    value: '3'
  },
  { key: 'Qualified', text: 'Qualified', value: '4' }
]

const headers = [
  { label: 'id', key: 'id' },
  { label: 'time', key: 'time' },
  { label: 'contact', key: 'contact' },
  { label: 'status', key: 'status' },
  { label: 'agent', key: 'agent' },
  { label: 'score', key: 'score' },
  { label: 'source', key: 'source' }
]

const options = [
  { key: 1, text: 'All Time', value: 'All Time' },
  { key: 1, text: 'Today', value: 'Today' },
  { key: 2, text: 'Yesterday', value: 'Yesterday' },
  { key: 3, text: 'This week', value: 'This week' },
  { key: 4, text: 'Last week', value: 'Last week' },
  { key: 5, text: 'This month', value: 'This month' },
  { key: 6, text: 'Last month', value: 'Last month' },
  { key: 7, text: 'Custom range', value: 'Select custom' }
]

const sortOptions = [
  { key: 0, text: 'Sort By', value: '' },
  { key: 1, text: 'Newest First', value: 'Newest First' },
  { key: 2, text: 'Oldest First', value: 'Oldest First' }
]

const leadSubStatus = [
  {
    key: 'pending',
    text: 'Pending',
    value: 'pending',
    var: 'pending_leads',
    tabVar: 'pending',
    // var: 'totalpendingLeads',
    scheduleVar: 'upcoming_calls',
    // scheduleVar: 'totalUpcomingScheduleCalls',
    scheduleText: 'Upcoming'
  },
  {
    key: 'in-progress',
    text: ' Assigned',
    value: 'in-progress',
    var: 'assigned_leads',
    tabVar: 'assigned',
    // var: 'totalinprogressleads',
    scheduleVar: 'past_calls',
    // scheduleVar: 'totalPastScheduleCalls',
    scheduleText: 'Past'
  },
  {
    key: 'closed',
    text: 'Closed',
    value: 'Closed',
    var: 'closed_leads',
    tabVar: 'closed',
    // var: 'totalClosedLeads',
    scheduleVar: 'canceled_calls',
    // scheduleVar: 'totalCanceledScheduleCalls',
    scheduleText: 'Cancelled'
  },
  {
    key: 'all',
    text: 'All',
    value: 'all',
    var: 'all_leads',
    tabVar: 'all_leads',
    // var: 'totalLeads',
    scheduleVar: 'all_calls',
    // scheduleVar: 'totalLeads',
    scheduleText: 'All'
  }
]

const callLeadSubStatus = [
  {
    key: 'missed',
    text: 'Missed',
    value: 'missed',
    var: 'missed_calls',
    // var: 'totalInboundMissedCalls',
    scheduleText: 'Upcoming'
  },
  {
    key: 'answered',
    text: 'Answered',
    value: 'answered',
    var: 'answered_calls',
    // var: 'totalInboundAnsweredCalls',
    scheduleText: 'Past'
  },
  {
    key: 'messages',
    text: 'Voicemail',
    value: 'messages',
    var: 'voicemail',
    // var: 'totalInboundVoicemail',
    scheduleText: 'Cancelled'
  },
  {
    key: 'all',
    text: 'All',
    value: 'all',
    var: 'all_calls',
    // var: 'totalLeads',
    scheduleText: 'All'
  }
]

const leadStatusOutboundCalls = [
  {
    key: 'missed',
    text: 'Missed',
    value: 'failed_to_connect_agent',
    var: 'missed_calls'
    // var: 'totalMissedOutgoingLeads'
  },
  {
    key: 'completed',
    text: 'Completed',
    value: 'completed',
    // var: 'totalCompletedOutgoingLeads'
    var: 'completed_calls'
  },
  {
    key: 'voicemail',
    text: 'Voicemail',
    value: 'voicemail',
    // var: 'totalVoicemailOutgoingLeads'
    var: 'voicemail'
  },
  {
    key: 'all',
    text: 'All',
    value: 'all',
    var: 'all_calls'
    // var: 'totalOutgoingLeads'
  }
]

class ManageLeads extends Component {
  state = {
    settingModal: false,
    isFavorite: false,
    segmentModal: 0,
    totalLeads: 0,
    tableFullData: null,
    totalUnassignedLeads: 0,
    totalpendingleads: 0,
    totalinprogressleads: 0,
    totalQualifiedLeads: 0,
    totalClosedLeads: 0,
    deleteModal: false,
    widget: '',
    allTags: [],
    adminId: null,
    updatedPane: 0,
    updatedtable: 0,
    updateList: false,
    segmentModal: false,
    listModal: false,
    fetchSegments: false,
    segmentName: '',
    listName: '',
    segmentActive: false,
    selectedList: -2,
    //data for chart
    leadScoreFilter: [],
    allLeadSubStatus: leadSubStatus,
    currentLeadSubStatus: leadSubStatus[0].value,
    // allLeadSubStatus: callLeadSubStatus,
    // currentLeadSubStatus: callLeadSubStatus[3].value,
    membrs: [],
    allOwners: [],
    allOwners2: [],
    allAgents: [],
    dataLoaded: false,
    sortMain: '-id',
    rightPane: false,
    nextLeadId: 0,
    prevLeadId: 0,
    leadData: [],
    leadScore: [],
    selectedPickerOption: 'All Time',
    sortPickerOption: 'Sort By',
    id: '',
    widget: '',
    params: {
      limit: 10,
      page: 1,
      sort_field: 'id',
      sort_value: 'DESC',
      sort: '-id',
      'filter[type][in]':
        'LIVE_CALL,DIGITAL_CALL,UNSUPPORTED_COUNTRY,USER_BLACKLISTED,LEAVE_MESSAGE'
    },
    filterTable: {
      startDateFilter: moment(),
      endDateFilter: moment()
    },
    activePage: 15,
    isLoading: false,
    loader: false,
    activeIndex: 0,
    setOfficeHour2: {
      addOfficeHour2: '',
      link: 'Link',
      officeHourFrom: '',
      officeHourTo: ''
    },
    addOfficeHour2: [1],
    selectCheckboxData: [],
    callLog: {
      title: '',
      legend: false,
      stepSize: '10',
      yAxes: true,
      padding: {
        top: '20',
        right: '20',
        bottom: '0',
        left: '0'
      },
      fontSize: 14,
      fontStyle: '600',
      gridLines: true,
      labels: [
        '',
        '23/03/29',
        '23/04/2019',
        '23/05/2019',
        '23/06/2019',
        '23/07/2019',
        '23/08/2019',
        '23/10/2019'
      ],
      datasets: [
        {
          label: 'call logs',
          fill: false,
          backgroundColor: 'rgba(249,166,9,1)',
          borderColor: 'rgba(249,166,9,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(249,166,9,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(249,166,9,1)',
          pointHoverBorderColor: 'rgba(249,166,9,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 8,
          pointHitRadius: 10,
          data: [0, 10, 3, 9, 4, 5, 8, 2],
          options: {
            bezierCurve: true,
            title: {
              fontSize: 25
            },
            tooltips: {
              mode: 'point',
              enabled: true
            },
            layout: {
              padding: {
                left: 50,
                right: 0,
                top: 0,
                bottom: 0
              }
            }
          }
        }
      ],
      isDayActive: false,
      isWeekActive: true,
      isMonthActive: false
    },
    tableData: {
      messages: [],
      call: [],
      schedules: [],
      isCall: false,
      isMessages: true,
      // isCall: true,
      // isMessages: false,
      isSchedules: false,
      isOutBound: false,
      isDigital: false
    },

    tableDataContent: [],
    leadStage: [],
    leadOwner: [],
    widget_id: null,
    openViews: true,
    openFilters: true,
    filterViews: '',
    //for tanble pagination
    tableDataTotalItem: 0,
    pageNumber: 1,
    totalPages: null,
    startItemCount: 0,
    endItemCount: 0,

    //edit fields checkbox state
    nodeCheckBoxFields: [],

    //filter fields state
    filterTable: {
      type: null,
      startDateFilter: [],
      endDateFilter: new Date(),
      status: null,
      search: null,
      score: null,
      agent: null
    },

    query: null,
    //check if all marked
    isMarkAllCheckbox: false,
    countItem: 0,
    showExport: false,
    isChecked: false,
    //modal state
    open: false,
    showDatePicker: false,
    animation: 'overlay',
    direction: 'left',
    directionMobile: 'top',
    visible: false,
    sortField: 'id',
    sortValue: 'DESC',
    exportData: [],
    dropDownData: [],
    filterCondition: 'is',
    filterDropDownOption: {
      score: null,
      type: null,
      owner: null,
      agent: null,
      final_status: null,
      tags: [],
      interseted2: [],
      source: [
        { key: 'no-referer', value: '', text: 'All Source' },
        { key: 'localhost', value: 'localhost', text: 'Localhost' },
        { key: 'qa.limecall', value: 'qa.limecall', text: 'QA Limecall' },
        { key: 'app.limecall', value: 'app.limecall', text: 'App Limecall' }
      ],
      status: [
        { key: 'Awaiting Review', value: 1, text: 'Pending' },
        { key: 'In Progress', value: 2, text: 'In Progress' },
        { key: 'Qualified', value: 4, text: 'Qualified' },
        { key: 'Closed', value: 3, text: 'Closed' }
      ],
      interseted: [
        { key: 0, value: '', text: 'All' },
        { key: 1, text: 'Good Fit', value: 1 },
        { key: 2, text: 'Pending', value: 2 },
        { key: 3, text: 'Bad Fit', value: 3 }
      ]
    },

    queryValue: {
      agent: { type: 'is', value: '' },
      phone_number: { type: 'begin', value: '' },
      final_status: { type: 'is', value: '' },
      type: { type: 'is', value: '' },
      status: { type: 'is', value: '' },
      owner_id: { type: 'is', value: '' },
      direction: { type: 'is', value: '' },
      // flags: { type: 'in', value: [] },
      stage: { type: 'is', value: '' },
      score: { type: 'is', value: '' },
      source: { type: 'contain', value: '' },
      tags: { type: 'contain', value: '' },
      interseted: { type: 'is', value: '' }
    },
    manageViewsCount: {},
    viewsFilterValue: {}
  }

  setCurrentStatus = async val => {
    await this.setState({ currentLeadStatus: val })
    await this.setState({
      currentLeadSubStatus: this.state.allLeadSubStatus[0]
    })
    this.fetchData()
  }

  setCurrentSubStatus = async (val, flag) => {
    await this.setState({ currentLeadSubStatus: flag === true ? 'all' : val })
    // await this.setState({ currentLeadSubStatus: "all" })
    if (flag === true) {
      let filter = {}
      switch (val) {
        case 'in-progress':
          filter[`filter[owner_id][is]`] = localStorage?.getItem('id')
          filter[`filter[status][!is]`] = 3
          break
        case 'pending':
          filter[`filter[owner_id][is]`] = 'null'
          filter[`filter[status][!is]`] = 3
          break
        case 'assigned':
          filter[`filter[owner_id][!is]`] = 'null'
          filter[`filter[status][!is]`] = 3
          break
        case 'all':
          // filter[`filter[owner_id][!is]`] = 'null'
          // filter[`filter[status][!is]`] = 3
          break
        case 'new':
          //remove 48 hours from current date and time.
          filter[`filter[created_at][gte]`] = new Date(
            new Date().getTime() - 2 * 24 * 60 * 60 * 1000
          )
          filter[`filter[interseted][!is]`] = 3
          break
        case 'Closed':
          filter[`filter[status][is]`] = 3
          break
        case 'spam':
          filter[`filter[interseted][is]`] = 3
          break
        case 'goodFit':
          filter[`filter[interseted][is]`] = 1
          break

        default:
          break
      }
      await this.setState({
        viewsFilterValue: filter
      })
    }
    this.fetchData()
  }

  deleteModalView = bool => {
    this.setState({ deleteModal: bool })
  }

  setUpdatedPaneArg = () => {
    let { updatedPane } = this.state
    updatedPane = updatedPane + 1
    this.setState({ updatedPane })
  }

  setUpdatedPane = () => {
    setTimeout(this.setUpdatedPaneArg, 2000)
  }

  setUpdatedTableArg = () => {
    let { updatedTable } = this.state
    updatedTable = updatedTable + 1
    this.setState({ updatedTable })
  }

  setUpdatedTable = () => {
    setTimeout(this.setUpdatedTableArg, 2000)
  }

  getArrayIndex = (list, find) => {
    for (const [i, v] of list.entries()) {
      if (v.id == find) {
        return i
      }
    }
    return -1
  }

  getNextPrevLead = id => {
    let leads = []
    if (this.state.tableData.isCall) {
      leads = this.state.tableData.call
    } else if (this.state.tableData.isMessages) {
      leads = this.state.tableData.messages
    } else {
      leads = this.state.tableData.schedules
    }

    // find the lead id
    const currIndex = this.getArrayIndex(leads, '#' + id)

    let nextIndex = 0
    let prevIndex = 0

    if (currIndex == 0) {
      nextIndex = 1
      prevIndex = leads.length - 1
    } else if (currIndex == leads.length - 1) {
      nextIndex = 0
      prevIndex = currIndex - 1
    } else {
      nextIndex = currIndex + 1
      prevIndex = currIndex - 1
    }

    if (!leads[nextIndex]) {
      nextIndex = currIndex
    }

    if (!leads[prevIndex]) {
      prevIndex = currIndex
    }

    let pLead = leads[prevIndex].id
    let nLead = leads[nextIndex].id
    if (leads[prevIndex].id.charAt(0) == '#') {
      nLead = leads[prevIndex].id.substring(1)
    }
    if (leads[nextIndex].id.charAt(0) == '#') {
      pLead = leads[nextIndex].id.substring(1)
    }

    this.setState({ nextLeadId: nLead, prevLeadId: pLead })
  }

  reloadLeads = () => {
    //this.fetchDataAfterDelete()
    this.fetchData()
  }

  fetchAllTags = widget => {
    this.setState({
      widget
    })
  }

  setAllTags = aTags => {
    this.selectAllTags(aTags)
  }

  selectAllTags = tags => {
    let newTags = []
    newTags = tags.map(tag => {
      return {
        tag: tag,
        color: this.generateRandomColor()
      }
    })
    this.setState({ allTags: newTags })
  }

  generateRandomColor = () => {
    var randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16)
    return randomColor
  }

  fetchLeadCounter = async () => {
    const token = await localStorage.getItem('access_token')
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/leads-counters`
    const settings = {
      url: URL,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      }
    }
    axios(settings)
      .then(res => {
        this.setState({
          manageViewsCount: res?.data?.data || {}
        })
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }

  fetchAllSources = async () => {
    const token = await localStorage.getItem('access_token')
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/available-sources`
    const settings = {
      url: URL,
      method: 'GET',
      timeout: 0,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      }
    }
    axios(settings)
      .then(res => {
        if (res?.data?.data) {
          let allValue = []
          allValue = [...allValue, ...res?.data?.data?.original_sources]
          allValue = [...allValue, ...res?.data?.data?.utm_sources]
          let cloneCurrentValue = { ...this.state.filterDropDownOption }
          cloneCurrentValue.source = allValue?.map(list => {
            return { key: list, value: list, text: list }
          })
          cloneCurrentValue.source.unshift({
            key: 'no-referer',
            value: '',
            text: 'All Source'
          })
          this.setState({
            filterDropDownOption: cloneCurrentValue
          })
        }
      })
      .catch(err => {})
  }

  fetchWidget = async () => {
    const token = await localStorage.getItem('access_token')
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets?limit=3&offset=0`

    const settings = {
      url: URL,
      method: 'GET',
      timeout: 0,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      },
      processData: false,
      mimeType: 'multipart/form-data',
      contentType: false
    }

    axios(settings)
      .then(res => {
        this.setState({ widget: res.data.data[0].id })
        this.fetchAllTags(res.data.data[0].id)
        localStorage.setItem('widget_active', res?.data?.data?.[0]?.active)
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }

  handleRightPaneOpen = async id => {
    this.setState({ dataLoaded: false, id })
    this.getNextPrevLead(id)
    // this.setState({ isLoading: true })
    this.setState({ rightPane: true })
    // let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/lead/${id}`
    // axios
    //   .get(url)
    //   .then(res => {
    //     this.setState({ isLoading: false })
    //     this.setState({ leadData: res?.data })
    //     this.setState({ dataLoaded: true })
    //   })
    //   .catch(err => {
    //     if (err.response?.data?.errors?.length) {
    //       this.setState({ rightPane: false })
    //       this.setState({ isLoading: false })
    //       CommonNotify('Id is not available / server error')
    //     }
    //     if (err?.response?.status === 401) {
    //       CommonNotify('Id is not available / server error')
    //       return
    //     }
    //     if (!err?.response) {
    //       CommonNotify('Id is not available / server error')
    //     }
    //   })
  }

  handleLoading = loading => {
    this.setState({ isLoading: loading })
  }

  // setLeadData = res => {
  //   this.setState({ isLoading: false })
  //   this.setState({ leadData: res?.data })
  //   this.setState({ dataLoaded: true })
  // }

  handleRightClose = () => {
    this.setState({ dataLoaded: false, rightPane: false, id: null })
  }

  cloneSetHoursWrapper2 = () => {
    const currentDiv2 = this.state.addOfficeHour2
    currentDiv2.push(1)
    this.setState({ addOfficeHour2: currentDiv2 })
  }

  onClickAddOfficeHour2 = () => {
    const { setOfficeHour2 } = this.state
    const link = this.state.setOfficeHour2.link
    const officeHourFrom = this.state.setOfficeHour2.officeHourFrom
    const officeHourTo = this.state.setOfficeHour2.officeHourTo
    const addOfficeHour2 = 'addOfficeHour2'
    const addOfficeHourItem2 = link + ' ' + officeHourFrom + ' ' + officeHourTo

    setOfficeHour2[addOfficeHour2] = addOfficeHourItem2

    this.setState({ setOfficeHour2 })

    this.cloneSetHoursWrapper2()
  }

  onClickRemoveOfficeHours2 = () => {
    const currentDiv2 = this.state.addOfficeHour2
    currentDiv2.pop()
    this.setState({ addOfficeHour2: currentDiv2 })
  }

  resetPage = () => {
    let { tableData, tableDataContent, pageNumber } = this.state

    tableData.messages = []
    tableData.schedules = []
    tableData.call = []

    tableDataContent = []

    this.setState({
      sortField: 'id',
      sortValue: 'DESC',
      tableDataContent,
      isMarkAllCheckbox: false,
      pageNumber,
      tableData
    })
  }

  handleSortData = (e, { value }) => {
    if (value === '') return

    if (value === 'Newest First') {
      this.setState({ sortMain: '-id', sortPickerOption: value }, () => {
        this.fetchData()
      })
    } else if (value === 'Oldest First') {
      this.setState({ sortMain: 'id', sortPickerOption: value }, () => {
        this.fetchData()
      })
    } else if (value === 'Awaiting Review') {
      this.setState({ sortMain: '-id', sortPickerOption: value }, () => {
        this.fetchData()
      })
    } else {
      this.setState({ sortMain: 'id', sortPickerOption: value }, () => {
        this.fetchData()
      })
    }
  }

  handleChangeDate = (e, { value }) => {
    if (value === 'Select custom') {
      this.setState({
        selectedPickerOption: value,
        filterTable: {
          startDateFilter: moment(),
          endDateFilter: moment()
        }
      })
      return
    }
    this.setState({ selectedPickerOption: value })
    switch (value) {
      case 'All Time':
        this.fetchData()
        break
      case 'Today':
        this.today()
        break
      case 'Yesterday':
        this.yesterday()
        break
      case 'This week':
        this.thisWeek()
        break
      case 'Last week':
        this.lastWeek()
        break
      case 'This month':
        this.thisMonth()
        break
      case 'Last month':
        this.lastMonth()
        break
      default:
        this.fetchData()
    }
  }

  today = () => {
    this.setState(
      {
        filterTable: {
          startDateFilter: moment().format('YYYY-MM-DD'),
          endDateFilter: moment().format('YYYY-MM-DD')
        },
        selectedPickerOption: 'Today'
      },
      () => {
        this.fetchDataWithDate()
      }
    )
  }

  yesterday = async () => {
    this.setState(
      {
        filterTable: {
          startDateFilter: moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
          endDateFilter: moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        }
      },
      () => {
        this.fetchDataWithDate()
      }
    )
  }

  lastWeek = () => {
    this.setState(
      {
        filterTable: {
          startDateFilter: moment()
            .subtract(1, 'weeks')
            .startOf('isoWeek')
            .format('YYYY-MM-DD'),
          endDateFilter: moment()
            .subtract(1, 'weeks')
            .endOf('isoWeek')
            .format('YYYY-MM-DD')
        }
      },
      () => {
        this.fetchDataWithDate()
      }
    )
  }

  thisWeek = () => {
    const startOfWeek = moment()
      .startOf('isoWeek')
      .format('YYYY-MM-DD')
    const endOfWeek = moment()
      .endOf('isoWeek')
      .format('YYYY-MM-DD')
    this.setState(
      {
        filterTable: {
          startDateFilter: startOfWeek,
          endDateFilter: endOfWeek
        }
      },
      () => {
        this.fetchDataWithDate()
      }
    )
  }

  thisMonth = () => {
    const startOfMonth = moment()
      .clone()
      .startOf('month')
      .format('YYYY-MM-DD')
    const endOfMonth = moment()
      .clone()
      .endOf('month')
      .format('YYYY-MM-DD')
    this.setState(
      {
        filterTable: {
          startDateFilter: startOfMonth,
          endDateFilter: endOfMonth
        }
      },
      () => {
        this.fetchDataWithDate()
      }
    )
  }

  lastMonth = () => {
    const prevMonthFirstDay = moment()
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD')
    const prevMonthLastDay = moment()
      .subtract(1, 'months')
      .endOf('month')
      .clone()
      .endOf('month')
      .format('YYYY-MM-DD')
    this.setState(
      {
        filterTable: {
          startDateFilter: prevMonthFirstDay,
          endDateFilter: prevMonthLastDay
        }
      },
      () => {
        this.fetchDataWithDate()
      }
    )
  }

  handleApply = (event, picker) => {
    this.setState(
      {
        filterTable: {
          startDateFilter: moment(picker.startDate).format('YYYY-MM-DD'),
          endDateFilter: moment(picker.endDate).format('YYYY-MM-DD')
        }
      },
      () => {
        this.fetchDataWithDate()
      }
    )
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber })
  }

  setSortField = field => {
    let { sortField, sortValue } = this.state
    if (sortField == field) {
      sortValue = this.flipSortType(sortValue)
      this.setState(
        {
          sortValue: sortValue
        },
        () => {
          this.fetchData()
        }
      )
      return
    }
    sortValue = 'DESC'
    this.setState(
      {
        sortField: field,
        sortValue: 'DESC'
      },
      () => {
        this.fetchData()
      }
    )
  }

  flipSortType = type => {
    if (type == 'DESC') return 'ASC'
    return 'DESC'
  }
  fetchDataWithDate = async () => {
    //const apiToken = await  localStorage.getItem('access_token')

    const {
      selectedValue,
      query,
      filterCondition,
      sortField,
      sortValue,
      pageNumber,
      filterTable
    } = this.state

    this.setState({ exportData: [], countItem: 0 })
    /*  const header = {
      Authorization: 'Bearer ' + apiToken
    } */

    const leadType = this.getLeadTypeNew()
    var url

    var params = {
      [`filter[created_at][between]`]: `${filterTable.startDateFilter},${filterTable.endDateFilter}`,
      limit: 10,
      page: pageNumber,
      sort_field: sortField,
      sort_value: sortValue
    }
    if (leadType === 'SCHEDULE_CALL') {
      params[
        'filter[request_call_time][between]'
      ] = `${filterTable.startDateFilter},${filterTable.endDateFilter}`
      // params[
      //   'filter[request_call_time][gt]'
      // ] = new Date()
      // delete params['filter[created_at][between]']
    }
    if (leadType !== 'LIVE_CALL' && leadType !== 'OUTBOUND_CALL') {
      params['filter[type][in]'] = leadType
    } else {
      params['filter[type][in]'] =
        'LIVE_CALL,DIGITAL_CALL,UNSUPPORTED_COUNTRY,USER_BLACKLISTED,LEAVE_MESSAGE'
      params['filter[direction][is]'] = 'Inbound'
      if (leadType == 'OUTBOUND_CALL') {
        params['filter[direction][is]'] = 'Outbound'
      }
    }

    this.setState({ params })

    url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`
  }

  setLeadsStates = res => {
    this.setFilterDropDownOption(res.data.data.leads.data)
    this.setState({
      totalLeads: res.data.data.leads.total,
      totalpendingleads: res.data.data.leads.total,
      totalUnassignedLeads: res.data.data.leads.total,
      totalQualifiedLeads: res.data.data.leads.total,
      totalInProgressLeads: res.data.data.leads.total,
      tableFullData: res.data.data
    })
    this.setLeadNo(res.data.data)
    this.storeSegmentLead(res.data.data.leads.data)
    this.storeTableData(res.data.data.leads)
  }

  setWidgets = res => {
    const data = res.data.data.scores_tags

    const updateLeadScore =
      data.allowed_scores &&
      data.allowed_scores.map((item, index) => {
        return {
          key: index + 1,
          text: item,
          value: item
        }
      })

    if (updateLeadScore.length === data.allowed_scores?.length) {
      updateLeadScore.unshift({
        key: 0,
        text: 'Lead scores',
        value: 0
      })
    }

    const updateStageScore =
      data.allowed_stages &&
      data.allowed_stages.map((item, index) => {
        return {
          key: index + 1,
          text: item,
          value: item
        }
      })

    this.setState({
      leadScore: [...updateLeadScore],
      leadScoreFilter: [...updateLeadScore],
      leadStage: [...updateStageScore]
    })
  }

  setUserId = res => {
    const data = []
    res.data.data.map(number => {
      data.push({
        key: number[0],
        value: number[0],
        text: `LimePhone Number (${number[0]})`
      })
    })

    this.setState({
      ...this.state,
      dropDownData: data
    })
  }

  removeFilter = async () => {
    let {
      query,
      filterDropDownOption,
      selectedValue,
      filterCondition,
      reloadNeed
    } = this.state
    await this.setState(
      {
        selectedValue: '',
        query: '',
        filterCondition: '',
        segmentActive: false
      },
      this.reloadData
    )
  }

  reloadData = () => {
    let { reloadNeed } = this.state
    if (!reloadNeed) return
    this.fetchData()
    this.setState({ reloadNeed: false })
  }

  fetchFilterData = async data => {
    await this.setState({ reloadNeed: true, segmentActive: true })
    this.fetchData()
  }

  storeleadTypeTotal = data => {
    return
    this.setState({
      totalLeads: data.leads.total,
      totalpendingleads: data.totalPendingLeads,
      totalUnassignedLeads: data.totalUnassignedLeads,
      totalQualifiedLeads: data.totalQualifiedLeads
    })
  }

  fetchData = async (data, flag) => {
    const {
      selectedValue,
      query,
      filterCondition,
      sortField,
      sortValue,
      pageNumber,
      sortMain
    } = this.state

    const leadType = this.getLeadTypeNew()
    var url

    var params = {
      limit: 10,
      page: pageNumber,
      sort_field: sortField,
      sort_value: sortValue,
      sort: sortMain
    }

    if (query) {
      if (selectedValue === 'Date') {
        var cond = 'like'
        filterCondition === '!is' ? (cond = '!like') : (cond = 'like')
        params = {
          [`filter[created_at][${cond}]`]: query,
          limit: 10,
          page: pageNumber,
          sort_field: sortField,
          sort_value: sortValue
        }
      }
    }

    const leadSubType = this.state.currentLeadSubStatus
    switch (leadType) {
      // Call Tab
      case 'LIVE_CALL':
      case 'calls':
        delete params[`filter[schedule_call_status][not in]`]
        delete params['filter[final_status][is not]']
        delete params[`filter[direction][is]`]
        delete params[`filter[final_status][is]`]
        delete params[`filter[type][in]`]

        if (leadSubType === 'missed') {
          params[`filter[type][!in]`] =
            'SCHEDULE_CALL,TEXT_MESSAGE,LEAVE_MESSAGE'
          params[`filter[direction][is]`] = 'Inbound'
          params[`filter[final_status][!is]`] = 'completed'
        }
        if (leadSubType === 'answered') {
          params[`filter[type][!in]`] =
            'SCHEDULE_CALL,TEXT_MESSAGE,LEAVE_MESSAGE'
          params[`filter[direction][is]`] = 'Inbound'
          params[`filter[final_status][is]`] = 'completed'
        }
        if (leadSubType === 'messages') {
          params[`filter[type][in]`] = 'VOICEMAIL'
          params[`filter[direction][is]`] = 'Inbound'
        }
        if (leadSubType === 'all') {
          params[`filter[type][!in]`] =
            'SCHEDULE_CALL,TEXT_MESSAGE,LEAVE_MESSAGE'
          params[`filter[direction][is]`] = 'Inbound'
        }
        break

      // leadTab Tab
      case 'LEAVE_MESSAGE':
      case 'leads':
        delete params[`filter[schedule_call_status][not in]`]
        delete params[`filter[direction][is]`]
        delete params[`filter[final_status][is]`]
        delete params[`filter[final_status][is not]`]
        delete params[`filter[type][in]`]
        delete params[`filter[status][is]`]
        if (leadSubType === 'pending') {
          // params[`filter[type][in]`] = 'TEXT_MESSAGE,LEAVE_MESSAGE'
          params[`filter[type][!in]`] = "TEXT_MESSAGE"
          params[`filter[direction][is]`] = 'Inbound'
          params[`filter[status][is]`] = '1'
        }
        if (leadSubType === 'in-progress') {
          // params[`filter[type][in]`] = 'TEXT_MESSAGE,LEAVE_MESSAGE'
          params[`filter[type][!in]`] = "TEXT_MESSAGE"
          params[`filter[direction][is]`] = 'Inbound'
          params[`filter[owner_id][!is]`] = 'null'
          params[`filter[status][!is]`] = '3'
        }
        if (leadSubType?.toLowerCase() === 'closed') {
          // params[`filter[type][in]`] = 'TEXT_MESSAGE,LEAVE_MESSAGE'
          params[`filter[type][!in]`] = "TEXT_MESSAGE"
          params[`filter[direction][is]`] = 'Inbound'
          params[`filter[status][is]`] = '3'
        }
        if (leadSubType === 'all') {
          // params[`filter[type][in]`] = 'TEXT_MESSAGE,LEAVE_MESSAGE'
          params[`filter[type][!in]`] = "TEXT_MESSAGE"
          params[`filter[direction][is]`] = 'Inbound'
        }
        break

      // schedule Tab
      case 'SCHEDULE_CALL':
        if (leadSubType === 'pending') {
          delete params['filter[final_status][is]']
          params['filter[type][in]'] = leadType
          params['filter[final_status][is]'] = 'scheduled,rescheduled'
        }
        if (leadSubType === 'in-progress') {
          params['filter[type][in]'] = leadType
          params['filter[schedule_call_status][!is]'] = 'canceled'
          params['filter[request_call_time][lt]'] = new Date()?.toISOString()
        }
        if (leadSubType?.toLowerCase() === 'closed') {
          params['filter[type][in]'] = leadType
          params['filter[schedule_call_status][is]'] = 'canceled'
        }
        if (leadSubType === 'all') {
          params['filter[type][in]'] = leadType
          delete params['filter[schedule_call_status][in]']
          delete params['filter[final_status][is]']
        }
        break
      // outbound Tab

      case 'OUTBOUND_CALL':
        delete params['filter[schedule_call_status][in]']
        delete params['filter[final_status][is]']
        delete params[`filter[final_status][is not]`]
        delete params[`filter[status][is]`]
        delete params[`filter[schedule_call_status][not in]`]
        delete params[`filter[direction][is]`]
        delete params[`filter[final_status][is]`]
        delete params[`filter[type][in]`]
        if (leadSubType === 'failed_to_connect_agent') {
          params['filter[type][!in]'] = 'SCHEDULE_CALL'
          params['filter[direction][is]'] = 'Outbound'
          params['filter[final_status][!is]'] = 'completed'
        }
        if (leadSubType === 'completed') {
          params['filter[type][!in]'] = 'SCHEDULE_CALL'
          params['filter[direction][is]'] = 'Outbound'
          params['filter[final_status][is]'] = 'completed'
        }
        if (leadSubType?.toLowerCase() === 'voicemail') {
          params['filter[type][in]'] = 'VOICEMAIL'
          params['filter[direction][is]'] = 'Outbound'
        }
        if (leadSubType === 'all') {
          params['filter[type][!in]'] = 'SCHEDULE_CALL'
          params['filter[direction][is]'] = 'Outbound'
        }

        break

      default:
        break
    }

    if (flag !== undefined) {
      if (flag) {
        params['filter[flags][in]'] = 1
      } else {
        delete params['filter[flags][in]']
      }
    } else {
      if (this.state.isFavorite) {
        params['filter[flags][in]'] = 1
      } else {
        delete params['filter[flags][in]']
      }
    }

    this.fetchLeadCounter()
    for (const key in this.state.queryValue) {
      if (this.state.queryValue[key].value) {
        params[
          `filter[additional_${key}][${this.state.queryValue[key].type}]`
        ] = this.state.queryValue[key].value
      }
    }

    if (
      this.state?.viewsFilterValue &&
      Object.keys(this.state?.viewsFilterValue)?.length
    ) {
      params = {
        ...params,
        ...this.state?.viewsFilterValue
      }
    }

    delete params?.sort_field
    delete params?.sort_value

    this.setState({ params, loader: true })
    url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`

    axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: params
      })
      .then(res => {
        this.setState({
          isLoading: false,
          loader: false,
          exportData: [],
          countItem: 0
        })

        this.setFilterDropDownOption(res.data.data.leads.data)
        this.resetPage()
        this.storeTableData(res.data.data.leads)
        this.setLeadNo(res.data.data)
        this.storeSegmentLead(res.data.data.leads.data)
        const filter = res.data.data
        delete filter?.leads
        this.setState({
          ...this.state,
          ...filter
        })
      })
      .catch(err => {
        this.setState({ isLoading: false, loader: false })
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }

  storeSegmentLead = data => {
    const leads = data.map(lead => {
      return lead.id
    })
    this.setState({ segmentLeads: leads })
  }

  setLeadNo = data => {
    this.setState({
      totalLeads: data.totalLeads,
      totalinprogressleads: data.totalInProgressLeads,
      totalpendingLeads: data.totalPendingLeads,
      totalQualifiedLeads: data.totalQualifiedLeads,
      totalUnassignedLeads: data.totalUnassignedLeads,
      totalClosedLeads: data.totalClosedLeads
    })
  }

  fetchDataAfterDelete = async data => {
    const {
      selectedValue,
      query,
      filterCondition,
      sortField,
      sortValue,
      pageNumber
    } = this.state

    const leadType = this.getLeadTypeNew()
    var url

    var params = {
      limit: 10,
      page: pageNumber,
      sort_field: sortField,
      sort_value: sortValue
    }

    if (leadType !== 'LIVE_CALL' && leadType !== 'OUTBOUND_CALL') {
      params['filter[type][in]'] =
        leadType === 'SCHEDULE_CALL' ? 'SCHEDULE_CALL' : leadType
      params['filter[type]'] = leadType
      // params[
      //   'filter[request_call_time][gt]'
      // ] = new Date()
    } else {
      params['filter[type][in]'] =
        'LIVE_CALL,DIGITAL_CALL,UNSUPPORTED_COUNTRY,USER_BLACKLISTED,LEAVE_MESSAGE'

      params['filter[direction][is]'] = 'Inbound'
      if (leadType == 'OUTBOUND_CALL') {
        params['filter[direction][is]'] = 'Outbound'
      }
    }

    const leadSubType = this.state.currentLeadSubStatus

    if (leadSubType !== 'all') {
      if (leadSubType === 'new') {
        params['filter[owner_id][!is]'] = ''
      } else if (leadSubType === 'pending') {
        params['filter[status][is]'] = '1'
      } else {
        params['filter[status][is]'] = '4'
      }
    }

    this.setState({ params })

    url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`
  }

  uniquesArray = (datas, type) => {
    return Object.values(
      datas.reduce((a, c) => {
        a[c[type]] = c
        return a
      }, {})
    )
      .map(res => {
        var value
        if (type === 'owner') {
          value = res['owner']
        } else {
          value = res[type]
        }
        return {
          key: res[type],
          text: res[type],
          value: value
        }
      })
      .filter(data => {
        return data.key !== null
      })
  }

  setFilterDropDownOption = datas => {
    const fStatus = [
      'canceled',
      'completed',
      'failed',
      'failed_to_connect_agent',
      'failed_to_connect_customer',
      'no-answer',
      'widget_not_available',
      'not_assigned'
    ]

    const final_status = fStatus.map((st, index) => {
      return {
        key: index + 1,
        text: st,
        value: st
      }
    })

    final_status.unshift({
      key: 0,
      text: 'Call Status',
      value: 0
    })

    const agent = this.state.allOwners

    const score = this.state.leadScore
    const owner = this.state.allOwners2

    const tag = this.state.allTags.map(item => {
      return {
        key: item.tag,
        value: item.tag,
        text: item.tag
      }
    })

    const type = [
      {
        key: 0,
        text: 'All Call Type',
        value: ''
      },
      {
        key: 1,
        text: 'SCHEDULE_CALL',
        value: 'SCHEDULE_CALL'
      },
      {
        key: 2,
        text: 'LEAVE_MESSAGE',
        value: 'LEAVE_MESSAGE'
      },
      {
        key: 3,
        text: 'LIVE_CALL',
        value: 'LIVE_CALL'
      },
      {
        key: 4,
        text: 'DIGITAL_CALL',
        value: 'DIGITAL_CALL'
      },
      {
        key: 5,
        text: 'UNSUPPORTED_COUNTRY',
        value: 'UNSUPPORTED_COUNTRY'
      },
      {
        key: 6,
        text: 'USER_BLACKLISTED',
        value: 'USER_BLACKLISTED'
      },
      {
        key: 7,
        text: 'FORWARDED_CALL',
        value: 'FORWARDED_CALL'
      }
    ]

    const status = [
      { key: 'All', text: 'All Status', value: 0 },
      { key: 'Awaiting Review', text: 'Pending', value: 1 },
      { key: 'Reviewed', text: 'Assigned', value: 2 },
      { key: 'Qualified', text: 'Qualified', value: 4 },
      { key: 'Closed', text: 'Closed', value: 3 }
    ]

    this.setState({
      ...this.state,
      filterDropDownOption: {
        ...this.state.filterDropDownOption,
        owner,
        score,
        type,
        agent,
        final_status,
        status,
        tags: tag
      }
    })
  }

  componentDidMount() {
    let permitRoles = ['Admin', 'MANAGER', 'Manager']
    let role_name = localStorage.getItem('role_name')
    const role = permitRoles.includes(role_name)
    this.setState({ userRole: role_name, deleteLead: role ,mainTab:"Leads"})
    document.title = 'Leads'
    this.getActiveTab()
    this.getOwner()
    this.fetchWidget()
    this.fetchSegments()
    this.fetchAllSources()
  }

  getMainTabQuery = () => {
    const leadType = this.getLeadTypeNew()
    if (leadType !== 'LIVE_CALL') {
      return leadType
    } else {
      return 'LIVE_CALL,DIGITAL_CALL'
    }
  }

  getActiveTab = () => {
    let url = this.props.location.search
    const options = [
      'all',
      'new',
      'pendingg',
      'in-progress',
      'Qualified',
      'Closed'
    ]
    let params = queryString.parse(url)
    if (options.filter(e => e === params.tab).length > 0) {
      this.setCurrentSubStatus(params.tab)
    } else {
      this.fetchData()
    }
  }

  getOwner = async () => {
    const owner = [
      {
        key: 'unassign owner',
        text: 'Unassign owner',
        value: null
      }
    ]
    const allMembers = []
    const allMembers2 = []

    await getLeadOwner()
      .then(async res => {
        const admin = res.data.data.admin
        await owner.push({
          key: admin.id,
          text: admin.first_name + ' ' + admin.last_name,
          value: admin.id
        })

        allMembers.push({
          key: 0,
          text: 'All Agents',
          value: 0
        })

        await allMembers.push({
          key: admin.id,
          text: admin.first_name + ' ' + admin.last_name,
          value: admin.first_name + ' ' + admin.last_name
        })

        allMembers2.push({
          key: 0,
          text: 'All Owners',
          value: 0
        })

        await res.data.data.members.map(team => {
          owner.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.id
          })
          allMembers.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.first_name + ' ' + team.last_name
          })

          allMembers2.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.id
          })
        })

        this.setState(
          {
            ...this.state,
            leadOwner: owner,
            adminId: admin,
            allOwners: allMembers,
            allOwners2: allMembers2,
            allAgents: allMembers
          },
          () => {
            // this.fetchData(true)
          }
        )
      })
      .catch(err => {})
  }

  storeTableData = data => {
    const dataA = data.data
    let { pageNumber, totalPages } = this.state
    totalPages = data.last_page
    let newData = []
    let LiveNewData = []
    const lType = this.getLeadTypeNew()
    let s = ''

    if (lType === 'LIIVE_CALL') {
      dataA.map(val => {
        if (val.type !== 'LEAVE_MESSAGE' && val.type !== 'SCHEDULE_CALL') {
          LiveNewData.push(val)
        }
      })
    } else {
      LiveNewData = dataA
    }

    LiveNewData.map(val => {
      if (val.source) {
        if (
          val.source.indexOf('http://') == 0 ||
          val.source.indexOf('https://') == 0
        ) {
          const d = val.source
          s = d.split('//')

          if (s[1]) {
            const n = s[1].split('/')
            s = n[0] ? n[0] : n
          }
        } else {
          const d = val.source
          s = d.split('.')

          if (s[1]) {
            const n = s[1].split('/')
            s = n[0] ? n[0] : n
          }
        }
      }

      let item = {
        id: '#' + val.id,
        schedule_call_status: val.schedule_call_status,
        owner_id: val.owner_id,
        defaultStage: val.stage,
        stage: val.stage,
        time: val.created_at,
        contact: val.phone_number,
        phone_number: val.phone_number,
        email: val.email,
        source: val.source ? s : 'Unknown',
        interseted: val.interseted,
        call_status: val.final_status,
        final_status: val.final_status,
        status: val.status ? val.status : 1,
        recording_url: val.recording_url,
        duration: val.duration,
        tags: JSON.parse(val.tags) || [],
        agent: val.agent,
        cost: val.cost,
        is_fav: val.is_fav,
        score: val.score == null || val.score <= 1 ? 1 : val.score,
        failure_reason: val.failure_reason,
        direction: val.direction,
        customerName: val.customer_name,
        contactName: val.contact,
        type: val.type,
        isHoverDisplay: false
      }

      if (
        item.customerName !== null &&
        item.customerName !== 'null' &&
        item.customerName !== 'undefined'
      ) {
        item.contact = item.customerName
        item.isHoverDisplay =
          item.email === null && val.phone_number === null ? true : false
      } else if (item.contact !== null) {
        item.contact = item.contact
      } else if (item.email !== null && item.email !== 'undefined') {
        item.contact = item.email
      } else if (
        item.contact === null &&
        item.contactName === null &&
        item.email === null &&
        item.customerName === null &&
        item.customerName === 'undefined' &&
        item.email === 'undefined'
      ) {
        item.contact = 'Unknown'
      }
      newData.push(item)
    })

    const leadType = this.getLeadType()
    let call = false
    let message = false
    let schedule = false
    let digital = false
    let outbound = false
    if (leadType == 'call') {
      call = true
    } else if (leadType == 'message') {
      message = true
    } else if (leadType == 'digital') {
      digital = true
    } else if (leadType == 'OUTBOUND_CALL') {
      outbound = true
    } else {
      schedule = true
    }

    this.setState({
      isLoading: false,
      tableDataContent: newData,
      isMarkAllCheckbox: false,
      pageNumber,
      totalPages,
      tableData: {
        messages: newData,
        call: newData,
        schedules: newData,
        isCall: call,
        isMessages: message,
        isSchedules: schedule,
        isOutBound: outbound,
        isDigital: digital
      }
    })
  }

  storeTableData2 = data => {
    const dataA = data
    let { pageNumber, totalPages } = this.state
    totalPages = data.last_page
    let newData = []
    let LiveNewData = []
    const lType = this.getLeadTypeNew()

    if (lType === 'LIIVE_CALL') {
      dataA.map(val => {
        if (val.type !== 'LEAVE_MESSAGE' && val.type !== 'SCHEDULE_CALL') {
          LiveNewData.push(val)
        }
      })
    } else {
      LiveNewData = dataA
    }

    LiveNewData.map(val => {
      if (val.score == null || val.score <= 1) {
        val.score = 1
      }

      if (val.source) {
        if (
          val.source.indexOf('http://') == 0 ||
          val.source.indexOf('https://') == 0
        ) {
          const d = val.source
          const s = d.split('//')

          if (s[1]) {
            const n = s[1].split('/')
            val.source = n[0] ? n[0] : n
          } else {
            val.source = s
          }
        } else {
        }
      } else {
        val.source = 'Unknown'
      }

      if (val.member == null || val.member == '') {
        val.member = ''
      } else {
        val.member = val.member.first_name
      }

      let item = {
        id: '#' + val.id,
        schedule_call_status: val.schedule_call_status,
        owner_id: val.owner_id,
        defaultStage: val.stage,
        stage: val.stage,
        time: val.created_at,
        contact: val.phone_number,
        phone_number: val.phone_number,
        email: val.email,
        source: val.source,
        interseted: val.interseted,
        call_status: val.final_status,
        final_status: val.final_status,
        status: val.status ? val.status : 1,
        recording_url: val.recording_url,
        duration: val.duration,
        tags: JSON.parse(val.tags) || [],
        agent: val.agent,
        score: val.score,

        customerName: val.customer_name,
        contactName: val.contact,
        type: val.type,
        isHoverDisplay: false
      }

      if (
        item.customerName !== null &&
        item.customerName !== 'null' &&
        item.customerName !== 'undefined'
      ) {
        item.contact = item.customerName
        item.isHoverDisplay =
          item.email === null && val.phone_number === null ? true : false
      } else if (item.contact !== null) {
        item.contact = item.contact
      } else if (item.email !== null && item.email !== 'undefined') {
        item.contact = item.email
      } else if (
        item.contact === null &&
        item.contactName === null &&
        item.email === null &&
        item.customerName === null &&
        item.customerName === 'undefined' &&
        item.email === 'undefined'
      ) {
        item.contact = 'Unknown'
      }

      newData.push(item)
    })

    const leadType = this.getLeadType()
    let call = false
    let message = false
    let schedule = false
    let digital = false
    let outbound = false

    if (leadType == 'call') {
      call = true
    } else if (leadType == 'message') {
      message = true
    } else if (leadType == 'digital') {
      digital = true
    } else if (leadType == 'outbound') {
      outbound = true
    } else {
      schedule = true
    }

    this.setState({
      tableData: {
        messages: newData,
        call: newData,
        schedules: newData,
        isCall: call,
        isMessages: message,
        isSchedules: schedule,
        isDigital: digital,
        isOutBound: outbound
      }
    })

    this.setState({
      tableDataContent: newData,
      isMarkAllCheckbox: false,
      pageNumber,
      totalPages
    })
  }

  toggleSettingModal = st => {
    if (this.state.listSegments && this.state.listSegments.length > 1) {
      this.setState({ settingModal: st })
    } else {
      CommonNotify('Lead Lists not available')
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearTimeout(this.interval)
      this.interval = 0
    }
  }

  updateData = () => {
    const category = document
      .querySelectorAll('.filter .active')[1]
      .innerHTML.toLocaleLowerCase()
    this.filterDataPerPage(category)
  }

  filterDataPerPage = cat => {
    this.interval = setTimeout(() => {
      const { tableData } = this.state

      const category = cat.toLowerCase()
      let { tableDataTotalItem, pageNumber } = this.state
      tableDataTotalItem = tableData[category]?.length
      const limit = 10
      let offset = (pageNumber - 1) * limit
      let start = offset + 1
      let lastItemCount = offset + limit
      let end = Math.min(lastItemCount, tableDataTotalItem)

      let temporaryContainer = []

      if (tableData[category]?.length) {
        tableData[category].forEach((value, index) => {
          if (index + 1 >= start && index + 1 <= end) {
            temporaryContainer.push(value)
          } else {
            temporaryContainer.splice(index, 1)
          }
        })
      }

      this.setState({
        startItemCount: start,
        lastItemCount: end,
        tableDataTotalItem,
        tableDataContent: temporaryContainer
      })
    }, 200)
  }

  handleStartDate = (event, date) => {}

  handleEndDate = date => {
    const { filterTable } = this.state

    filterTable.endDateFilter = date
    this.setState({ ...filterTable })
  }

  // onClickIncrementPage = () => {
  //   let { pageNumber, tableDataTotalItem } = this.state
  //   const limit = 10
  //   let totalPages = Math.ceil(tableDataTotalItem / limit)

  //   if (pageNumber <= 1) {
  //     pageNumber = pageNumber + 1
  //     this.setState({ pageNumber }, () => {
  //       this.fetchData()
  //     })
  //   } else if (pageNumber > 1) {
  //     if (pageNumber > 1) {
  //       pageNumber = pageNumber - 1
  //       this.setState({ pageNumber }, () => {
  //         this.fetchData()
  //       })
  //     }
  //   }
  // }

  // onClickDecrementPage = () => {
  //   let { pageNumber } = this.state

  //   if (pageNumber > 1) {
  //     pageNumber = pageNumber - 1
  //     this.setState({ pageNumber }, () => {
  //       this.fetchData()
  //     })
  //   }
  // }

  onClickMarkCheckbox = e => {
    let { isMarkAllCheckbox, tableDataContent } = this.state
    let index = e
    if (index || index === 0) {
      tableDataContent[index].isChecked = !tableDataContent[index].isChecked

      this.setState({ ...tableDataContent })
      this.setState({ isMarkAllCheckbox: false })
      const show = tableDataContent.some(item => item.isChecked === true)

      if (show) {
        this.setState({ showExport: true })
      } else {
        this.setState({ showExport: false })
      }
      const dataFilter = tableDataContent.filter(
        item => item.isChecked === true
      )
      this.setState({ countItem: dataFilter.length, exportData: dataFilter })
    }
  }

  onClickMarkAllCheckbox = () => {
    let { isMarkAllCheckbox, tableDataContent } = this.state

    isMarkAllCheckbox = !isMarkAllCheckbox

    if (isMarkAllCheckbox) {
      tableDataContent.map(data => {
        return (data.isChecked = true)
      })

      this.setState({ isMarkAllCheckbox, ...tableDataContent })
    } else {
      tableDataContent.map(data => {
        return (data.isChecked = false)
      })

      this.setState({ isMarkAllCheckbox, ...tableDataContent })
    }

    const show = tableDataContent.some(item => item.isChecked === true)
    if (show) {
      this.setState({ showExport: true })
    } else {
      this.setState({ showExport: false })
    }

    const dataFilter = tableDataContent.filter(item => item.isChecked === true)

    this.setState({ countItem: dataFilter.length, exportData: dataFilter })
  }

  onChangeSelectFilter = e => {
    let { filterTable } = this.state
    const type = e.target.innerHTML
    const name = e.target.parentNode.parentNode.parentNode.getAttribute('name')

    filterTable[name] = type
    this.setState({ ...filterTable })

    const datePicker = document.querySelector('.holder-date')

    if (filterTable.dateRange === 'Custom Date') {
      this.setState({ showDatePicker: true })
    } else {
      this.setState({ showDatePicker: false })
    }
  }

  onChangeInputFilter = (e, data) => {
    //alert(data.value);

    const { filterCondition } = this.state
    this.setState(
      {
        ...this.state,
        filterCondition: 'is'
      },
      () => this.selectFilter(e, data)
    )
  }

  onClickMark = () => {
    let { isMarkOpen } = this.state
    const body = document.querySelector('body')

    isMarkOpen = !isMarkOpen
    body.classList.add('overflow-hide')

    this.setState({ isMarkOpen })
  }

  onClickDay = () => {
    let { callLog } = this.state

    const day = dayLabels
    const data = dayData

    callLog.labels = day
    callLog.datasets[0].data = data
    callLog.isDayActive = true
    callLog.isWeekActive = false
    callLog.isMonthActive = false

    this.setState({ callLog })
  }

  onClickWeek = () => {
    let { callLog } = this.state

    const week = weekLabels
    const data = weekData

    callLog.labels = week
    callLog.datasets[0].data = data
    callLog.isDayActive = false
    callLog.isWeekActive = true
    callLog.isMonthActive = false

    this.setState({ callLog })
  }

  onClickMonth = () => {
    let { callLog } = this.state

    const month = monthLabels
    const data = monthData

    callLog.labels = month
    callLog.datasets[0].data = data
    callLog.isDayActive = false
    callLog.isWeekActive = false
    callLog.isMonthActive = true

    this.setState({ callLog })
  }

  getLeadType = () => {
    let { tableData } = this.state

    if (tableData.isDigital) return 'digital'
    if (tableData.isCall) return 'call'
    if (tableData.isMessages) return 'message'
    if (tableData.isSchedules) return 'SCHEDULE_CALL'
    if (tableData.isOutBound) return 'OUTBOUND_CALL'
  }

  getLeadTypeNew = () => {
    let { tableData } = this.state
    if (tableData.isDigital) return 'DIGITAL_CALL'
    if (tableData.isCall) return 'LIVE_CALL'
    if (tableData.isMessages) return 'LEAVE_MESSAGE'
    if (tableData.isSchedules) return 'SCHEDULE_CALL'
    if (tableData.isOutBound) return 'OUTBOUND_CALL'
  }

  getLeadCount = type => {
    if (type == 'all') {
      return this.state.totalLeads
    } else if (type == 'pending') {
      return this.state.totalPendingLeads
    } else if (type == 'qualified') {
      return this.state.totalQualifiedLeads
    } else if (type == 'in-progress') {
      return this.state.totalinprogressLeads
    } else {
      return this.state.totalUnassignedLeads
    }
  }

  manageIdState = data => {
    this.setState({
      selectedRecordIds: data
        ?.filter(list => {
          if (list?.isChecked === true) {
            return list?.id?.substring(1)
          }
        })
        ?.map(value => {
          return value?.id?.substring(1)
        })
    })
  }

  onClickTableCategory = e => {
    let {
      tableData,
      tableDataContent,
      pageNumber,
      allLeadSubStatus
    } = this.state
    var target = e.target.innerHTML
    this.setState({ mainTab: target })
    if (target !== 'Outbound Calls') {
      if (target === 'Calls') {
        this.setState({
          allLeadSubStatus: callLeadSubStatus,
          currentLeadSubStatus: callLeadSubStatus[3].value
        })
      } else {
        this.setState({
          allLeadSubStatus: leadSubStatus,
          currentLeadSubStatus: leadSubStatus[0].value
        })
      }
    } else {
      this.setState({
        allLeadSubStatus: leadStatusOutboundCalls,
        currentLeadSubStatus: leadStatusOutboundCalls[3].value
      })
    }
    var status1st = allLeadSubStatus[3].value
    if (
      target === 'Calls' ||
      target === 'Leads' ||
      target === 'Scheduled Calls'
    ) {
      if (target === 'Calls') {
        this.setState({
          allLeadSubStatus: callLeadSubStatus,
          currentLeadSubStatus: callLeadSubStatus[3].value
        })
      } else {
        this.setState({
          allLeadSubStatus: leadSubStatus,
          currentLeadSubStatus: leadSubStatus[0].value
        })
      }
    } else {
      this.setState({
        allLeadSubStatus: leadSubStatus,
        currentLeadSubStatus: status1st
      })
    }

    if (target === 'Calls') {
      tableData.isCall = true
      tableData.isMessages = false
      tableData.isSchedules = false
      tableData.isDigital = false
      tableData.isOutBound = false
    } else if (target === 'Leads') {
      tableData.isCall = false
      tableData.isMessages = true
      tableData.isSchedules = false
      tableData.isDigital = false
      tableData.isOutBound = false
    } else if (target === 'Digital') {
      tableData.isCall = false
      tableData.isMessages = false
      tableData.isSchedules = false
      tableData.isDigital = true
      tableData.isOutBound = false
    } else if (target === 'Outbound Calls') {
      this.setState({
        allLeadSubStatus: leadStatusOutboundCalls
      })
      tableData.isCall = false
      tableData.isMessages = false
      tableData.isSchedules = false
      tableData.isDigital = false
      tableData.isOutBound = true
    } else {
      target = 'schedules'
      tableData.isCall = false
      tableData.isMessages = false
      tableData.isSchedules = true
      tableData.isOutBound = false
    }

    pageNumber = 1
    this.filterDataPerPage(target)
    this.manageIdState(tableDataContent)
    this.setState(
      {
        tableDataContent,
        isMarkAllCheckbox: false,
        pageNumber,
        sortField: 'id',
        sortValue: 'DESC'
      },
      () => {
        this.fetchData()
      }
    )
  }

  handleScore = e => {
    let { tableDataContent } = this.state
    const dataId = e.target.parentNode.parentNode.parentNode.querySelectorAll(
      'td'
    )[1].innerText

    const score = e.target.getAttribute('data-score')

    tableDataContent.forEach(value => {
      if (value.id === dataId) {
        value.score = score
      }
    })

    this.setState({ tableDataContent })
  }

  toggleTags = e => {
    const dropdown = e.target.querySelector('.holder-dropdown')
    const allDropdown = document.querySelectorAll('.holder-dropdown')

    if (dropdown) {
      allDropdown.forEach(value => {
        value.classList.remove('holder-dropdown-active')
      })

      if (dropdown?.classList) {
        dropdown.classList.add('holder-dropdown-active')
      }
    } else {
      return
    }
  }

  handleTagsData = e => {
    let { tableDataContent } = this.state
    const tag = e.target.innerText
    const dataId = e.target.parentNode.parentNode.parentNode.querySelectorAll(
      'td'
    )[1].innerText

    tableDataContent.forEach(value => {
      if (value.id === dataId) {
        const index = value.tag.indexOf(tag)

        if (index === -1) {
          value.tag.push(tag)
        } else {
          value.tag.splice(index, 1)
        }
      }
    })

    this.setState({ tableDataContent })
  }

  show = size => () => this.setState({ size, open: true })
  close = () => this.setState({ open: false })

  closeDropdown = e => {
    const { isMarkOpen } = this.state
    const target = e.target.className
    const body = document.querySelector('body')
    const allDropdown = document.querySelectorAll('.holder-dropdown')
    const dropDown = document.querySelector('.holder-export-options')

    if (
      e.target?.classList[0] !== 'btn-export' &&
      this.state.showExport === true
    ) {
      if (dropDown?.classList) {
        dropDown.classList.remove('show')
      }
    }

    if (e.target.classList[0] !== 'tags') {
      allDropdown.forEach(value => {
        value.classList.remove('holder-dropdown-active')
      })
    }

    if (isMarkOpen) {
      if (target !== 'checkbox-item' || target !== 'mark active') {
        body.classList.remove('overflow-hide')
        this.setState({ isMarkOpen: false })
      }
    }
  }

  openExportFile = e => {
    const dropDown = document.querySelector('.holder-export-options')

    if (dropDown?.classList) {
      dropDown.classList.toggle('show')
    }
  }

  handleClickInbox = () => {
    this.setState({
      openInbox: !this.state.openInbox
    })
  }

  handleChange = (event, data) => {
    this.setState({ selectedValue: data.value })
  }

  handleChangeNew = (e, data, param) => {
    const qValue = { ...this.state.queryValue }

    //return;

    qValue[param].value = data.value
    this.setState({ queryValue: qValue }, () => {
      this.fetchFilterData() //console.log( this.state.queryValue);
    })
    // this.setState({ selectedValue: param })
    // this.onChangeInputFilter(e, data)
  }

  handleChangeNew2 = (data, param) => {
    const qValue = { ...this.state.queryValue }

    qValue[param].value = data
    this.setState({ queryValue: qValue })
    // this.setState({ selectedValue: param })
    // this.onChangeInputFilter(e, data)
  }

  handleChangeNew3 = e => {
    if (
      e.key === 'Enter' ||
      this.state.queryValue['phone_number'].value == ''
    ) {
      this.fetchFilterData()
    }
  }

  selectFilter = (e, data) => {
    const { query } = this.state
    this.setState(
      {
        ...this.state,
        query: data.value
      },
      () => this.fetchFilterData()
    )
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  handleAnimationChange = animation =>
    this.setState(prevState => ({ animation, visible: !prevState.visible }))

  //Used for to delete the selected LEAD
  onDeleteLeadHandler = () => {
    const { exportData } = this.state
    if (exportData.length === 0) {
      CommonNotify('Select lead first.')
    } else {
      const token = localStorage.getItem('access_token')
      const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/delete-leads`
      const head = {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
      var postId = []
      exportData.map(data => {
        postId.push(data.id.replace('#', ''))
      })

      const postData = {
        ids: postId
      }

      axios
        .post(URL, postData)
        .then(res => {
          this.setState({ exportData: [], countItem: 0, deleteModal: false })
          this.fetchDataAfterDelete()
        })
        .catch(err => {
          this.fetchDataAfterDelete()

          if (err?.response?.status === 401) {
            return
          }
          if (!err?.response) {
          }
        })
    }
  }

  onPageChange = (e, a) => {
    this.setState(
      {
        ...this.state,
        pageNumber: a.activePage
      },
      () => {
        this.fetchData()
      }
    )
  }

  handleCancel = () => {}

  changeSegment = value => {
    this.setState({ segmentName: value })
  }

  changeList = value => {
    this.setState({ listName: value })
  }

  saveSegment = async () => {
    let { segmentName, segmentLeads } = this.state

    const apiToken = await localStorage.getItem('access_token')
    if (segmentName === '') {
      CommonNotify('Please Enter Segment Name')
    }
    if (!segmentLeads.length) {
      CommonNotify('leads is empty')
    }
    const leads = segmentLeads
    let data = { name: segmentName, lead_ids: leads }

    this.setState({ isLoading: true })
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/list/lists`
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        this.setState({ isLoading: false })
        this.closeSegment()
        this.fetchSegments()
        CommonNotify('Segment Saved', 'success')
      })
      .catch(err => {
        this.setState({ isLoading: false })
        CommonNotify('Segment Name Exists / not available')
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }

  saveList = async () => {
    let { listName, exportData } = this.state

    const apiToken = await localStorage.getItem('access_token')
    if (listName === '') {
      CommonNotify('Please Enter List Name')
    }

    var leads = []
    exportData.map(data => {
      leads.push(data.id.replace('#', ''))
    })

    let data = { name: listName, lead_ids: leads }

    this.setState({ isLoading: true })
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/list/lists`
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        this.setState({ isLoading: false })
        this.closeList()
        this.fetchSegments()
        CommonNotify('Segment Saved', 'success')
      })
      .catch(err => {
        this.setState({ isLoading: false })
        CommonNotify('Segment Name Exists / not available')
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
          //logout
        }
      })
  }

  closeSegment = () => {
    this.setState({ segmentModal: false })
  }

  openSegment = () => {
    this.setState({ segmentModal: true })
  }

  closeList = () => {
    this.setState({ listModal: false })
  }

  openList = () => {
    this.setState({ listModal: true })
  }

  fetchSegments = () => {
    this.setState({ fetchSegments: true })
  }

  setSegmentsStates = res => {
    this.storeSegments(res.data.data)
  }

  storeSegments = data => {
    const list = data.map((segment, key, data) => {
      return {
        key: key,
        text: segment.name,
        value: key,
        id: segment.id
      }
    })


    //list.unshift({key:1, text: "Add New List", value: -1})
    //list.unshift({key:0, text: "All List", value: -2})

    this.setState({
      listSegments: list,
      segments: data,
      isLoading: false,
      fetchSegments: false
    })
  }

  loadSegment = data => {
    const { segments } = this.state


    if (data === -2) {
      this.setState({ selectedList: data })
      this.fetchData()
      return
    }
    if (data === -1) {
      this.onClickMarkAllCheckbox()
      this.setState({ selectedList: data })
      this.openList()
      return
    }
    const lead = segments[data].leads
    this.setFilterDropDownOption(lead)
    this.resetPage()
    this.storeTableData2(lead)
    this.setState({ selectedList: data })
  }

  deleteList = async id => {
    this.setState({ isLoading: true })
    const apiToken = await localStorage.getItem('access_token')
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/list/delete-list`
    axios
      .delete(url, {
        data: { list_id: id },
        headers: {
          Authorization: 'Bearer ' + apiToken
        }
      })
      .then(res => {
        this.setState({ isLoading: false, settingModal: false })
        //console.info(res.data.data)
        this.fetchSegments()
      })
      .catch(err => {
        this.setState({ isLoading: false })
        if (err?.response?.status === 401) {
          //logOut();
          return
        }
        if (!err?.response) {
          //logOut();
        }
      })
  }

  listUpdate = async id => {
    let { listName, exportData } = this.state

    const apiToken = await localStorage.getItem('access_token')
    if (!exportData.length) {
      //CommonNotify('select lead first')
    }

    var leads = []
    exportData.map(data => {
      leads.push(data.id.replace('#', ''))
    })

    let data = { name: listName, lead_ids: leads }

    this.setState({ isLoading: true })
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/list/lists/${id}/leads`
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        this.setState({ isLoading: false })
        this.closeList()
        this.fetchSegments()
        CommonNotify('Segment Saved', 'success')
      })
      .catch(err => {
        this.setState({ isLoading: false })
        CommonNotify('Segment Name Exists / not available')
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
          //logout
        }
      })
  }

  //this function return tab wise sidebar count value
  handleGetCurrentkeyValue = () => {
    const leadType = this.getLeadTypeNew()
    switch (leadType) {
      case 'LIVE_CALL':
        return this.state?.manageViewsCount?.['calls'] || {}
      case 'LEAVE_MESSAGE':
        return this.state?.manageViewsCount?.['leads'] || {}
      case 'SCHEDULE_CALL':
        return this.state?.manageViewsCount?.['schedule_calls'] || {}
      case 'OUTBOUND_CALL':
        return this.state?.manageViewsCount?.['outgoing_calls'] || {}
      default:
        return this.state?.manageViewsCount?.['calls'] || {}
    }
  }

  handleChangeFavorite = () => {
    this.setState({
      isFavorite: !this.state.isFavorite
    })
    this.fetchData(undefined, !this.state.isFavorite)
  }

  // bulk update Status
  handleUpdateStatusBulk = value => {
    if (value) {
      const { exportData } = this.state
      if (exportData.length === 0) {
        CommonNotify('Select lead first.')
      } else {
        const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/bulk/status`
        var postId = []
        exportData.map(data => {
          postId.push(data.id.replace('#', ''))
        })

        const postData = {
          lead_ids: postId,
          status: value
        }
        this.setState({ isLoading: true })
        axios
          .post(URL, postData)
          .then(res => {
            this.fetchData()
          })
          .catch(err => {
            this.setState({ isLoading: false })
            CommonNotify(err?.response?.data?.errors?.[0])
          })
      }
    }
  }
  // bulk update Stage
  handleUpdateStageBulk = value => {
    if (value) {
      const { exportData } = this.state
      if (exportData.length === 0) {
        CommonNotify('Select lead first.')
      } else {
        const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/bulk/stage`
        var postId = []
        exportData.map(data => {
          postId.push(data.id.replace('#', ''))
        })

        const postData = {
          lead_ids: postId,
          stage: value
        }
        this.setState({ isLoading: true })
        axios
          .post(URL, postData)
          .then(res => {
            this.fetchData()
          })
          .catch(err => {
            this.setState({ isLoading: false })
            CommonNotify(err?.response?.data?.errors?.[0])
          })
      }
    }
  }
  // bulk update Interent
  handleUpdateInterestBulk = value => {
    if (value) {
      const { exportData } = this.state
      if (exportData.length === 0) {
        CommonNotify('Select lead first.')
      } else {
        const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/bulk/interested`
        var postId = []
        exportData.map(data => {
          postId.push(data.id.replace('#', ''))
        })

        const postData = {
          lead_ids: postId,
          interested: value
        }
        this.setState({ isLoading: true })
        axios
          .post(URL, postData)
          .then(res => {
            this.fetchData()
          })
          .catch(err => {
            this.setState({ isLoading: false })
            CommonNotify(err?.response?.data?.errors?.[0])
          })
      }
    }
  }
  // bulk update Score
  handleUpdateScoreBulk = value => {
    if (value) {
      const { exportData } = this.state
      if (exportData.length === 0) {
        CommonNotify('Select lead first.')
      } else {
        const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/bulk/score`
        var postId = []
        exportData.map(data => {
          postId.push(data.id.replace('#', ''))
        })

        const postData = {
          lead_ids: postId,
          score: value
        }
        this.setState({ isLoading: true })
        axios
          .post(URL, postData)
          .then(res => {
            this.fetchData()
          })
          .catch(err => {
            this.setState({ isLoading: false })
            CommonNotify(err?.response?.data?.errors?.[0])
          })
      }
    }
  }
  // bulk update Owner
  handleUpdateOwnerBulk = value => {
    if (value) {
      const { exportData } = this.state
      if (exportData.length === 0) {
        CommonNotify('Select lead first.')
      } else {
        const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/bulk/set-owner`
        var postId = []
        exportData.map(data => {
          postId.push(data.id.replace('#', ''))
        })

        const postData = {
          lead_ids: postId,
          user_id: value
        }
        this.setState({ isLoading: true })
        axios
          .post(URL, postData)
          .then(res => {
            this.fetchData()
          })
          .catch(err => {
            this.setState({ isLoading: false })
            CommonNotify(err?.response?.data?.errors?.[0])
          })
      }
    }
  }
  // bulk update Tags
  handleUpdateTagsBulk = value => {
    if (value) {
      const { exportData } = this.state
      if (exportData.length === 0) {
        CommonNotify('Select lead first.')
      } else {
        const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/bulk/update-tags`
        var postId = []
        exportData.map(data => {
          postId.push(data.id.replace('#', ''))
        })

        const postData = {
          lead_ids: postId,
          tags: [value]
        }
        this.setState({ isLoading: true })
        axios
          .post(URL, postData)
          .then(res => {
            this.fetchData()
          })
          .catch(err => {
            this.setState({ isLoading: false })
            CommonNotify(err?.response?.data?.errors?.[0])
          })
      }
    }
  }

  render() {
    const {
      tableDataContent,
      tableData,
      isMarkOpen,

      isChecked,
      isMarkAllCheckbox,
      tableDataTotalItem,
      startItemCount,
      lastItemCount,
      pageNumber,
      open,
      data,
      activeIndex,
      animation,
      vertical,
      direction,
      directionMobile,
      visible,
      size
    } = this.state
    const title = {
      type: 'image',
      titleOne: leadLogo,
      titleTwo: 'Calls Managers'
    }
    // this function return sidebar count value
    const sidebarCount = this.handleGetCurrentkeyValue()

    const tagOptions = [
      {
        key: 'ID',
        text: 'ID',
        value: 'id'
      },

      {
        key: 'phone_number',
        text: 'Phone Number',
        value: 'phone_number'
      },
      {
        key: 'Type',
        text: 'Type',
        value: 'type'
      },

      {
        key: 'Call Status',
        text: 'Call Status',
        value: 'final_status'
      },
      {
        key: 'status',
        text: 'status',
        value: 'status'
      },
      {
        key: 'Agent',
        text: 'Agent',
        value: 'agent'
      },
      {
        key: 'Score',
        text: 'Score',
        value: 'score'
      },
      {
        key: 'Owner',
        text: 'Owner',
        value: 'owner'
      }
    ]

    const conditionOptions = [
      {
        key: 'equals',
        text: 'equals',
        value: 'is'
      },
      {
        key: 'not equal to',
        text: 'not equal to',
        value: '!is'
      },
      {
        key: 'starts with',
        text: 'contains',
        value: 'begin'
      }
    ]

    const conditionOptions2 = [
      {
        key: 'equals',
        text: 'equals',
        value: 'is'
      },
      {
        key: 'not equal to',
        text: 'not equal to',
        value: '!is'
      }
    ]

    const filter = {
      type: ['Type', 'Existing', 'Meeting', 'Opportunity'],
      status: ['Civil Status', 'Online', 'Offline'],
      score: ['Score', 'All', 'Qualified', 'Bad Fit'],
      owner: ['Owner', 'All', 'Developer AIPXPERTS'],
      agent: ['Agent', 'All', 'Developer AIPXPI'],
      dateRange: ['Date', 'Today', 'Last 7 Days', 'Last 30 Days', 'Custom Date']
    }

    // const isMobile = window.innerWidth < 992

    return (
      <>
        <Dimmer
          active={this.state.isLoading || this.state?.loader}
          style={{ position: 'fixed' }}
        >
          <Loader />
        </Dimmer>
        <div className="lead-container">
          <Title data={title} />
          <div className="widget-banner-container">
            <LeadBanner />
            <Grid container spacing={2}>
              <Grid xs={1.5} pl={4} mt={6}>
                <List
                  sx={{
                    width: '100%',
                    bgcolor: 'background.paper'
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader-2"
                >
                  <ListItemButton
                    onClick={() => {
                      this.setState({ openViews: !this.state.openViews })
                    }}
                  >
                    <ListItemText primary="Views" />
                    {this.state.openViews ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={this.state.openViews}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => {
                            this.setState({ filterViews: 'Your calls' })
                            this.setCurrentSubStatus('in-progress', true)
                          }}
                          selected={this.state.filterViews === 'Your calls'}
                        >
                          <ListItemText
                            primary={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography>Your calls</Typography>
                                <Typography>
                                  {sidebarCount?.your_calls ||
                                    sidebarCount?.your_leads ||
                                    0}
                                </Typography>
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => {
                            this.setState({ filterViews: 'Unassigned' })
                            this.setCurrentSubStatus('pending', true)
                          }}
                          selected={this.state.filterViews === 'Unassigned'}
                        >
                          <ListItemText
                            primary={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography>Unassigned</Typography>
                                <Typography>
                                  {sidebarCount?.unassigned_calls ||
                                    sidebarCount?.unassigned_leads ||
                                    0}
                                </Typography>
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => {
                            this.setState({ filterViews: 'assigned' })
                            this.setCurrentSubStatus('assigned', true)
                          }}
                          selected={this.state.filterViews === 'assigned'}
                        >
                          <ListItemText
                            primary={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography>All assigned</Typography>
                                <Typography>
                                  {sidebarCount?.assigned_calls ||
                                    sidebarCount?.assigned_leads ||
                                    0}
                                </Typography>
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => {
                            this.setState({ filterViews: 'all' })
                            this.setCurrentSubStatus('all', true)
                          }}
                          selected={this.state.filterViews === 'all'}
                        >
                          <ListItemText
                            primary={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography>All</Typography>
                                <Typography>
                                  {sidebarCount?.all_calls ||
                                    sidebarCount?.all_leads ||
                                    0}
                                </Typography>
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => {
                            this.setState({ filterViews: 'new' })
                            this.setCurrentSubStatus('new', true)
                          }}
                          selected={this.state.filterViews === 'new'}
                        >
                          <ListItemText
                            primary={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography>New</Typography>
                                <Typography>
                                  {' '}
                                  {sidebarCount?.new_calls ||
                                    sidebarCount?.new_leads ||
                                    0}
                                </Typography>
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => {
                            this.setState({ filterViews: 'closed' })
                            this.setCurrentSubStatus('Closed', true)
                          }}
                          selected={this.state.filterViews === 'closed'}
                        >
                          <ListItemText
                            primary={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography>Closed</Typography>
                                <Typography>
                                  {sidebarCount?.closed_calls ||
                                    sidebarCount?.closed_leads ||
                                    0}
                                </Typography>
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => {
                            this.setState({ filterViews: 'spam' })
                            this.setCurrentSubStatus('spam', true)
                          }}
                          selected={this.state.filterViews === 'spam'}
                        >
                          <ListItemText
                            primary={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography>Bad Fit</Typography>
                                <Typography>
                                  {' '}
                                  {sidebarCount?.badfit_calls ||
                                    sidebarCount?.badfit_leads ||
                                    0}
                                </Typography>
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => {
                            this.setState({ filterViews: 'goodFit' })
                            this.setCurrentSubStatus('goodFit', true)
                          }}
                          selected={this.state.filterViews === 'goodFit'}
                        >
                          <ListItemText
                            primary={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography>Good Fit</Typography>
                                <Typography>
                                  {' '}
                                  {sidebarCount?.goodfit_calls ||
                                    sidebarCount?.goodfit_leads ||
                                    0}
                                </Typography>
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Collapse>
                  <ListItemButton
                    onClick={() => {
                      this.setState({ openFilters: !this.state.openFilters })
                    }}
                  >
                    <ListItemText primary="Filters" />
                    {this.state.openFilters ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={this.state.openFilters}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List disablePadding>
                      <ListItem>
                        <div className="favorite">
                          <label>Favorite</label>
                          <Checkbox
                            checked={this.state.isFavorite}
                            style={{ margin: '0 5%' }}
                            toggle
                            onChange={(e, data) => {
                              this.handleChangeFavorite()
                            }}
                          />
                        </div>
                      </ListItem>
                    </List>
                    <List disablePadding>
                      <Dropdown
                        className="condition-select"
                        style={{
                          margin: '0 5% 5%',
                          maxWidth: '85%',
                          boxShadow: 'none'
                        }}
                        selection
                        options={this.state.filterDropDownOption['owner']}
                        placeholder="Lead Owner"
                        value={this.state.queryValue['owner_id'].value}
                        onChange={(e, data) =>
                          this.handleChangeNew(e, data, 'owner_id')
                        }
                      />

                      <Dropdown
                        className="condition-select"
                        style={{
                          margin: '5%',
                          maxWidth: '85%',
                          boxShadow: 'none'
                        }}
                        selection
                        options={this.state.filterDropDownOption['agent']}
                        placeholder="Agent"
                        value={this.state.queryValue['agent'].value}
                        onChange={(e, data) =>
                          this.handleChangeNew(e, data, 'agent')
                        }
                      />

                      <Dropdown
                        className="condition-select"
                        style={{
                          margin: '5%',
                          maxWidth: '85%',
                          boxShadow: 'none'
                        }}
                        selection
                        options={this.state.filterDropDownOption['type']}
                        placeholder="Call Type"
                        value={this.state.queryValue['type'].value}
                        onChange={(e, data) =>
                          this.handleChangeNew(e, data, 'type')
                        }
                      />

                      <Dropdown
                        className="condition-select"
                        style={{
                          margin: '5%',
                          maxWidth: '85%',
                          boxShadow: 'none'
                        }}
                        selection
                        options={this.state.filterDropDownOption['score']}
                        placeholder="Score"
                        value={this.state.queryValue['score'].value}
                        onChange={(e, data) =>
                          this.handleChangeNew(e, data, 'score')
                        }
                      />

                      <Dropdown
                        className="condition-select"
                        style={{
                          margin: '5%',
                          maxWidth: '85%',
                          boxShadow: 'none'
                        }}
                        selection
                        options={this.state.filterDropDownOption['source']}
                        placeholder="Source"
                        value={this.state.queryValue['source'].value}
                        onChange={(e, data) =>
                          this.handleChangeNew(e, data, 'source')
                        }
                      />

                      {this.state.queryValue['tags'] && (
                        <Dropdown
                          className="condition-select"
                          style={{
                            margin: '5%',
                            maxWidth: '85%',
                            boxShadow: 'none'
                          }}
                          selection
                          options={this.state.filterDropDownOption['tags']}
                          placeholder="Tags"
                          value={this.state.queryValue['tags']?.value}
                          onChange={(e, data) =>
                            this.handleChangeNew(e, data, 'tags')
                          }
                        />
                      )}

                      <Dropdown
                        className="condition-select"
                        style={{
                          margin: '5%',
                          maxWidth: '85%',
                          boxShadow: 'none'
                        }}
                        selection
                        options={this.state.filterDropDownOption['interseted']}
                        placeholder="Lead Qualification"
                        value={this.state.queryValue['interseted']?.value}
                        onChange={(e, data) =>
                          this.handleChangeNew(e, data, 'interseted')
                        }
                      />
                      <Dropdown
                        className="condition-select"
                        style={{
                          margin: '5%',
                          maxWidth: '85%',
                          boxShadow: 'none'
                        }}
                        selection
                        options={this.state.filterDropDownOption['status']}
                        placeholder="Lead Status"
                        value={this.state.queryValue['status']?.value}
                        onChange={(e, data) =>
                          this.handleChangeNew(e, data, 'status')
                        }
                      />
                      <Dropdown
                        className="condition-select"
                        style={{
                          margin: '5%',
                          maxWidth: '85%',
                          boxShadow: 'none'
                        }}
                        selection
                        options={
                          this.state.filterDropDownOption['final_status']
                        }
                        placeholder="Call Status"
                        value={this.state.queryValue['final_status']?.value}
                        onChange={(e, data) =>
                          this.handleChangeNew(e, data, 'final_status')
                        }
                      />
                    </List>
                  </Collapse>
                </List>
                <Input
                  placeholder="Search"
                  style={{ margin: '5%', maxWidth: '85%', boxShadow: 'none' }}
                  value={this.state.queryValue['phone_number'].value}
                  onChange={e =>
                    this.handleChangeNew2(e.target.value, 'phone_number')
                  }
                  onKeyPress={this.handleChangeNew3}
                />
              </Grid>
              <Grid item xs={10.5}>
                <div className="lead-content-holder">
                  {this.state.id ? (
                    <RightPane
                      reloadTable={this.reloadLeads}
                      allTags={this.state.allTags}
                      setUpdatedPane={this.setUpdatedPane}
                      updatedTable={this.state.updatedTable}
                      leadStage={this.state.leadStage}
                      rightPane={this.state.rightPane}
                      handleRightClose={this.handleRightClose}
                      id={this.state.id}
                      leadData={this.state.leadData}
                      nextLeadId={this.state.nextLeadId}
                      prevLeadId={this.state.prevLeadId}
                      leadOwner={this.state.leadOwner}
                      leadScore={this.state.leadScore}
                      handleRightPaneOpen={this.handleRightPaneOpen}
                      handleLoading={this.handleLoading}
                      options={this.state.filterDropDownOption}
                      setDataLoadValue={() =>
                        this.setState({
                          dataLoaded: true
                        })
                      }
                      setLoadingValue={() =>
                        this.setState({
                          isLoading: false
                        })
                      }
                    />
                  ) : (
                    ''
                  )}
                  <Sidebar.Pushable as={Segment}>
                    <Sidebar.Pusher>
                      <Segment basic>
                        <div
                          onClick={this.closeDropdown}
                          className={
                            isMarkOpen
                              ? 'holder-content mark-open'
                              : 'holder-content leads_main'
                          }
                        >
                          <div className="holder-table">
                            <div
                              style={{
                                padding: '10px 50px',
                                minHeight: '100px',
                                margin: '0 auto',
                                textAlign: 'center',
                                display: 'none'
                              }}
                            ></div>

                            <div
                              className="lead-header"
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                            >
                              <div
                                className="filter-wrapper"
                                style={{ display: 'flex' }}
                              >
                                <div
                                  className="date-range-parent custom-range-parent"
                                  style={{ margin: '0 40px 0 0px' }}
                                >
                                  <Dropdown
                                    onChange={this.handleChangeDate}
                                    options={options}
                                    selection
                                    style={{ margin: '0 40px 0 0px' }}
                                    value={this.state.selectedPickerOption}
                                  />

                                  {this.state.selectedPickerOption ===
                                  'Select custom' ? (
                                    <CommonDateRangePicker
                                      handleApply={this.handleApply}
                                      handleCancel={this.handleCancel}
                                      initialSettings={{
                                        startDate: this.state.filterTable
                                          .startDateFilter,
                                        endDate: this.state.filterTable
                                          .endDateFilter,
                                        maxDate: moment()
                                      }}
                                    />
                                  ) : null}
                                </div>
                              </div>
                              <div className="view-btn">
                                  <Tooltip title="Advance view">
                                    <span style={{backgroundColor: '#fff'}}>
                       <AdvanceView height={25} width={25}/>
                       </span>
                       </Tooltip>
                       <Tooltip title="Simple view">
                       <span onClick={()=> this.props.history.push(
      '/leads',
  
     )} style={{backgroundColor: '#f4f5f8'}}>
                       <ListView  height={25} width={25}/>
                       </span>
                       </Tooltip>
                               <Tooltip title="Pipeline view">
                       <span style={{backgroundColor: '#fff'}} onClick={()=>this.props.history?.push("/pipeline")}>
                       <CardView height={25} width={25}/>
                       </span>
                       </Tooltip>
                        </div>

                              <div className="filter-sort">
                                {/* <span
                                  onClick={this.onClickTableCategory}
                                  className={tableData.isCall ? 'active' : null}
                                >
                                  Calls
                                </span> */}

                                <span
                                  onClick={this.onClickTableCategory}
                                  className={
                                    tableData.isMessages ? 'active' : null
                                  }
                                >
                                  Leads
                                </span>
                                {/* <span
                                  onClick={this.onClickTableCategory}
                                  className={
                                    tableData.isSchedules ? 'active' : null
                                  }
                                >
                                  Scheduled Calls
                                </span> */}

                                <span
                                  onClick={this.onClickTableCategory}
                                  className={
                                    tableData.isOutBound ? 'active' : null
                                  }
                                >
                                  Outbound Calls
                                </span>
                              </div>
                            </div>
                            <div
                              className="holder-filter-leads"
                              style={{
                                padding: '0',
                                marginBottom: '20px !important',
                                marginTop: '0px !important'
                              }}
                            >
                              <div
                                className="filter-sort"
                                style={{
                                  display: 'flex',
                                  flexGrow: '1',
                                  flexDirection: 'row',
                                  padding: '5px'
                                }}
                              >
                                {this.state.allLeadSubStatus.map(
                                  (item, idx) => {
                                    return (
                                      <span
                                        onClick={e =>
                                          this.setCurrentSubStatus(item.value)
                                        }
                                        key={idx}
                                        className={
                                          item.value ===
                                          this.state.currentLeadSubStatus
                                            ? `${item.value} active`
                                            : `${item.value}`
                                        }
                                        style={{
                                          flexGrow: '1',
                                          width: '400px !important'
                                        }}
                                      >
                                        {this.state.mainTab ===
                                        'Scheduled Calls'
                                          ? item.scheduleText
                                          : item.text}
                                        <p
                                          style={
                                            item.value ===
                                            this.state.currentLeadSubStatus
                                              ? {
                                                  color: 'white',
                                                  marginLeft: '20px'
                                                }
                                              : {
                                                  color: 'black',
                                                  marginLeft: '20px'
                                                }
                                          }
                                        >
                                          {`(${
                                            this.state.mainTab ===
                                            'Scheduled Calls'
                                              ? sidebarCount[
                                                  item.scheduleVar
                                                ] || 0
                                              :  this.state.mainTab ===
                                              'Leads' ? this.state?.manageViewsCount?.['all_leads']?.[item.tabVar] || 0:sidebarCount[item.var] || 0
                                          })`}
                                        </p>
                                      </span>
                                    )
                                  }
                                )}
                              </div>
                            </div>

                            <div className="holder-table-main">
                              <div className="holder-filter">
                                <div className="holder-date-range">
                                  <CommonSelect
                                    onChange={e => this.onChangeSelectFilter(e)}
                                    name="dateRange"
                                    placeholder="Date"
                                    options={filter.dateRange}
                                  />
                                </div>
                                <div className="holder-status">
                                  <CommonSelect
                                    onChange={this.onChangeSelectFilter}
                                    name="status"
                                    placeholder="Call Status"
                                    options={filter.status}
                                  />
                                </div>

                                <div className="holder-agent">
                                  <CommonSelect
                                    onChange={this.onChangeSelectFilter}
                                    name="agent"
                                    placeholder="Agent"
                                    options={filter.agent}
                                  />
                                </div>
                                <div className="holder-score">
                                  <CommonSelect
                                    onChange={this.onChangeSelectFilter}
                                    name="score"
                                    placeholder="Score"
                                    options={filter.score}
                                  />
                                </div>
                                <div className="holder-score">
                                  <CommonSelect
                                    onChange={this.onChangeSelectFilter}
                                    name="owner"
                                    placeholder="Owner"
                                    options={filter.owner}
                                  />
                                </div>

                                {this.state.showDatePicker && (
                                  <div className="holder-date show">
                                    <div className="holder-datepicker"></div>
                                  </div>
                                )}
                              </div>
                              <div className="holder-search">
                                <input
                                  onChange={this.onChangeInputFilter}
                                  name="search"
                                  type="text"
                                  placeholder="Mail, Phone, Name"
                                />
                              </div>
                            </div>
                            <div className="table lead_table">
                              <div
                                className="table-content"
                                style={{ minHeight: '300px' }}
                              >
                                {this.state.countItem ? (
                                  <div
                                    className="holder-pagination"
                                    style={{
                                      justifyContent: 'left',
                                      padding: '33px',
                                      overflow: 'scroll !important'
                                    }}
                                  >
                                    <div className="pagination">
                                      {this.state.countItem ? (
                                        <div className="table-delete leas_delete_box">
                                          <p>
                                            {this.state.countItem} of{' '}
                                            {this.state.tableDataContent.length}{' '}
                                            records selected
                                          </p>

                                          <div className="chart-filter ml-2">
                                            <div className="heading">
                                              <div className="holder-btn-export">
                                                <div className="btn-export-data">
                                                  {this.state.countItem ? (
                                                    <button
                                                      className="btn-export"
                                                      type="button"
                                                      onClick={
                                                        this.openExportFile
                                                      }
                                                    >
                                                      <Image src={exportIcon} />
                                                      Export
                                                    </button>
                                                  ) : null}
                                                </div>

                                                <div className="holder-export-options">
                                                  <ExportToExcel
                                                    apiData={
                                                      this.state.exportData
                                                    }
                                                    fileName="Excel"
                                                  />
                                                  {this.state.exportData && (
                                                    <CSVLink
                                                      target="_blank"
                                                      style={{
                                                        color: 'white',
                                                        textDecoration: 'none'
                                                      }}
                                                      data={
                                                        this.state.exportData &&
                                                        this.state.exportData
                                                      }
                                                      headers={headers}
                                                    >
                                                      CSV
                                                    </CSVLink>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="chart-filter ml-2">
                                            <div className="heading">
                                              <div className="holder-btn-export">
                                                <Popup
                                                  className="profile_popup"
                                                  content={
                                                    <BulkOperationMenu
                                                      options={statusOption}
                                                      saveOption={tag =>
                                                        this.handleUpdateStatusBulk(
                                                          tag
                                                        )
                                                      }
                                                    />
                                                  }
                                                  size="large"
                                                  position="bottom center"
                                                  flowing
                                                  on="click"
                                                  trigger={
                                                    <div
                                                      style={{
                                                        height: '20px',
                                                        width: '20px'
                                                      }}
                                                      className="image-size"
                                                    >
                                                      {/* status */}
                                                      <img
                                                        alt="dot"
                                                        src={dotIcon}
                                                      />
                                                    </div>
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="chart-filter ml-2">
                                            <div className="heading">
                                              <div className="holder-btn-export">
                                                <Popup
                                                  className="profile_popup"
                                                  content={
                                                    <BulkOperationMenu
                                                      options={
                                                        this.state?.leadStage ||
                                                        []
                                                      }
                                                      saveOption={tag =>
                                                        this.handleUpdateStageBulk(
                                                          tag
                                                        )
                                                      }
                                                    />
                                                  }
                                                  size="large"
                                                  position="bottom center"
                                                  flowing
                                                  on="click"
                                                  trigger={
                                                    <div
                                                      style={{
                                                        height: '20px',
                                                        width: '20px'
                                                      }}
                                                      className="image-size"
                                                    >
                                                      {/* stage */}
                                                      <img
                                                        alt="stage"
                                                        src={leadStageIcon}
                                                      />
                                                    </div>
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="chart-filter ml-2">
                                            <div className="heading">
                                              <div className="holder-btn-export">
                                                <div className="btn-export-data">
                                                  <Popup
                                                    className="profile_popup"
                                                    content={
                                                      <BulkOperationMenu
                                                        options={
                                                          this.state
                                                            .filterDropDownOption[
                                                            'interseted'
                                                          ] || []
                                                        }
                                                        saveOption={tag =>
                                                          this.handleUpdateInterestBulk(
                                                            tag
                                                          )
                                                        }
                                                      />
                                                    }
                                                    size="large"
                                                    position="bottom center"
                                                    flowing
                                                    on="click"
                                                    trigger={
                                                      <div
                                                        style={{
                                                          height: '20px',
                                                          width: '20px'
                                                        }}
                                                        className="image-size"
                                                      >
                                                        {/* Qualification */}
                                                        <img
                                                          alt="ints"
                                                          src={leadIntrest}
                                                        />
                                                      </div>
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="chart-filter ml-2">
                                            <div className="heading">
                                              <div className="holder-btn-export">
                                                <div className="btn-export-data">
                                                  <Popup
                                                    className="profile_popup"
                                                    content={
                                                      <BulkOperationMenu
                                                        options={
                                                          this.state
                                                            .leadScore || []
                                                        }
                                                        saveOption={tag =>
                                                          this.handleUpdateScoreBulk(
                                                            tag
                                                          )
                                                        }
                                                      />
                                                    }
                                                    size="large"
                                                    position="bottom center"
                                                    flowing
                                                    on="click"
                                                    trigger={
                                                      <div
                                                        style={{
                                                          height: '20px',
                                                          width: '20px'
                                                        }}
                                                        className="image-size"
                                                      >
                                                        {/* Score */}
                                                        <img
                                                          alt="ints"
                                                          src={leadScoreIcon}
                                                        />
                                                      </div>
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="chart-filter ml-2">
                                            <div className="heading">
                                              <div className="holder-btn-export">
                                                <div className="btn-export-data">
                                                  <Popup
                                                    className="profile_popup"
                                                    content={
                                                      <BulkOperationMenu
                                                        options={
                                                          this.state
                                                            .filterDropDownOption[
                                                            'owner'
                                                          ] || []
                                                        }
                                                        saveOption={tag =>
                                                          this.handleUpdateOwnerBulk(
                                                            tag
                                                          )
                                                        }
                                                      />
                                                    }
                                                    size="large"
                                                    position="bottom center"
                                                    flowing
                                                    on="click"
                                                    trigger={
                                                      <div
                                                        style={{
                                                          height: '20px',
                                                          width: '20px'
                                                        }}
                                                        className="image-size"
                                                      >
                                                        {/* Owner */}
                                                        <img
                                                          alt="ints"
                                                          src={userIcon}
                                                        />
                                                      </div>
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="chart-filter ml-2">
                                            <div className="heading">
                                              <div className="holder-btn-export">
                                                <div className="btn-export-data">
                                                  <Popup
                                                    className="profile_popup"
                                                    content={
                                                      <BulkOperationMenu
                                                        options={this.state.allTags.map(
                                                          item => {
                                                            return {
                                                              key: item.tag,
                                                              value: item.tag,
                                                              text: item.tag
                                                            }
                                                          }
                                                        )}
                                                        saveOption={tag =>
                                                          this.handleUpdateTagsBulk(
                                                            tag
                                                          )
                                                        }
                                                      />
                                                    }
                                                    size="large"
                                                    position="bottom center"
                                                    flowing
                                                    on="click"
                                                    trigger={
                                                      <div
                                                        style={{
                                                          height: '20px',
                                                          width: '20px'
                                                        }}
                                                        className="image-size"
                                                      >
                                                        {/* Tags */}
                                                        <img
                                                          alt="ints"
                                                          src={tagsIcon}
                                                        />
                                                      </div>
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          {this.state.deleteLead && (
                                            <>
                                              <CommonButton
                                                btnClass="btn-delete"
                                                image={deleteIcon}
                                                onClick={() =>
                                                  this.deleteModalView(true)
                                                }
                                              />

                                              <Button
                                                onClick={() =>
                                                  this.deleteModalView(true)
                                                }
                                                className="btn-clear"
                                              >
                                                Delete Leads
                                              </Button>
                                            </>
                                          )}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="holder-edit-fields"></div>
                                  </div>
                                ) : null}
                                <div className="lead-table-holder">
                                  <ManageLeadTable
                                    allTags={this.state.allTags}
                                    outboundCall={
                                      this.state.tableData.isOutBound
                                    }
                                    reloadTable={this.reloadLeads}
                                    setUpdatedTable={this.setUpdatedTable}
                                    updatedPane={this.state.updatedTable}
                                    leadScore={this.state.leadScoreFilter}
                                    tableDataContent={
                                      this.state.tableDataContent
                                    }
                                    tableFullData={this.state.tableFullData}
                                    isMarkAllCheckbox={isMarkAllCheckbox}
                                    isMarkOpen={isMarkOpen}
                                    leadStage={this.state.leadStage}
                                    leadOwner={this.state.leadOwner}
                                    onClickMarkAllCheckbox={
                                      this.onClickMarkAllCheckbox
                                    }
                                    leadType={this.getLeadType()}
                                    leadTypeNew={this.getLeadTypeNew()}
                                    onClickMark={this.onClickMark}
                                    onClickMarkCheckbox={
                                      this.onClickMarkCheckbox
                                    }
                                    handleScore={this.handleScore}
                                    onHoverScore={this.onHoverScore}
                                    toggleTags={this.toggleTags}
                                    handleTagsData={this.handleTagsData}
                                    setSortField={this.setSortField}
                                    dropDownData={this.state.dropDownData}
                                    rightPane={this.state.rightPane}
                                    handleRightPaneOpen={
                                      this.handleRightPaneOpen
                                    }
                                    params={this.state.params}
                                    setLeadsStates={this.setLeadsStates}
                                    setUserId={this.setUserId}
                                    setAllTags={this.setAllTags}
                                    handleLoading={this.handleLoading}
                                    setWidgets={this.setWidgets}
                                  />
                                </div>
                                <div
                                  className="holder-pagination"
                                  style={{
                                    padding: '0px 0px 33px 0px',
                                    float: 'center'
                                  }}
                                >
                                  <div className="pagination">
                                    {this.state.totalPages > 1 && (
                                      <Pagination
                                        defaultActivePage={1}
                                        totalPages={this.state.totalPages}
                                        onPageChange={this.onPageChange}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Segment>
                    </Sidebar.Pusher>
                  </Sidebar.Pushable>
                </div>
              </Grid>
            </Grid>
            <Modal
              className="local-number-modal"
              open={this.state.deleteModal}
              onClose={e => this.deleteModalView(false)}
            >
              <Modal.Content>
                <div className="modal-content">
                  <p style={{ marginTop: 20 }}>
                    Are you sure you want to delete this lead ?
                  </p>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                      paddingTop: 10,
                      paddingBottom: 10
                    }}
                  >
                    <CommonButton
                      onClick={() => this.deleteModalView(false)}
                      type="button"
                      content="Cancel"
                      background="red"
                      style={{
                        borderRadius: 0,
                        backgroundColor: '#e0e1e2',
                        color: '#5a5a5a'
                      }}
                    />

                    <CommonButton
                      onClick={this.onDeleteLeadHandler}
                      type="button"
                      content="Delete"
                      background="blue"
                      style={{
                        marginLeft: 20,
                        marginRight: 20,
                        borderRadius: 0,
                        backgroundColor: '#1071eb',
                        color: '#ffffff'
                      }}
                    />
                  </div>
                </div>
              </Modal.Content>
            </Modal>

            <Modal
              className="local-number-modal"
              open={this.state.settingModal}
              onClose={e => this.toggleSettingModal(false)}
            >
              <Modal.Content>
                <div className="modal-content">
                  {this.state.segments?.map((item, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <p style={{ marginTop: 20 }}>
                          {item.name}
                          {!this.state.updateList ? (
                            <i
                              style={{ marginLeft: '5px', fontSize: '13px' }}
                              className="icon trash"
                              onClick={() => this.deleteList(item.id)}
                            ></i>
                          ) : (
                            <i
                              style={{ marginLeft: '5px', fontSize: '13px' }}
                              className="icon save"
                              onClick={() => this.listUpdate(item.id)}
                            ></i>
                          )}
                        </p>
                      </React.Fragment>
                    )
                  })}

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                      paddingTop: 10,
                      paddingBottom: 10
                    }}
                  ></div>
                </div>
              </Modal.Content>
            </Modal>

            <Modal
              size="tiny"
              open={this.state.segmentModal}
              onClose={e => this.closeSegment()}
            >
              <Modal.Header>Enter Segment name</Modal.Header>
              <Modal.Content>
                <Input
                  placeholder="Enter Segment name"
                  onChange={e => this.changeSegment(e.target.value)}
                  type="text"
                  value={this.state.segmentName}
                />
              </Modal.Content>
              <Modal.Actions>
                <CommonButton
                  onClick={() => this.closeSegment()}
                  type="button"
                  content="Cancel"
                  background="red"
                  style={{
                    borderRadius: 0,
                    backgroundColor: '#e0e1e2',
                    color: '#5a5a5a'
                  }}
                />

                <CommonButton
                  onClick={this.saveSegment}
                  type="button"
                  content="Save"
                  background="blue"
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                    borderRadius: 0,
                    backgroundColor: '#1071eb',
                    color: '#ffffff'
                  }}
                />
              </Modal.Actions>
            </Modal>

            <Modal
              open={this.state.listModal}
              onClose={e => this.closeList()}
              size="tiny"
            >
              <Modal.Header>Enter List name</Modal.Header>
              <Modal.Content style={{ padding: '20px' }}>
                <Input
                  fluid
                  onChange={e => this.changeList(e.target.value)}
                  type="text"
                  value={this.state.listName}
                  placeholder="Enter List name"
                />
              </Modal.Content>
              <Modal.Actions>
                <CommonButton
                  onClick={() => this.closeList()}
                  type="button"
                  content="Cancel"
                  background="red"
                  style={{
                    borderRadius: 0,
                    backgroundColor: '#e0e1e2',
                    color: '#5a5a5a'
                  }}
                />

                <CommonButton
                  onClick={this.saveList}
                  type="button"
                  content="Save"
                  background="blue"
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                    borderRadius: 0,
                    backgroundColor: '#1071eb',
                    color: '#ffffff'
                  }}
                />
              </Modal.Actions>
            </Modal>
          </div>
        </div>
      </>
    )
  }
}

export default ManageLeads
