import React, { Component, useEffect, useState } from 'react'

import Title from '../common/Title'

import InnerNavigation from '../common/InnerNavigation'

import icon from '../assets/images/settingIcon.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Button, Popover } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'

const titleContent = {
  type: 'image',
  titleOne: icon,
  titleTwo: 'Phone Numbers'
}

const settingsData = [
  {
    mainSidebar: 'Manage Number',
    innerTabs: ['Manage Number']
  }
]

export const Numbers = props => {
  const [sideTab, setSideTab] = useState(0)
  const navigate = useHistory()
    const [anchorEl, setAnchorEl] = useState(null)

    const handlePopoverOpen = event => {
      setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
      setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
  useEffect(() => {
    document.title = 'Numbers'
  }, [])

  return (
    <div
      className="fullwidth-container number-container"
      style={{ margin: 'auto' }}
    >
      <>
        <div
          className="numbers-banner justify-content-end"
          style={{ padding: '30px' }}
        >
          {/* <div className="d-flex">
                  <div className="banner-icon">
                    <img src={icon} alt="icon" />
                  </div>
                  <div className="banner-description-wrapper">
                    <p
                      className="banner-description"
                      style={{
                        fontSize: '22px',
                        color: 'black',
                        fontWeight: 500
                      }}
                    >
                      Manage your Virtual phone numbers.
                    </p>
                  </div>
                </div> */}
        </div>
        
        <InnerNavigation
          dataComponent={settingsData}
          activeTab={props.activeTab}
          activeComponent={props.activeComponent}
          dataTitle={titleContent}
        />
      </>
    </div>
  )
}

export default Numbers
