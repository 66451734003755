import React, { Component } from 'react'
import { Button, Image, Label, Modal } from 'semantic-ui-react'
import {
  Menu,
  Tab,
  Icon,
  Dropdown,
  Input,
  TextArea,
  Sidebar,
  Segment,
  Popup,
  Accordion,
  Progress
} from 'semantic-ui-react'
import Pusher from 'pusher-js'
import moment from 'moment'
import relax from  '../assets/images/relax.svg'

import Title from '../common/Title'

import CommonButtons from '../common/CommonButtons'
import CommonSelect from '../common/CommonSelect'
import CommonInput from '../common/CommonInput'

import axios from 'axios'

import { CommonNotify } from '../common/CommonNotify'

import DoneIcon from '@mui/icons-material/Done'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import ErrorIcon from '@mui/icons-material/Error'
import PeopleIcon from '@mui/icons-material/People'
import BusinessIcon from '@mui/icons-material/Business'
import PersonIcon from '@mui/icons-material/Person'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import PhoneIcon from '@mui/icons-material/Phone'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import ChatIcon from '@mui/icons-material/Chat'
import leadLogo from '../assets/images/lead-logo.svg'
import circlePlus from '../assets/images/cicle-plus.png'
import deleteIcon from '../assets/images/delete-icon.png'
import checkIcon from '../assets/images/checkList.png'
import {
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  IconButton,
  ListItem,
  Popover,
  Rating,
  TextField,
  Typography
} from '@mui/material'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import DraftsIcon from '@mui/icons-material/Drafts'
import SendIcon from '@mui/icons-material/Send'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import StarBorder from '@mui/icons-material/StarBorder'
import {
  AccountCircle,
  AlternateEmail,
  Delete,
  Group,
  MarkChatRead,
  MoreHoriz,
  NoAccounts,
  NotificationsPaused
} from '@mui/icons-material'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import BasicMenu from '../common/BasicMenu'
import { connect } from 'react-redux'
import { getProfile } from '../actions/settings'
import { getConversations } from '../actions/conversation'

const emptyObg = {
  first_name: '',
  last_name: '',
  company: '',
  type: '',
  email: '',
  phone_number: '',
  country: '',
  city: ''
}

const searchDetail = {
  name: '',
  id: 0
}

class ConversationContent extends Component {
  state = {
    selectedValuePriority: 3,
    activeConvoId: 0,
    activeMsg: '',
    activeTab: 'inbox',
    selectedValueNumber: 'All',
    selectedContact: null,
    activeConversation: null,
    allActiveconversations: [],
    sActiveconversations: [],
    dActiveconversations: [],
    conversations: [],
    commonContacts: [],
    participants: [],
    numlist: [],
    leadNotes: [],
    convoObject: null,
    activeQualification: 0,
    activeIndex: 0,
    activeIndexMessage: 0,
    activeItemMessage: 0,
    activeItemMenu: 0,
    activeItem: 'inbox',
    isModalOpen: false,
    filterConversation: 'inbox',

    isModalMessageOpen: false,
    animation: 'overlay',
    direction: 'right',
    dimmed: false,
    visible: false,
    animationSearch: 'overlay',
    directionSearch: 'right',
    dimmedSearch: false,
    openInbox: true,
    openAssigned: false,
    openChanells: true,
    openContact: true,
    openAttribute: false,
    openFeedback: false,
    openPrevConv: false,
    openLead: false,
    openDetails: true,
    visibleSearch: false,
    animationCall: 'overlay',
    directionCall: 'bottom',
    dimmedCall: false,
    visibleCall: false,
    animationContact: 'overlay',
    directionContact: 'center',
    dimmedContact: false,
    visibleContact: true,
    channel: null,
    setOfficeHour2: {
      addOfficeHour2: '',
      link: 'Link',
      officeHourFrom: '',
      officeHourTo: ''
    },
    contacts: [emptyObg],
    searchDetails: [searchDetail],
    setNote: {
      addNote: '',
      link: 'Link',
      officeHourFrom: '',
      officeHourTo: ''
    },
    addNote: [1],
    chosenEmoji: '',
    setChosenEmoji: '',
    allConversations: [],
    agentId: null,
    selectedIndex: 0,
    countAll: 0,
    countInbox: 0,
    countUnasigned: 0,
    countMentions: 0
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.profile !== prevProps.profile) {
      this.getPersonalLink(this.props.profile)
    }

    if (this.state.activeConversation !== prevState.activeConversation) {
      const pusher = new Pusher('7b262bf6c81fabf9536b', {
        cluster: 'eu',
        encrypted: true
      })
      const channel = pusher.subscribe(
        `conversation-channel-${this.state.widgetId}`
      )
      channel.bind(
        `conversation-message-${this.state.activeConversation?.id}`,
        data => {
        }
      )
    }

    if (this.props.allConversations !== prevProps.allConversations) {
      this.setState({
        allConversations: this.props.allConversations?.conversations,
        countAll: this.props.allConversations?.all,
        countInbox: this.props.allConversations?.inbox,
        countUnasigned: this.props.allConversations?.unassigned,
        countMentions: this.props.allConversations?.mentions
      })
      if (this.state.selectedValueNumber === 'All') {
        this.setState(
          {
            conversations: this.props.allConversations?.conversations
          },
          () => this.checkContacts()
        )
      } else {
        this.setState(
          {
            conversations: this.props.allConversations?.conversations.filter(
              el => el?.source === this.state.selectedValueNumber
            )
          },
          () => this.checkContacts()
        )
      }
    }
  }

  componentDidMount = async () => {
    const widgetScript = localStorage.getItem('widgetScript')
    const widgetId = localStorage.getItem('widgetId')
    const agentId = parseInt(localStorage.getItem('id'))
    if (this.props.profile) {
      this.getPersonalLink(this.props.profile)
    } else {
      this.props.getProfile()
    }

    await this.getNumbers()

    if (this.props.allConversations) {
      this.setState(
        {
          conversations: this.props.allConversations?.conversations.filter(
            el => el.owner_id === this.state.agentId
          ),
          allConversations: this.props.allConversations?.conversations,
          allConversations: this.props.allConversations?.conversations,
          countAll: this.props.allConversations?.all,
          countInbox: this.props.allConversations?.inbox,
          countUnasigned: this.props.allConversations?.unassigned,
          countMentions: this.props.allConversations?.mentions,
          loading: false
        },
        () => this.handleFilters()
      )
    } else {
      this.fetchConversation()
    }
    this.fetchTeams()

    this.setState({ widgetScript, widgetId, agentId })

    this.fetchContacts(null)
  }

  getPersonalLink = res => {
    if (!res) {
      res = this.props.profile
    }

    const urlData = res.data?.personal_link?.split('me/').pop()
    this.setState({
      agentCode: urlData
    })
  }

  fetchTeams = async () => {
    const apiToken = await localStorage.getItem('access_token')

    this.setState({
      isLoading: true
    })

    const data = []
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/teams?limit=20000&offset=0`

    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          this.setState({
            membersTeam: res.data.data,
            isLoading: false
          })

          this.fetchMembers()
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false
        })
      })
  }

  processList = () => {
    const { members, membersTeam } = this.state
    const allList = [...members, ...membersTeam]
    const processList = allList.map(item => {
      return {
        key: item.first_name?.length ? item.id : item.id + '-team',
        value: item.id,
        text: item.first_name?.length ? item.first_name : item.name + ' team'
      }
    })

    this.setState({ membersList: processList })
  }

  fetchMembers = async () => {
    const { widget, loading } = this.props
    this.setState({
      isLoading: true
    })

    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`

    axios
      .get(url)
      .then(res => {
        this.setState({
          isLoading: false
        })
        if (res.data.data) {
          const dt1 = [...res.data.data.members]
          dt1.push(res.data.data.admin)

          this.setState({ members: dt1 }, () => this.processList())
        }
      })
      .catch(error => {
        const { response = {} } = error || {}
        const { data = {} } = response
        const { message = '' } = data
        // CommonNotify("cant fetch team")
      })
  }

  getMessagesNotes = conversation =>
    conversation?.messages?.concat(conversation.notes)?.sort(this.compareNotes)

  allActiveConvo = (i, tab) => {
    let { activeConversation } = this.state
    this.setState({
      activeConversation: null,
      selectedContact: null,
      openDetails: true
    })
    if (tab === 'inbox') {
      activeConversation = {
        ...this.state.allActiveConversations[i],
        messages: this.getMessagesNotes(this.state.allActiveConversations[i])
      }
    } else if (tab === 'closed') {
      activeConversation = {
        ...this.state.dActiveConversations[i],
        messages: this.getMessagesNotes(this.state.dActiveConversations[i])
      }
    } else {
      activeConversation = {
        ...this.state.sActiveConversations[i],
        messages: this.getMessagesNotes(this.state.sActiveConversations[i])
      }
    }

    if (activeConversation?.contact_id) {
      this.fetchContact(activeConversation?.contact_id)
    }

    /* this.state.channel?.bind(
      `conversation-message-${activeConversation?.id}`,
      data => {
      }
    ) */

    this.setState(
      {
        activeConvoId: i,
        activeTab: tab,
        activeConversation,
        selectedValuePriority: activeConversation?.priority,
        selectedValueAssign: activeConversation?.owner_id
      },
      () => {
        if (activeConversation?.unread_messages > 0) {
          const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/mark-read`
          const ids = []
          ids[0] = activeConversation?.id
          axios
            .put(url, {
              ids
            })
            .then(res => {
              this.getConversationById()
            })
            .catch(error => {
              this.setState({ loading: false })
            })
        }
        if (document.querySelector('.height-message-content')) {
          document
            .querySelector('.height-message-content')
            .scrollTo(
              0,
              document.querySelector('.height-message-content').scrollHeight
            )
        }
      }
    )
  }

  getNumbers = async () => {
    // fetch number from profile

    const apiToken = await localStorage.getItem('access_token')

    this.setState({ loading: true })
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/get-user-numbers`
    axios
      .get(url)
      .then(res => {
        const numbers = res.data.data.map(number => ({
          phone_number: number.phone_number,
          name: number.friendly_name,
          symbole: number.symbole
        }))

        const numlist = numbers.map((item, index) => {
          return {
            text: item.phone_number,
            symbole: item.symbole,
            value: item.phone_number,
            key: index,
            name: item.name
          }
        })
        this.setState({
          loading: false,
          numbers,
          numlist,
          selectedValueNumber: 'All'
        })
      })
      .catch(error => {
        this.setState({ loading: false })
      })
  }

  deleteConversation = id => {
    if (this.state.activeConversation) {
      const conId = this.state.conversations.find(con => {
        if (con.contact_id === id) return con.id
      })

      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/delete`

      let conversation = this.state.activeConversation?.id
      if (conId) conversation = conId.id
      const data = {
        ids: [conversation],
        script_id: this.state.widgetScript
      }

      axios
        .delete(url, { data })
        .then(res => {
          this.fetchConversation()
        })
        .catch(error => {
          this.setState({ loading: false })
        })
    }
  }

  setupConversation = async () => {
    this.setState({
      isModalMessageOpen: !this.state.isModalMessageOpen
    })

    if (!this.state.newNumber) {
      return CommonNotify('Number not added')
    }

    if (this.state.widgetScript === '') {
      return CommonNotify('Widget Not installed / not available')
    }

    const data = {
      friendly_name: 'New Conversation',
      script_id: this.state.widgetScript,
      source: this.state.newNumber
    }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation`
    const urlParticipant = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/participant`
    axios
      .post(url, data)
      .then(res => {
        const dataParticipant = {
          sid: res.data.data.sid,
          number: parseInt(res.data.data.source),
          name: res.data.data.friendly_name
        }
        axios
          .post(urlParticipant, dataParticipant)
          .then(res => {
            this.fetchConversation()
          })
          .catch(error => {
            this.setState({ loading: false })
            CommonNotify(
              'Cant Add participants, Participant Already exist in another conversation'
            )
            this.fetchConversation()
          })
        //  this.updateConvo(contact, res.data.data.id, res.data.data.sid)
      })
      .catch(error => {
        this.setState({ loading: false })
      })
  }

  checkContacts = () => {
    let { conversations } = this.state

    let commonContacts = conversations.map(item => item.contact_id)

    let allActiveConversations = conversations
      .filter(item => {
        if (item.state === 'active') {
          return item
        }
      })
      .sort(this.compare)

    let sActiveConversations = conversations
      .filter(item => {
        if (item.state === 'snoozed') {
          return item
        }
      })
      .sort(this.compare)

    let dActiveConversations = conversations
      .filter(item => {
        if (item.state === 'closed') {
          return item
        }
      })
      .sort(this.compare)

    this.setState(
      {
        commonContacts,
        allActiveConversations,
        sActiveConversations,
        dActiveConversations
      },
      () => this.allActiveConvo(this.state.activeConvoId, this.state.activeTab)
    )
  }

  fetchConversation = async () => {
    await this.props.getConversations()
  }

  handleNoteChange = e => {
    this.setState({ note: e.target.value })
  }

  handleItemClickMessage = i => this.setState({ activeItemMessage: i })

  handleItemClickMenu = i => this.setState({ activeItemMenu: i })

  cloneSetHoursWrapper2 = () => {
    const currentDiv2 = this.state.contacts
    currentDiv2.push(emptyObg)
    this.setState({ contacts: currentDiv2 })
  }

  onClickContacts = () => {
    const { setOfficeHour2 } = this.state
    const link = this.state.setOfficeHour2.link
    const officeHourFrom = this.state.setOfficeHour2.officeHourFrom
    const officeHourTo = this.state.setOfficeHour2.officeHourTo
    const addOfficeHour2 = 'addOfficeHour2'
    const addOfficeHourItem2 = link + ' ' + officeHourFrom + ' ' + officeHourTo

    setOfficeHour2[addOfficeHour2] = addOfficeHourItem2

    this.setState({ setOfficeHour2 })

    this.cloneSetHoursWrapper2()
  }

  onClickRemoveOfficeHours2 = () => {
    const currentDiv2 = this.state.contacts
    currentDiv2.pop()
    this.setState({ contacts: currentDiv2 })
  }

  cloneAddNote = () => {
    const currentDiv3 = this.state.addNote
    currentDiv3.push(1)
    this.setState({ addNote: currentDiv3 })
  }

  onClickAddNote = () => {
    if (this.state.activeConversation.lead_id) {
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${this.state.activeConversation.lead_id}/add-note`

      const postData = {
        content: this.state.note
      }
      axios
        .post(url, postData)
        .then(res => {
          this.setState({ note: '' })
          this.fetchContact(this.state.selectedContact.id)
        })
        .catch(err => {
          this.setState({ loading: false })
          CommonNotify('Cant add note ')
        })
    } else {
      CommonNotify('Lead not exist ')
    }
  }

  getConversationById = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${this.state.activeConversation.id}`

    axios
      .get(url)
      .then(res => {
        this.setState(
          {
            activeConversation: {
              ...res.data.data.conversation,
              messages: this.getMessagesNotes(res.data.data.conversation)
            }
          },
          () => {
            if (document.querySelector('.height-message-content')) {
              document
                .querySelector('.height-message-content')
                .scrollTo(
                  0,
                  document.querySelector('.height-message-content').scrollHeight
                )
            }
          }
        )
      })
      .catch(err => {})
  }

  onClickRemoveNote = id => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/delete-notes-new`

    const postData = {
      lead_id: this.state.activeConversation.lead_id,
      id
    }
    axios
      .post(url, postData)
      .then(res => {
        this.fetchContact(this.state.selectedContact.id)
      })
      .catch(err => {
        this.setState({ loading: false })
        CommonNotify('Cant delete note ')
      })
  }

  handleAnimationChange = animation => () =>
    this.setState(prevState => ({ animation, visible: !prevState.visible }))

  handleAnimationChangeSearch = animationSearch => () =>
    this.setState(prevState => ({
      animationSearch,
      visibleSearch: !prevState.visibleSearch
    }))

  handleAnimationChangeCall = animationCall => () =>
    this.setState(prevState => ({
      animationCall,
      visibleCall: !prevState.visibleCall
    }))

  handleAnimationChangeContact = animationContact => () =>
    this.setState(prevState => ({
      animationContact,
      visibleContact: !prevState.visibleContact
    }))

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }
  handleQualification = (e, titleProps) => {
    const { index } = titleProps
    const { activeQualification } = this.state
    const newIndex = activeQualification === index ? -1 : index

    this.setState({ activeQualification: newIndex })
  }
  handleClickMessage = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndexMessage } = this.state
    const newIndex = activeIndexMessage === index ? -1 : index

    this.setState({ activeIndexMessage: newIndex })
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  handleChangeAssign = async index => {
    let type = 'App\\User'
    if (this.state.membersList[index]?.key.toString()?.includes('team')) {
      type = 'App\\Team'
    }

    const postData = {
      owner_type: type,
      owner_id: this.state.membersList[index]?.value,
      widget_id: this.state.widgetId,
      ids: [this.state.activeConversation?.id]
    }

    this.setState({ loading: true })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/assign`

    axios
      .post(url, postData)
      .then(res => {
        if (res.data.errors.length >= 0) {
          this.setState({ loading: false, searchDetails: res.data.data })
          this.setState({
            selectedValueAssign: this.state.membersList[index]?.value
          })
        }
        this.fetchConversation()
      })
      .catch(err => {
        this.setState({ loading: false })
        CommonNotify('Cant assigned ')
      })
  }

  compare = (a, b) => {
    const d1 = new Date(
      a.messages
        .filter(msg => msg.message_type === 'Message')
        .slice(-1)[0]?.created_at
    )
    const d2 = new Date(
      b.messages
        .filter(msg => msg.message_type === 'Message')
        .slice(-1)[0]?.created_at
    )
    return d2 - d1
  }

  compareNotes = (a, b) => {
    const d1 = new Date(a.created_at)
    const d2 = new Date(b.created_at)
    return d1 - d2
  }

  handleFilters = () => {
    switch (this.state.filterConversation) {
      case 'all':
        this.setState(
          {
            selectedIndex: 2,
            conversations: this.state.allConversations.filter(
              el =>
                el.source === this.state.selectedValueNumber ||
                this.state.selectedValueNumber === 'All'
            )
          },
          () => this.checkContacts()
        )
        break
      case 'inbox':
        this.setState(
          {
            selectedIndex: 0,
            conversations: this.state.allConversations.filter(
              el =>
                el.owner_id === this.state.agentId &&
                (el.source === this.state.selectedValueNumber ||
                  this.state.selectedValueNumber === 'All')
            )
          },
          () => this.checkContacts()
        )
        break
      case 'unassigned':
        this.setState(
          {
            selectedIndex: 3,
            conversations: this.state.allConversations.filter(
              el =>
                !el.owner_id &&
                (el.source === this.state.selectedValueNumber ||
                  this.state.selectedValueNumber === 'All')
            )
          },
          () => this.checkContacts()
        )
        break
      case 'mentions':
        this.setState(
          {
            selectedIndex: 1,
            conversations: this.state.allConversations.filter(
              el =>
                el?.mentions?.length > 0 &&
                (el.source === this.state.selectedValueNumber ||
                  this.state.selectedValueNumber === 'All')
            )
          },
          () => this.checkContacts()
        )
        break

      default:
        this.setState(
          {
            selectedIndex: 2,
            conversations: this.state.allConversations.filter(
              el =>
                el.source === this.state.selectedValueNumber ||
                this.state.selectedValueNumber === 'All'
            )
          },
          () => this.checkContacts()
        )
        break
    }
  }

  handleFilterAssign = data => {
    this.setState(
      {
        selectedIndex: 4,
        conversations: this.state.allConversations.filter(
          el => el.owner_id === data.id
        )
      },
      () => this.checkContacts()
    )
    /* let it = this.state.membersList?.map(item => item.key)
    let type = 'App\\Team'
    if (it.includes(data.value)) {
      type = 'App\\User'
    }

    this.setState({ loading: true })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/get-by-assignee
    ?owner_type=${type}&owner_id=${data.value}&widget_id=${this.state.widgetId}`

    axios
      .get(url)
      .then(res => {
        this.setState({ conversations: this.props.allConversations?.conversations }, () =>
          this.checkContacts()
        )
      })
      .catch(err => {
        this.setState({ loading: false })
        CommonNotify('No Conversations Assigned')
      }) */
  }

  handleChangePriority = async (event, dt) => {
    const st = dt.value
    const data = {
      priority: st
    }

    this.setState({ loading: true })
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${this.state.activeConversation.id}/update`
    axios
      .put(url, data)
      .then(res => {
        this.setState({ selectedValuePriority: st })
      })
      .catch(error => {
        this.setState({ loading: false })
        CommonNotify('Cant update ')
      })
  }

  isToday(date) {
    const today = new Date()

    if (today.toDateString() === date.toDateString()) {
      return true
    }

    return false
  }

  handleChangeNumber = (event, data) => {
    if (data.value === 'All') {
      this.setState(
        {
          selectedValueNumber: 'All',
          allConversations: this.props.allConversations?.conversations,
          countAll: this.props.allConversations?.all,
          countInbox: this.props.allConversations?.inbox,
          countUnasigned: this.props.allConversations?.unassigned,
          countMentions: this.props.allConversations?.mentions
        },
        () => this.handleFilters()
      )
    } else {
      this.setState(
        {
          selectedValueNumber: data.value,
          allConversations: this.props.allConversations?.conversations,
          countAll: this.props.allConversations?.all,
          countInbox: this.props.allConversations?.inbox,
          countUnasigned: this.props.allConversations?.unassigned,
          countMentions: this.props.allConversations?.mentions
        },
        () => this.handleFilters()
      )
    }
  }

  handleModal = () => {
    let { isModalOpen, cardSummary } = this.state

    isModalOpen = !isModalOpen
    cardSummary = false
    this.setState({ isModalOpen, cardSummary })
  }

  handleModalMessage = () => {
    let { isModalMessageOpen, cardSummary } = this.state

    isModalMessageOpen = !isModalMessageOpen
    cardSummary = false
    this.setState({ isModalMessageOpen, cardSummary })
  }

  handleCloseModal = () => this.setState({ isModalOpen: false })
  handleCloseModalMessage = () => this.setState({ isModalOpen: false })

  onEmojiClick = emojiObject => {
    this.setState({ setChosenEmoji: emojiObject })
  }

  changeContact = (value, index, key) => {
    let contacts = [...this.state.contacts]
    contacts[index][key] = value
    this.setState({ contacts: contacts })
  }

  addContacts = () => {
    let { contacts } = this.state
    contacts.forEach(contact => {
      if (this.checkField(contact)) {
        this.addContact(contact)
      }
    })

    contacts = [emptyObg]
    this.setState(contacts)
  }

  checkField = contact => {
    let error = false
    Object.keys(contact).forEach(item => {
      if (item != 'company') {
        if (contact[item] === '') {
          error = true
          return
        } else {
        }
      }
    })

    if (error) {
      CommonNotify('Please fillup all fields')
    }

    return !error
  }

  fetchContacts = async param => {
    this.setState({ loading: true, openDetails: true })
    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/search`

    axios
      .post(url, { searchKey: param })
      .then(res => {
        if (res.data.errors.length >= 0) {
          this.setState({ loading: false, searchDetails: res.data.data })
        }
      })
      .catch(err => {
        this.setState({ loading: false })
      })
  }

  addNote = () => {
    this.setState({ loading: true })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${this.state.activeConversation.id}/note`

    axios
      .post(url, { note: this.state.activeNote })
      .then(res => {
        this.setState({
          activeNote: ''
        })
        this.getConversationById()
      })
      .catch(err => {
        this.setState({ loading: false })
      })
  }

  fetchContact = async id => {
    this.setState({ loading: true, leadNotes: [] })
    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${id}`

    axios
      .get(url)
      .then(res => {
        this.setState({
          loading: false,
          selectedContact: res.data.data,
          updateContact: res.data.data
        })
      })
      .catch(err => {
        this.setState({ loading: false })
      })
    const urlLeads = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${this.state.activeConversation.lead_id}/note`

    axios
      .get(urlLeads)
      .then(res => {
        if (Array.isArray(res.data.data)) {
          this.setState({
            leadNotes: res.data.data
          })
        } else {
          this.setState({
            leadNotes: []
          })
        }
      })
      .catch(err => {
        this.setState({ loading: false, leadNotes: [] })
      })
    const urlLogs = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${this.state.activeConversation.id}/logs?logs_segment_by=month`

    axios
      .get(urlLogs)
      .then(res => {})
      .catch(err => {
        this.setState({ loading: false, leadNotes: [] })
      })
  }

  addContact = async contact => {
    this.setState({ loading: true })
    contact.name = contact.first_name + ' ' + contact.last_name
    contact.owner_id = localStorage.getItem('id')

    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact`

    axios
      .post(url, contact)
      .then(res => {
        if (res.data.errors.length >= 0) {
          this.setState({ loading: false })
          CommonNotify(
            `Contact Named ${contact.name} Created Successfully`,
            'success'
          )
        }
        this.fetchContacts()
      })
      .catch(err => {
        this.setState({ loading: false })

        if (err.response.data) {
          CommonNotify('Cant Create Contacts : ' + err.response.data.errors[0])
        }
      })
  }

  assignConversation = contact => {
    return
    this.updateConvo(contact)

    let owner = localStorage.getItem('id')
    const data = {
      owner_type: 'AppUser',
      owner_id: localStorage.getItem('id')
    }

    this.setState({ loading: true })
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/assign`
    axios
      .post(url, data)
      .then(res => {
        this.updateConvo(contact)
      })
      .catch(error => {
        this.setState({ loading: false })
        CommonNotify('Cant assign owner')
      })
  }

  updateStatus = async status => {
    const apiToken = await localStorage.getItem('access_token')

    const data = {
      state: status
    }

    this.setState({ loading: true })
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${this.state.activeConversation.id}/update`
    axios
      .put(url, data)
      .then(res => {
        this.fetchConversation()
      })
      .catch(error => {
        this.setState({ loading: false })
        CommonNotify('Cant Add participants')
        this.fetchConversation()
      })
  }

  updateConvo = async (contact, convo, sid) => {
    const apiToken = await localStorage.getItem('access_token')

    let phone = contact.phone_number
    let name = contact.name
    let cId = contact.id
    let owner = localStorage.getItem('id')
    phone = phone?.replace(/ +/g, '')

    const data = {
      contact_id: contact.id,
      state: 1
    }

    this.setState({ loading: true })
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${convo}/update`
    axios
      .put(url, data)
      .then(res => {
        this.addParticipant(contact, sid)
      })
      .catch(error => {
        this.setState({ loading: false })
        CommonNotify('Cant update ')
      })
  }

  sendMessage = async () => {
    if (this.state.activeMsg) {
      const data = {
        body: this.state.activeMsg,
        sid: this.state.activeConversation.sid,
        agent_code: this.state.agentCode,
        whatsapp: 0
      }

      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/agent-push-message`
      axios
        .post(url, data)
        .then(res => {
          this.setState({ activeMsg: '' }, () => this.getConversationById())
        })
        .catch(error => {
          CommonNotify('Cant Send message')
        })
    } else {
      CommonNotify("Can't send empty message")
    }
  }

  addParticipant = (contact, sid) => {
    let phone = contact.phone_number
    let name = contact.name
    let cId = contact.id
    let owner = localStorage.getItem('id')
    phone = phone?.replace(/ +/g, '')

    const data = {
      sid: sid,
      number: phone,
      name: name
    }

    this.setState({ loading: true })
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/participant`
    axios
      .post(url, data)
      .then(res => {
        this.fetchConversation()
      })
      .catch(error => {
        this.setState({ loading: false })
        CommonNotify(
          'Cant Add participants, Participant Already exist in another conversation'
        )
        this.fetchConversation()
      })
  }

  handleClickInbox = () => {
    this.setState({
      openInbox: !this.state.openInbox
    })
  }

  handleClickAssigned = () => {
    this.setState({
      openAssigned: !this.state.openAssigned
    })
  }

  handleClickChannels = () => {
    this.setState({
      openChanells: !this.state.openChanells
    })
  }

  handleClickContacts = key => {
    switch (key) {
      case 'details':
        this.setState({
          openDetails: !this.state.openDetails
        })
        break
      case 'contact':
        this.setState({
          openContact: !this.state.openContact
        })
        break
      case 'attribute':
        this.setState({
          openAttribute: !this.state.openAttribute
        })
        break
      case 'lead':
        this.setState({
          openLead: !this.state.openLead
        })
        break
      case 'feedback':
        this.setState({
          openFeedback: !this.state.openFeedback
        })
        break
        case 'prevConv':
          this.setState({
            openPrevConv: !this.state.openPrevConv
          })
          break
      default:
        break
    }
  }

  handleUpdateContact = input => {
    if (input !== 'new' && this.state.selectedContact) {
      let data = {}

      if (this.state.selectedContact.name !== this.state.updateContact.name) {
        data = { ...data, name: this.state.selectedContact.name }
      }

      if (this.state.selectedContact.email !== this.state.updateContact.email) {
        data = { ...data, email: this.state.selectedContact.email }
      }

      if (
        this.state.selectedContact.phone_number !==
        this.state.updateContact.phone_number
      ) {
        data = {
          ...data,
          phone_number: this.state.selectedContact.phone_number
        }
      }

      if (
        this.state.selectedContact.organization_name !==
        this.state.updateContact.organization_name
      ) {
        data = {
          ...data,
          organization_name: this.state.selectedContact.organization_name
        }
      }

      if (
        (this.state.selectedContact.email !== this.state.updateContact.email &&
          input === 'email') ||
        (this.state.selectedContact.name !== this.state.updateContact.name &&
          input === 'name') ||
        (this.state.selectedContact.phone_number !==
          this.state.updateContact.phone_number &&
          input === 'phone') ||
        (this.state.selectedContact.organization_name !==
          this.state.updateContact.organization_name &&
          input === 'company')
      ) {
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/update/${this.state.selectedContact.id}`
        axios
          .post(url, data)
          .then(res => {})
          .catch(error => {
            CommonNotify('Cant update Contact')
          })
      }
    } else if (input === 'new' && this.state.newName) {
      const newdata = {
        name: this.state.newName,
        email: '',
        phone_number: this.state.activeConversation?.friendly_name?.replace(
          'customer -',
          ''
        ),
        country: 'India',
        city: 'New Delhi',
        title: '',
        owner: '',
        phone_country: '',
        type: 'individual',
        company: ''
      }
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact`
      axios
        .post(url, newdata)
        .then(res => {
          this.setState({
            loading: false,
            selectedContact: res.data.data,
            updateContact: res.data.data
          })
        })
        .catch(error => {
          CommonNotify('Cant Add Contact')
        })
    }
  }

  getMessageStatus = status => {
    switch (status) {
      case 'read':
        return <DoneAllIcon fontSize="14" color="primary" />
      case 'pending':
        return <DoneIcon />
      case 'failed':
        return <ErrorIcon fontSize="14" color="error" />
      default:
        return <DoneIcon fontSize="14" />
    }
  }

  getMessageSymbole = participant => {
    if (!participant) return <MailOutlineIcon />
    const type = participant[0].channel[0]
    switch (type) {
      case 'sms':
        return <MailOutlineIcon />
      case 'whatsapp':
        return <WhatsAppIcon />
      case 'webchat':
        return <ChatIcon />
      default:
        return <MailOutlineIcon />
    }
  }

  setAnchorEl = value => {
    this.setState({
      anchorEl: value
    })
  }

  handlePopoverOpen = event => {
    this.setAnchorEl(event.currentTarget)
  }

  handlePopoverClose = () => {
    this.setAnchorEl(null)
  }

  getMessageClass = participant => {
    if (!participant) return 'message-sms'
    const type = participant[0].channel[0]
    switch (type) {
      case 'sms':
        return 'message-sms'
      case 'whatsapp':
        return 'message-whatsapp'
      case 'webchat':
        return 'message-webchat'
      default:
        return 'message-sms'
    }
  }

  render() {
    const {
      activeIndex,
      activeItem,
      activeIndexMessage,
      activeItemMessage,
      activeItemMenu,
      isModalOpen,
      isModalMessageOpen,
      dimmed,
      visible,
      animation,
      direction,
      dimmedSearch,
      visibleSearch,
      animationSearch,
      directionSearch,
      dimmedCall,
      visibleCall,
      animationCall,
      directionCall,
      dimmedContact,
      visibleContact,
      animationContact,
      directionContact,
      vertical,
      chosenEmoji,
      setChosenEmoji,
      anchorEl
    } = this.state

    const open = Boolean(anchorEl)

    const title = {
      type: 'image',
      titleOne: leadLogo,
      titleTwo: 'Conversations'
    }

    const assignTo = [
      {
        key: 'Sales',
        text: 'Sales',
        value: 'Sales'
      },
      {
        key: 'Time',
        text: 'Time',
        value: 'Time'
      }
    ]

    const priority = [
      {
        key: 1,
        text: 'Low',
        value: 1
      },
      {
        key: 3,
        text: 'Medium',
        value: 3
      },
      {
        key: 4,
        text: 'High',
        value: 4
      }
    ]

    const number = [
      {
        key: '(812)701-3838',
        text: '(812)701-3838',
        value: '(812)701-3838'
      },
      {
        key: '(812)701-3839',
        text: '(812)701-3839',
        value: '(812)701-3839'
      }
    ]

    const icons = ['user', 'user circle', 'user circle']

    const menuDetails = [
      {
        icon: 'user',
        title: 'Myself',
        active: true
      },
      {
        icon: 'user circle',
        title: 'Syed',
        active: false
      },
      {
        icon: 'user circle',
        title: 'Rifad',
        active: false
      },
      {
        icon: 'user outline',
        title: 'Unassigned',
        active: false
      },
      {
        icon: 'users',
        title: 'All',
        active: false
      },
      {
        icon: 'shopping bag',
        title: 'Sales',
        active: false
      },
      {
        icon: 'bullhorn',
        title: 'Marketing',
        active: false
      }
    ]

    const panes = [
      {
        menuItem: (
          <Menu.Item>
            <Dropdown
              item
              text="Assigned To"
              open={activeIndex === 0}
              index={0}
              onClick={this.handleClick}
            >
              <Dropdown.Menu>
                {this.state.membersList?.map((item, i) => {
                  return (
                    <span>
                      {item.value === this.state.selectedValueAssign ? (
                        <Dropdown.Item active>
                          <Icon
                            name={item.value === item.key ? 'user' : 'users'}
                            key={i}
                          />{' '}
                          {item.text}
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item>
                          <Icon
                            name={item.value === item.key ? 'user' : 'users'}
                            key={i}
                          />{' '}
                          {item.text}
                        </Dropdown.Item>
                      )}
                    </span>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        )
      }
    ]

    const messageItem = [
      { menuItem: 'Tab 1', render: () => <Tab.Pane>Tab 1 Content</Tab.Pane> },
      { menuItem: 'Tab 2', render: () => <Tab.Pane>Tab 2 Content</Tab.Pane> },
      { menuItem: 'Tab 3', render: () => <Tab.Pane>Tab 3 Content</Tab.Pane> }
    ]

    const dataMessage = [
      {
        number: '(812)701-3838',
        date: 'Apr 9',
        latestMessage: 'Hello User001 here at LimePhone, We make your...',
        dataMessageContent: [
          {
            sender: [
              {
                message1: 'Lorem ipsum',
                message2: 'Lorem ipsum dolor sir amet'
              }
            ],
            receiver: [
              {
                message1: 'Lorem ipsum',
                message2: 'Lorem ipsum dolor sir amet'
              }
            ]
          }
        ]
      },
      {
        number: '(812)701-3838',
        date: 'Apr 9',
        latestMessage: 'Missed Call',
        dataMessageContent: [
          {
            sender: [
              {
                message1: 'Lorem ipsum test',
                message2: 'Lorem ipsum dolor sir amet test'
              }
            ],
            receiver: [
              {
                message1: 'Lorem ipsum test',
                message2: 'Lorem ipsum dolor sir amet test'
              }
            ]
          }
        ]
      }
    ]

    const dataMessageUnread = [
      {
        number: '(812)701-3838',
        date: 'Apr 9',
        latestMessage: 'Hello User001 here at LimePhone, We make your...',
        dataMessageContent: [
          {
            sender: [
              {
                message1: 'Lorem ipsum',
                message2: 'Lorem ipsum dolor sir amet'
              }
            ],
            receiver: [
              {
                message1: 'Lorem ipsum',
                message2: 'Lorem ipsum dolor sir amet'
              }
            ]
          }
        ]
      }
    ]

    const dataMessageDone = [
      {
        number: '(812)701-3838',
        date: 'Apr 9',
        latestMessage: 'Missed Call',
        dataMessageContent: [
          {
            sender: [
              {
                message1: 'Lorem ipsum test',
                message2: 'Lorem ipsum dolor sir amet test'
              }
            ],
            receiver: [
              {
                message1: 'Lorem ipsum test',
                message2: 'Lorem ipsum dolor sir amet test'
              }
            ]
          }
        ]
      }
    ]

    const userDetails = {
      name: 'Ghiyast H',
      location: 'Jakarta, Indonesia',
      time: '11:46pm',
      phone: '+1-234-345-456-5544',
      email: 'ghiyasth@gmail.com',
      ipAddress: '112.234.456.65',
      lastSeen: 'Jan 31, 2020 2:32pm',
      created: 'Jan 31, 2020 2:32pm',
      session: '01',
      push: ''
    }

    const messages = [
      {
        menuItem: 'Open',
        render: () => (
          <div className="hover-item">
            <Menu fluid vertical tabular>
              {this.state.allActiveConversations?.map((item, i) => {
                return (
                  <Menu.Item
                    active={this.state.activeConvoId === i}
                    onClick={() => this.allActiveConvo(i, 'inbox')}
                    key={i}
                    id={i}
                  >
                    <div className="message-holder">
                      <div className={this.getMessageClass(item.participant)}>
                        {this.getMessageSymbole(item.participant)}
                      </div>
                      <div className="message-content">
                        <div
                          className="message-numdate"
                          style={{ fontSize: 14 }}
                        >
                          <p style={{ fontSize: 14 }}>
                            {item?.friendly_name?.replace('customer - ', '')}
                          </p>
                          <p className="message-date">
                            {this.isToday(
                              new Date(
                                item.messages
                                  .filter(msg => msg.message_type === 'Message')
                                  .slice(-1)[0]?.created_at
                              )
                            )
                              ? moment(
                                  item.messages
                                    .filter(
                                      msg => msg.message_type === 'Message'
                                    )
                                    .slice(-1)[0]?.created_at
                                ).format('LT')
                              : moment(
                                  item.messages
                                    .filter(
                                      msg => msg.message_type === 'Message'
                                    )
                                    .slice(-1)[0]?.created_at
                                ).format('MMM DD')}
                          </p>
                        </div>
                        <Box display="flex" justifyContent="space-between">
                          <span>
                            {
                              item.messages
                                .filter(msg => msg.message_type === 'Message')
                                .slice(-1)[0]?.body
                            }
                          </span>
                          {item.unread_messages > 0 && (
                            <span
                              style={{
                                backgroundColor: 'rgb(33, 133, 208)',
                                color: 'white',
                                padding: '5px',
                                borderRadius: '20%'
                              }}
                            >
                              {item.unread_messages}
                            </span>
                          )}
                        </Box>
                      </div>
                    </div>
                  </Menu.Item>
                )
              })}
            </Menu>
          </div>
        )
      },
      {
        menuItem: 'Snooze',
        render: () => (
          <div>
            <Menu fluid vertical tabular>
              {this.state.sActiveConversations?.map((item, i) => {
                return (
                  <Menu.Item
                    active={this.state.activeConvoId === i}
                    onClick={() => this.allActiveConvo(i, 'snooze')}
                    key={i}
                    id={i}
                  >
                    <div className="message-holder">
                      <div className={this.getMessageClass(item.participant)}>
                        {this.getMessageSymbole(item.participant)}
                      </div>
                      <div className="message-content">
                        <div
                          className="message-numdate"
                          style={{ fontSize: 14 }}
                        >
                          <p style={{ fontSize: 14 }}>
                            {item?.contact?.name
                              ? item?.contact?.name
                              : item.source}
                          </p>

                          <p className="message-date">
                            {moment(
                              item.messages
                                .filter(msg => msg.message_type === 'Message')
                                .slice(-1)[0]?.created_at
                            )
                              .utc()
                              .format('MMM DD')}
                          </p>
                        </div>
                        <Box display="flex" justifyContent="space-between">
                          <span>
                            {
                              item.messages
                                .filter(msg => msg.message_type === 'Message')
                                .slice(-1)[0]?.body
                            }
                          </span>
                          {item.unread_messages > 0 && (
                            <span
                              style={{
                                backgroundColor: 'rgb(33, 133, 208)',
                                color: 'white',
                                padding: '5px',
                                borderRadius: '20%'
                              }}
                            >
                              {item.unread_messages}
                            </span>
                          )}
                        </Box>
                      </div>
                    </div>
                  </Menu.Item>
                )
              })}
            </Menu>
          </div>
        )
      },
      {
        menuItem: 'Closed',
        render: () => (
          <div>
            <Menu fluid vertical tabular>
              {this.state.dActiveConversations?.map((item, i) => {
                return (
                  <Menu.Item
                    active={this.state.activeConvoId === i}
                    onClick={() => this.allActiveConvo(i, 'closed')}
                    key={i}
                    id={i}
                  >
                    <div className="message-holder">
                      <div className={this.getMessageClass(item.participant)}>
                        {this.getMessageSymbole(item.participant)}
                      </div>
                      <div className="message-content">
                        <div
                          className="message-numdate"
                          style={{ fontSize: 14 }}
                        >
                          <p style={{ fontSize: 14 }}>
                            {item?.contact?.name
                              ? item?.contact?.name
                              : item.source}
                          </p>
                          <p className="message-date">
                            {moment(
                              item.messages
                                .filter(msg => msg.message_type === 'Message')
                                .slice(-1)[0]?.created_at
                            ).format('MMM DD YYYY')}
                          </p>
                        </div>
                        <Box display="flex" justifyContent="space-between">
                          <span>
                            {
                              item.messages
                                .filter(msg => msg.message_type === 'Message')
                                .slice(-1)[0]?.body
                            }
                          </span>
                          {item.unread_messages > 0 && (
                            <span
                              style={{
                                backgroundColor: 'rgb(33, 133, 208)',
                                color: 'white',
                                padding: '5px',
                                borderRadius: '20%'
                              }}
                            >
                              {item.unread_messages}
                            </span>
                          )}
                        </Box>
                      </div>
                    </div>
                  </Menu.Item>
                )
              })}
            </Menu>
          </div>
        )
      }
    ]
    const ChatSendContent = [
      {
        menuItem: 'Reply',
        render: () => (
          <>
            <div className="replyTab">
              <TextArea
                onChange={e => this.setState({ activeMsg: e.target.value })}
                value={this.state.activeMsg}
                placeholder="Joe Dude, this one is for you! A bug within the team settings."
              />
              <div className="message-save">
                <div className="message-footer-items">
                  <div className="content-icons">
                    {/* <Icon name="clipboard outline" />
                    <Icon name="file audio outline" />
                    <Icon name="tag" /> 
                    <Icon name="attach" />
                    <Icon name="smile outline" />*/}
                  </div>
                  <CommonButtons
                    onClick={this.sendMessage}
                    content="Reply"
                    background="green"
                  />
                </div>
              </div>
            </div>
          </>
        )
      },
      {
        menuItem: 'Note',
        render: () => (
          <>
            <div className="replyTab note-style">
              <TextArea
                onChange={e => this.setState({ activeNote: e.target.value })}
                value={this.state.activeNote}
              />
              <div className="message-save">
                <div className="message-footer-items">
                  <div className="content-icons">
                    {/* <Icon name="clipboard outline" />
                    <Icon name="file audio outline" /> */}
                    {/* <Icon name="tag" /> */}
                    <Icon name="attach" />
                    <Icon name="smile outline" />
                  </div>
                  <CommonButtons
                    onClick={this.addNote}
                    content="Add Note"
                    background="green"
                  />
                </div>
              </div>
            </div>
          </>
        )
      }
    ]
    const SizeForm = (
      <div className="info-details">
        <div className="info-main">
          <div className="progressLabel">
            <label>6/10 complete</label>
          </div>
          <div>
            <Progress
              percent={60}
              size="tiny"
              color="green"
              className="progressBar"
            />
          </div>
          <div className="info-item">
            <div className="checkWrap">
              <Image src={checkIcon} />
            </div>
            <p>Name</p>
            <span>James Hernandes</span>
          </div>
          <div className="info-item">
            <div className="checkWrap">
              <Image src={checkIcon} />
            </div>
            <p>Email</p>
            <span>{userDetails.email}</span>
          </div>
          <div className="info-item">
            <div className="checkWrap">
              <Image src={checkIcon} />
            </div>
            <p>Phone</p>
            <span>{userDetails.phone}</span>
          </div>
          <div className="info-item">
            <div className="checkWrap">
              <Image src={checkIcon} />
            </div>
            <p>Company Name</p>
            <span>Zencity</span>
          </div>
          <div className="info-item">
            <div className="checkWrap">
              <Image src={checkIcon} />
            </div>
            <p>Number of Employee</p>
            <span>Add</span>
          </div>
          <div className="info-item">
            <div className="checkWrap">
              <Image src={checkIcon} />
            </div>
            <p>Status</p>
            <span>Add</span>
          </div>
          <div className="info-item">
            <div className="checkWrap">
              <Image src={checkIcon} />
            </div>
            <p>Company Website</p>
            <span>WWW.Zencity.io</span>
          </div>
          <div className="info-item">
            <div className="checkWrap">
              <Image src={checkIcon} />
            </div>
            <p>Your Business Model</p>
            <span>Add</span>
          </div>
          <div className="info-item">
            <div className="checkWrap">
              <Image src={checkIcon} />
            </div>
            <p>Industry</p>
            <Input placeholder="Add industry" />
          </div>
          <div className="info-item">
            <div className="checkWrap">
              <Image src={checkIcon} />
            </div>
            <p>Company Size</p>
            <span>350</span>
          </div>
        </div>
      </div>
    )

    return (
      <div className="conversation-container">
        <Title data={title} />
        <div className="widget-banner-container">
          <div className="conversation-content-holder content-left">
            {/* <Tab
              menu={{ fluid: true, vertical: true, tabular: true }}
              panes={panes}
              className="conversation-tab"
            /> */}
            <List
              sx={{ width: '100%', maxWidth: 250, bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton
                onClick={() => {
                  this.setState(
                    {
                      filterConversation: 'inbox',
                      activeConvoId: 0,
                      activeTab: 'inbox'
                    },
                    () => {
                      this.handleFilters()
                    }
                  )
                }}
                selected={this.state.selectedIndex === 0}
              >
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box display="flex" justifyContent="space-between">
                      <Typography>Your Inbox</Typography>
                      <Typography>{this.state.countInbox}</Typography>
                    </Box>
                  }
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  this.setState(
                    {
                      filterConversation: 'mentions',
                      activeConvoId: 0,
                      activeTab: 'inbox'
                    },
                    () => {
                      this.handleFilters()
                    }
                  )
                }}
                selected={this.state.selectedIndex === 1}
              >
                <ListItemIcon>
                  <AlternateEmail />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box display="flex" justifyContent="space-between">
                      <Typography>Mentions</Typography>
                      <Typography>{this.state.Mentions}</Typography>
                    </Box>
                  }
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  this.setState(
                    {
                      filterConversation: 'all',
                      activeConvoId: 0,
                      activeTab: 'inbox'
                    },
                    () => {
                      this.handleFilters()
                    }
                  )
                }}
                selected={this.state.selectedIndex === 2}
              >
                <ListItemIcon>
                  <Group />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box display="flex" justifyContent="space-between">
                      <Typography>All</Typography>
                      <Typography>{this.state.countAll}</Typography>
                    </Box>
                  }
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  this.setState(
                    {
                      filterConversation: 'unassigned',
                      activeConvoId: 0,
                      activeTab: 'inbox'
                    },
                    () => {
                      this.handleFilters()
                    }
                  )
                }}
                selected={this.state.selectedIndex === 3}
              >
                <ListItemIcon>
                  <NoAccounts />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box display="flex" justifyContent="space-between">
                      <Typography>Unassigned</Typography>
                      <Typography>{this.state.countUnasigned}</Typography>
                    </Box>
                  }
                />
              </ListItemButton>
              <ListItemButton
                onClick={this.handleClickAssigned}
                selected={this.state.selectedIndex === 4}
              >
                <ListItemText primary="Teams" />
                {this.state.openAssigned ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse
                in={this.state.openAssigned}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {this.state.membersTeam?.map((item, i) => (
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => this.handleFilterAssign(item)}
                    >
                      <ListItemText
                        primary={
                          <Box display="flex" justifyContent="space-between">
                            <Typography>
                              {item.name?.replace('Team', '')}
                            </Typography>
                            <Typography>
                              {
                                this.state.allConversations?.filter(
                                  el =>
                                    el.owner_id === item.id &&
                                    el?.source ===
                                      this.state.selectedValueNumber
                                )?.length
                              }
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
              <ListItemButton onClick={this.handleClickChannels}>
                <ListItemText primary="Support Channels" />
                {this.state.openChanells ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse
                in={this.state.openChanells}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    selected={this.state.selectedValueNumber === 'All'}
                    onClick={e => this.handleChangeNumber(e, { value: 'All' })}
                  >
                    <ListItemText
                      primary={
                        <Box display="flex" justifyContent="space-between">
                          <Typography>All</Typography>
                          <Typography>
                            {this.props.allConversations?.all}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItemButton>
                  {this.state.numlist?.map((item, i) => (
                    <ListItemButton
                      sx={{ pl: 4 }}
                      selected={this.state.selectedValueNumber === item.value}
                      onClick={e => this.handleChangeNumber(e, item)}
                    >
                      <ListItemIcon>
                        <MailOutlineIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Box display="flex" justifyContent="space-between">
                            {item.symbole && (
                              <Typography>{item.symbole}</Typography>
                            )}
                            <Typography>{item.name}</Typography>
                          </Box>
                        }
                        secondary={
                          <Box display="flex" justifyContent="space-between">
                            <Typography>{item.value}</Typography>
                            <Typography>
                              {
                                this.state.allConversations?.filter(
                                  el =>
                                    el?.source === item.value &&
                                    item.unread_messages > 0
                                )?.length
                              }
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </List>
            <div className="conversation-content">
              <div className="conversation-content-holder ">
                <div className="content-header">
                  <div className="content-text">
                    {/* <Dropdown
                      className="icon add-filter"
                      options={this.state.numlist}
                      value={this.state.selectedValueNumber}
                      onChange={this.handleChangeNumber}
                      placeholder="(812)701-3838"
                      icon="chevron down"
                    /> */}

                    <div className="content-icons">
                      <Icon
                        name="comment alternate outline"
                        onClick={this.handleModalMessage}
                      />
                    </div>
                  </div>
                  <div className="justify-menu">
                    <Tab
                      menu={{ secondary: true, pointing: true }}
                      panes={messages}
                      onTabChange={() => {
                        this.setState({
                          activeConversation: null,
                          selectedContact: null
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Sidebar.Pushable as={Segment} className="main-segment">
              <div className="conversation-content-messages-info-holder">
                {this.state.activeConversation && (
                  <Sidebar
                    animation={animationContact}
                    direction={directionContact}
                    visible={this.state.openDetails}
                    className="sidebar-contact"
                  >
                    <div
                      className="conversation-content-info"
                      style={{
                        borderLeft: 'solid 0.5px #c0c0c0',
                        overflow: 'auto'
                      }}
                    >
                      <div className="content-user">
                        <div className="icon-holder">
                          <Typography variant="h5">Details</Typography>
                        </div>
                        <div className="icon-holder-share">
                          <div className="menu-holder">
                            <Dropdown icon={<MoreHoriz />}>
                              <Dropdown.Menu style={{ left: '-125px' }}>
                                <Dropdown.Item
                                  text="Ban User"
                                  onClick={() =>
                                    this.deleteConversation(
                                      this.state.selectedContact?.id
                                    )
                                  }
                                />
                                <Dropdown.Item
                                  text="Delete Conversation"
                                  onClick={() =>
                                    this.deleteConversation(
                                      this.state.selectedContact?.id
                                    )
                                  }
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <Divider />
                      <div className="contact-details">
                        <div className="info-details">
                          <div className="info-main">
                            <div
                              className="info-item"
                              style={{ margin: 0, fontSize: 13 }}
                            >
                              <p>Status</p>
                              {this.state.activeConversation?.state && (
                                <Chip
                                  sx={{
                                    minWidth: '100px',
                                    '.MuiChip-label': {
                                      textOverflow: 'inherit',
                                      p: 0,
                                      fontSize: 13,
                                      textAlign: 'center'
                                    },
                                    color: '#1976d2',
                                    backgroundColor: '#1976d21f'
                                  }}
                                  size="medium"
                                  label={this.state.activeConversation?.state}
                                />
                              )}
                            </div>
                            <div
                              className="info-item"
                              style={{ margin: 0, fontSize: 13 }}
                            >
                              <p>Assignee</p>
                              <Popup
                                trigger={
                                  <Dropdown
                                    className="icon add-filter"
                                    options={this.state.membersList}
                                    value={this.state.selectedValueAssign}
                                    onChange={(event, dt) => {
                                      {
                                        const index = this.state.membersList
                                          .map(el => el.value)
                                          .indexOf(dt.value)
                                        this.handleChangeAssign(index)
                                      }
                                    }}
                                    placeholder="Sales"
                                  />
                                }
                                content="Choose Assignee"
                                position="bottom center"
                              />
                            </div>
                            <div
                              className="info-item"
                              style={{ margin: 0, fontSize: 13 }}
                            >
                              <p>Team</p>
                              {this.state.activeConversation?.owner_type ===
                              'App\\Team'
                                ? this.state.activeConversation?.owner_name
                                : ''}
                            </div>
                            <div className="info-item">
                              <p>Priority</p>
                              <Popup
                                trigger={
                                  <Dropdown
                                    className="icon"
                                    options={priority}
                                    value={this.state.selectedValuePriority}
                                    onChange={this.handleChangePriority}
                                    placeholder="Closed"
                                  />
                                }
                                content="Change Priority"
                                position="bottom center"
                              />
                            </div>
                            <div className="info-item">
                              <p>Source</p>
                              {this.state.activeConversation?.participant
                                ? this.state?.activeConversation?.source
                                : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                      <Divider />
                      <List
                        sx={{ width: '100%', py: 0 }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                      >
                        <ListItemButton
                          onClick={() => this.handleClickContacts('contact')}
                        >
                          <ListItemText primary="Contact Details" />
                          {this.state.openContact ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                        <Collapse
                          in={this.state.openContact}
                          timeout="auto"
                          unmountOnExit
                        >
                          <div className="contact-details">
                            <div className="info-details">
                              <div className="info-main">
                                <div className="info-item">
                                  <p>
                                    <PeopleIcon /> Name
                                  </p>
                                  {this.state.selectedContact?.name ? (
                                    <ClickAwayListener
                                      onClickAway={() =>
                                        this.handleUpdateContact('name')
                                      }
                                    >
                                      <TextField
                                        multiline
                                        onChange={e => {
                                          this.setState({
                                            selectedContact: {
                                              ...this.state.selectedContact,
                                              name: e.target.value
                                            }
                                          })
                                        }}
                                        variant="standard"
                                        value={this.state.selectedContact?.name}
                                      />
                                    </ClickAwayListener>
                                  ) : (
                                    <ClickAwayListener
                                      onClickAway={() =>
                                        this.handleUpdateContact('new')
                                      }
                                    >
                                      <TextField
                                        multiline
                                        onChange={e => {
                                          this.setState({
                                            newName: e.target.value
                                          })
                                        }}
                                        variant="standard"
                                        value={this.state.newName}
                                      />
                                    </ClickAwayListener>
                                  )}
                                </div>
                                <div className="info-item">
                                  <p>
                                    <PhoneIcon /> Phone
                                  </p>
                                  {this.state.selectedContact?.phone_number ? (
                                    <ClickAwayListener
                                      onClickAway={() =>
                                        this.handleUpdateContact('phone')
                                      }
                                    >
                                      <TextField
                                        multiline
                                        onChange={e => {
                                          this.setState({
                                            selectedContact: {
                                              ...this.state.selectedContact,
                                              phone_number: e.target.value
                                            }
                                          })
                                        }}
                                        variant="standard"
                                        value={
                                          this.state.selectedContact
                                            ?.phone_number
                                        }
                                      />
                                    </ClickAwayListener>
                                  ) : (
                                    this.state.activeConversation.friendly_name?.replace(
                                      'customer - ',
                                      ''
                                    )
                                  )}
                                </div>
                                <div className="info-item">
                                  <p>
                                    <AlternateEmailIcon /> Email
                                  </p>
                                  {this.state.selectedContact?.email && (
                                    <ClickAwayListener
                                      onClickAway={() =>
                                        this.handleUpdateContact('email')
                                      }
                                    >
                                      <TextField
                                        multiline
                                        onChange={e => {
                                          this.setState({
                                            selectedContact: {
                                              ...this.state.selectedContact,
                                              email: e.target.value
                                            }
                                          })
                                        }}
                                        variant="standard"
                                        value={
                                          this.state.selectedContact?.email
                                        }
                                      />
                                    </ClickAwayListener>
                                  )}
                                </div>
                                <div className="info-item">
                                  <p>
                                    <LocationOnIcon /> Location
                                  </p>
                                  {this.state.selectedContact?.city && (
                                    <p>
                                      {this.state.selectedContact?.city +
                                        ' ' +
                                        this.state.selectedContact?.country}
                                    </p>
                                  )}
                                </div>
                                <div className="info-item">
                                  <p>
                                    <BusinessIcon /> Company
                                  </p>
                                  {this.state.selectedContact && (
                                    <ClickAwayListener
                                      onClickAway={() =>
                                        this.handleUpdateContact('company')
                                      }
                                    >
                                      <TextField
                                        multiline
                                        onChange={e => {
                                          this.setState({
                                            selectedContact: {
                                              ...this.state.selectedContact,
                                              organization_name: e.target.value
                                            }
                                          })
                                        }}
                                        variant="standard"
                                        value={
                                          this.state.selectedContact
                                            ?.organization_name
                                        }
                                      />
                                    </ClickAwayListener>
                                  )}
                                </div>
                                {/* <div className="info-item">
                                <p>Address</p>
                                <span>
                                  {this.state.selectedContact?.address}
                                </span>
                              </div> */}
                              </div>
                            </div>
                          </div>
                        </Collapse>
                        <Divider />
                        <ListItemButton
                          onClick={() => this.handleClickContacts('attribute')}
                        >
                          <ListItemText primary="Conversation Info" />
                          {this.state.openAttribute ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                        <Collapse
                          in={this.state.openAttribute}
                          timeout="auto"
                          unmountOnExit
                        >
                          <div className="contact-details">
                            <div className="info-details">
                              <div className="info-main">
                                <div className="info-item">
                                  <p>First seen</p>
                                  <span>
                                    {this.state.selectedContact?.first_seen}
                                  </span>
                                </div>
                                <div className="info-item">
                                  <p>Last seen</p>
                                  <span>
                                    {this.state.selectedContact?.last_seen}
                                  </span>
                                </div>
                                <div className="info-item">
                                  <p>Created</p>
                                  <span>
                                    {this.state.selectedContact?.created_at}
                                  </span>
                                </div>
                                <div className="info-item">
                                  <p>Local Time</p>
                                  {this.state.selectedContact?.local_time && (
                                    <p>
                                      {this.state.selectedContact?.local_time} -
                                      Local Time
                                    </p>
                                  )}
                                </div>
                                <div className="info-item">
                                  <p>Calls</p>
                                  {this.state.selectedContact?.leads?.length}
                                </div>
                                <div className="info-item">
                                  <p>User Id</p>
                                  {this.state.selectedContact?.id}
                                </div>
                                <div className="info-item">
                                  <p>Type</p>
                                  {this.state.selectedContact?.type}
                                </div>
                                <div className="info-item">
                                  <p>Conversation Id</p>
                                  {this.state.activeConversation?.id}
                                </div>
                                <div className="info-item">
                                  <p>Channel</p>
                                </div>
                                <div className="info-item">
                                  <p>Visits</p>
                                </div>
                                <div className="info-item">
                                  <p>Page impressions</p>
                                </div>
                              </div>
                            </div>
                            {/* <div className="qualification">
                        <Accordion
                          as={Menu}
                          vertical
                          className="qualificationWrap"
                        >
                          <Menu.Item>
                            <Accordion.Title
                              active={this.state.activeQualification === 0}
                              content="Qualification"
                              index={0}
                              onClick={this.handleQualification}
                            />
                            <Accordion.Content
                              active={this.state.activeQualification === 0}
                              content={SizeForm}
                            />
                          </Menu.Item>
                        </Accordion>
                      </div> */}

                            {/*<div className="info-note">
                            {this.state.addNote.map((data, index) => {
                              return (
                                <div className="info-note-item">
                                  <span key={index}>{this.state.note}</span>
                                  {index !== 0 ? (
                                    <CommonButtons
                                      onClick={this.onClickRemoveNote}
                                      disabled={index === 0 ? 'disabled' : ''}
                                      btnClass="btn-delete"
                                      image={deleteIcon}
                                    />
                                  ) : null}
                                </div>
                              )
                            })}
                             <div className="add-contact-new info-note-add">
                          <CommonInput
                            name="companyName"
                            type="text"
                            inputStyle="input-company"
                            placeholder="Write a note..."
                            value={this.state.note}
                            onChange={this.handleNoteChange}
                          />
                          <CommonButtons
                            onClick={this.onClickAddNote}
                            btnClass="btn-hours"
                            image={circlePlus}
                          />
                            </div> 
                          </div>*/}
                          </div>
                        </Collapse>
                        <ListItemButton
                          onClick={() => this.handleClickContacts('feedback')}
                        >
                          <ListItemText primary="Feedback" />
                          {this.state.openFeedback ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                        <Collapse
                          in={this.state.openFeedback}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box
                            sx={{
                              '& > legend': { mt: 2 },
                              px: 2
                            }}
                          >
                            <Typography component="legend">
                              Really Awesome Product!
                            </Typography>
                            <Rating name="read-only" value={5} readOnly />
                          </Box>
                        </Collapse>
                        <ListItemButton
                          onClick={() => this.handleClickContacts('prevConv')}
                        >
                          <ListItemText primary="Previous Conversations" />
                          {this.state.openPrevConv ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                        <Collapse
                          in={this.state.openPrevConv}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItem>
                            <ListItemText
                              primary="Sample Conversation"
                              secondary="Jan 7, 2022"
                            />
                          </ListItem>
                        </Collapse>
                      </List>
                      {/* <div className="contact-details">
                      <div className="info-details">
                        <div className="info-head">
                          <p>
                            Attribute Details <Icon name="info circle" />
                          </p>
                        </div>
                        <div className="info-main">
                          <div className="info-item">
                            <p>First seen</p>
                            <span>
                              {this.state.selectedContact?.first_seen}
                            </span>
                          </div>
                          <div className="info-item">
                            <p>Last seen</p>
                            <span>{this.state.selectedContact?.last_seen}</span>
                          </div>
                          <div className="info-item">
                            <p>Created</p>
                            <span>
                              {this.state.selectedContact?.created_at}
                            </span>
                          </div>
                          <div className="info-item">
                            <p>Local Time</p>
                            <span>
                              {this.state.selectedContact?.local_time && (
                                <p>
                                  {this.state.selectedContact?.local_time} -
                                  Local Time
                                </p>
                              )}
                            </span>
                          </div>
                          <div className="info-item">
                            <p>Leads</p>
                            <span>
                              {this.state.selectedContact?.leads?.length}
                            </span>
                          </div>
                          <div className="info-item">
                            <p>User Id</p>
                            <span>{this.state.selectedContact?.id}</span>
                          </div>
                          <div className="info-item">
                            <p>Type</p>
                            <span>{this.state.selectedContact?.type}</span>
                          </div>
                          <div className="info-item">
                            <p>Conversation Id</p>
                            <span>{this.state.activeConversation?.id}</span>
                          </div>
                        </div>
                      </div>
                      <div className="qualification">
                        <Accordion
                          as={Menu}
                          vertical
                          className="qualificationWrap"
                        >
                          <Menu.Item>
                            <Accordion.Title
                              active={this.state.activeQualification === 0}
                              content="Qualification"
                              index={0}
                              onClick={this.handleQualification}
                            />
                            <Accordion.Content
                              active={this.state.activeQualification === 0}
                              content={SizeForm}
                            />
                          </Menu.Item>
                        </Accordion>
                      </div>

                      <div className="info-note">
                        {this.state.addNote.map((data, index) => {
                          return (
                            <div className="info-note-item">
                              <span key={index}>{this.state.note}</span>
                              {index !== 0 ? (
                                <CommonButtons
                                  onClick={this.onClickRemoveNote}
                                  disabled={index === 0 ? 'disabled' : ''}
                                  btnClass="btn-delete"
                                  image={deleteIcon}
                                />
                              ) : null}
                            </div>
                          )
                        })}
                        <div className="add-contact-new info-note-add">
                          <CommonInput
                            name="companyName"
                            type="text"
                            inputStyle="input-company"
                            placeholder="Write a note..."
                            value={this.state.note}
                            onChange={this.handleNoteChange}
                          />
                          <CommonButtons
                            onClick={this.onClickAddNote}
                            btnClass="btn-hours"
                            image={circlePlus}
                          />
                        </div>
                      </div>
                    </div> */}
                      <Divider />
                      <ListItemButton
                        onClick={() => this.handleClickContacts('lead')}
                      >
                        <ListItemText primary="Notes" />
                        {this.state.openContact ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={this.state.openLead}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List>
                          {this.state.leadNotes?.map((data, index) => {
                            return (
                              <ListItem
                                sx={{
                                  backgroundColor: '#F3E779',
                                  borderTop: '1px solid #00000075'
                                }}
                                secondaryAction={
                                  <IconButton
                                    onClick={() =>
                                      this.onClickRemoveNote(index)
                                    }
                                    edge="end"
                                    aria-label="delete"
                                  >
                                    <Delete />
                                  </IconButton>
                                }
                              >
                                <ListItemText
                                  primary={data.note}
                                  secondary={
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                    >
                                      <span>
                                        {moment(data.date).format('LLL')}
                                      </span>
                                      <span>{data.userName}</span>
                                    </Box>
                                  }
                                />
                              </ListItem>
                            )
                          })}
                        </List>
                        <div className="add-contact-new info-note-add">
                          <TextField
                            id="outlined-basic"
                            label="Write A note"
                            variant="outlined"
                            value={this.state.note}
                            onChange={this.handleNoteChange}
                            onKeyPress={e => {
                              if (e.key === 'Enter') {
                                this.onClickAddNote()
                              }
                            }}
                            sx={{
                              width: '100%'
                            }}
                          />
                        </div>
                      </Collapse>
                    </div>
                  </Sidebar>
                )}
                {/* <Sidebar
                  animation={animation}
                  direction={direction}
                  visible={visible}
                >
                  <div className="add-contact">
                    <h2>Add Contact</h2>
                    {this.state.contacts.map((data, index) => {
                      return (
                        <div className="add-contact-inputs">
                          <div className="add-contact-holder">
                            <div className="add-contact-item">
                              <p className="title">First name</p>
                              <CommonInput
                                name="companyName"
                                type="text"
                                inputStyle="input-company"
                                onChange={e =>
                                  this.changeContact(
                                    e.target.value,
                                    index,
                                    'first_name'
                                  )
                                }
                              />
                            </div>

                            <div className="add-contact-item">
                              <p className="title">Last name</p>
                              <CommonInput
                                name="companyName"
                                type="text"
                                inputStyle="input-company"
                                onChange={e =>
                                  this.changeContact(
                                    e.target.value,
                                    index,
                                    'last_name'
                                  )
                                }
                              />
                            </div>

                            <div className="add-contact-item">
                              <p className="title">Phone Number</p>
                              <CommonInput
                                name="companyName"
                                type="text"
                                inputStyle="input-company"
                                onChange={e =>
                                  this.changeContact(
                                    e.target.value,
                                    index,
                                    'phone_number'
                                  )
                                }
                              />
                            </div>

                            <div className="add-contact-item">
                              <p className="title">Email</p>
                              <CommonInput
                                name="companyName"
                                type="text"
                                inputStyle="input-company"
                                onChange={e =>
                                  this.changeContact(
                                    e.target.value,
                                    index,
                                    'email'
                                  )
                                }
                              />
                            </div>

                            <div className="add-contact-item">
                              <p className="title">Type</p>
                              <CommonSelect
                                name="link"
                                className="set-hours-select"
                                onChange={(e, result) =>
                                  this.changeContact(
                                    result.value,
                                    index,
                                    'type'
                                  )
                                }
                                options={[' ', 'individual', 'organization']}
                              />
                            </div>

                            <div className="add-contact-item">
                              <p className="title">Company</p>
                              <CommonInput
                                name="companyName"
                                type="text"
                                inputStyle="input-company"
                                onChange={e =>
                                  this.changeContact(
                                    e.target.value,
                                    index,
                                    'company'
                                  )
                                }
                              />
                            </div>

                            <div className="add-contact-item">
                              <p className="title">Country</p>
                              <CommonSelect
                                name="link"
                                onChange={(e, result) =>
                                  this.changeContact(
                                    result.value,
                                    index,
                                    'country'
                                  )
                                }
                                className="set-hours-select"
                                options={[' ', 'Egypt', 'India', 'USA']}
                              />
                            </div>
                            <div className="add-contact-item">
                              <p className="title">City</p>
                              <CommonInput
                                name="companyName"
                                onChange={e =>
                                  this.changeContact(
                                    e.target.value,
                                    index,
                                    'city'
                                  )
                                }
                                type="text"
                                inputStyle="input-company"
                              />
                            </div>
                          </div>
                          <div className="add-contact-user">
                            <Icon name="user"></Icon>
                          </div>
                          {index !== 0 ? (
                            <CommonButtons
                              onClick={this.onClickRemoveOfficeHours2}
                              disabled={index === 0 ? 'disabled' : ''}
                              btnClass="btn-delete"
                              image={deleteIcon}
                            />
                          ) : null}
                        </div>
                      )
                    })}
                    <div className="add-contact-new">
                      <CommonButtons
                        onClick={this.onClickContacts}
                        btnClass="btn-hours"
                        image={circlePlus}
                      />{' '}
                      Add a new item
                    </div>
                    <div className="add-contact-button">
                      <CommonButtons
                        btnClass="btn-start sky-blue"
                        content="Save"
                        onClick={this.addContacts}
                      />
                      <CommonButtons
                        btnClass="btn-start light"
                        content="Cancel"
                      />
                    </div>
                  </div>
                </Sidebar>
                <Sidebar
                  animation={animationSearch}
                  direction={directionSearch}
                  visible={visibleSearch}
                >
                  <div className="add-contact add-contact-search">
                    <CommonInput
                      placeholder="Search by name or number"
                      name="companyName"
                      type="text"
                      inputStyle="input-company"
                      onChange={e => this.fetchContacts(e.target.value)}
                    />
                    <div className="contact-items">
                      {this.state.searchDetails.map((item, i) => {
                        return (
                          <p>
                            <label className="container">
                              {item.name}
                              <input
                                onClick={() =>
                                  this.setupConversation(
                                    item,
                                    this.state.commonContacts.includes(item.id)
                                      ? true
                                      : false
                                  )
                                }
                                type="checkbox"
                                checked={
                                  this.state.commonContacts.includes(item.id)
                                    ? true
                                    : false
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </p>
                        )
                      })}
                    </div>
                  </div>
                </Sidebar> */}
                <Sidebar.Pusher>
                {this.state.activeConversation && this.state.activeConversation?.messages   ?  (
                    <Segment basic className="message-segment">
                      {visibleContact ? (
                        <div className="conversation-content-messages auto-width">
                          <div className="conversation-content-messages-holder">
                            <div
                              className="content-text"
                              style={{ marginBottom: '17px' }}
                            >
                              <Typography variant="h6">
                                {this.state.activeConversation?.friendly_name?.replace(
                                  'customer - ',
                                  ''
                                )}
                              </Typography>
                              <div className="content-icons">
                                {this.state.activeTab === 'inbox' && (
                                  <>
                                    <Popup
                                      trigger={
                                        <IconButton
                                          sx={{ marginLeft: 1 }}
                                          onClick={() => this.updateStatus(2)}
                                          color="primary"
                                          aria-label="upload picture"
                                          component="span"
                                        >
                                          <NotificationsPaused />
                                        </IconButton>
                                      }
                                      content="Snooze the Message"
                                      position="bottom center"
                                    />

                                    {/* <Popup
                                      trigger={
                                        <IconButton
                                          sx={{ marginLeft: 1 }}
                                          onClick={() => this.updateStatus(3)}
                                          color="primary"
                                          aria-label="upload picture"
                                          component="span"
                                        >
                                          <DoneIcon />
                                        </IconButton>
                                      }
                                      content="Mark as done"
                                      position="bottom center"
                                    /> */}
                                    <div>
                                      <Popup
                                        trigger={
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.updateStatus(3)}
                                            sx={{
                                              marginLeft: 1,
                                              marginRight: 1,
                                              fontSize: '14px !important'
                                            }}
                                          >
                                            Close
                                          </Button>
                                        }
                                        content="Close the Message"
                                        position="bottom center"
                                      />
                                    </div>
                                  </>
                                )}

                                {this.state.activeTab === 'snooze' && (
                                  <>
                                    <Popup
                                      trigger={
                                        <IconButton
                                          sx={{ marginLeft: 1 }}
                                          onClick={() => this.updateStatus(1)}
                                          color="primary"
                                          aria-label="upload picture"
                                          component="span"
                                        >
                                          <MarkChatRead />
                                        </IconButton>
                                      }
                                      content="Activate Conversation"
                                      position="bottom center"
                                    />

                                    {/* <Popup
                                      trigger={
                                        <IconButton
                                          sx={{ marginLeft: 1 }}
                                          onClick={() => this.updateStatus(3)}
                                          color="primary"
                                          aria-label="upload picture"
                                          component="span"
                                        >
                                          <DoneIcon />
                                        </IconButton>
                                      }
                                      content="Mark as done"
                                      position="bottom center"
                                    /> */}
                                    <div>
                                      <Popup
                                        trigger={
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.updateStatus(3)}
                                            sx={{
                                              marginLeft: 1,
                                              marginRight: 1,
                                              fontSize: '14px !important'
                                            }}
                                          >
                                            Close
                                          </Button>
                                        }
                                        content="Close the Message"
                                        position="bottom center"
                                      />
                                    </div>
                                  </>
                                )}

                                {this.state.activeTab === 'closed' && (
                                  <>
                                    <Popup
                                      trigger={
                                        <IconButton
                                          sx={{ marginLeft: 1 }}
                                          onClick={() => this.updateStatus(1)}
                                          color="primary"
                                          aria-label="upload picture"
                                          component="span"
                                        >
                                          <MarkChatRead />
                                        </IconButton>
                                      }
                                      content="Activate Conversation"
                                      position="bottom center"
                                    />

                                    <Popup
                                      trigger={
                                        <IconButton
                                          sx={{ marginLeft: 1 }}
                                          onClick={() => this.updateStatus(2)}
                                          color="primary"
                                          aria-label="upload picture"
                                          component="span"
                                        >
                                          <NotificationsPaused />
                                        </IconButton>
                                      }
                                      content="Snooze the Message"
                                      position="bottom center"
                                    />
                                  </>
                                )}
                                {/* <BasicMenu
                                  options={this.state.membersList}
                                  onChange={dt => this.handleChangeAssign(dt)}
                                  selected={
                                    this.state.activeConversation?.owner_id
                                  }
                                  title="Assign"
                                /> */}
                              </div>
                            </div>
                            <Divider />
                            <div className="content-message-body">
                              <div className="message-content height-message-content">
                                <Popover
                                  id="mouse-over-popover"
                                  sx={{
                                    pointerEvents: 'none'
                                  }}
                                  open={open}
                                  anchorEl={anchorEl}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                  }}
                                  onClose={this.handlePopoverClose}
                                  disableRestoreFocus
                                >
                                  <Typography sx={{ p: 1 }}>
                                    Source :{' '}
                                    {this.state.activeConversation?.participant
                                      ? this.state?.activeConversation?.source
                                      : ''}
                                  </Typography>
                                </Popover>
                                {this.state.activeConversation?.messages?.map(
                                  (data, x) => {
                                    return (
                                      <div key={x}>
                                        {data.direction === 'outgoing' ? (
                                          <div className="message-text message-send">
                                            <div className="chatMessages">
                                              <div className="chatTime">
                                                <div
                                                  className="message-text-holder"
                                                  // style={{
                                                  //   backgroundColor: '#dce1f9'
                                                  // }}
                                                >
                                                  <p key={x}>{data.body}</p>
                                                  <label>
                                                    {this.getMessageStatus(
                                                      data.status
                                                    )}{' '}
                                                    <Popup
                                                      trigger={
                                                        <span>
                                                          {moment(
                                                            data.created_at +
                                                              'Z'
                                                          ).fromNow(true)}
                                                        </span>
                                                      }
                                                      content={moment(
                                                        data.created_at
                                                      ).format('LLL')}
                                                      position="bottom center"
                                                    />
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="messageProfile">
                                                <label>A</label>
                                              </div>
                                            </div>
                                          </div>
                                        ) : data.direction === 'incoming' ? (
                                          <div className="message-text message-receive">
                                            <div className="chatMessages">
                                              <div
                                                className={this.getMessageClass(
                                                  this.state.activeConversation
                                                    .participant
                                                )}
                                              >
                                                {this.getMessageSymbole(
                                                  this.state.activeConversation
                                                    .participant
                                                )}
                                              </div>
                                              <div className="chatTime">
                                                <div
                                                  className="message-text-holder"
                                                  // style={{
                                                  //   backgroundColor: '#f1f1f1'
                                                  // }}
                                                >
                                                  <p>{data.body}</p>
                                                  <label
                                                    style={{ width: '100%' }}
                                                  >
                                                    <ChatIcon
                                                      sx={{ float: 'left' }}
                                                      onMouseEnter={
                                                        this.handlePopoverOpen
                                                      }
                                                      onMouseLeave={
                                                        this.handlePopoverClose
                                                      }
                                                    />
                                                    <Popup
                                                      trigger={
                                                        <span
                                                          style={{
                                                            float: 'right'
                                                          }}
                                                        >
                                                          {moment(
                                                            data.created_at +
                                                              'Z'
                                                          ).fromNow(true)}
                                                        </span>
                                                      }
                                                      content={moment(
                                                        data.created_at
                                                      ).format('LLL')}
                                                      position="bottom center"
                                                    />
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="message-text message-send">
                                            <div className="chatMessages">
                                              <div className="chatTime">
                                                <div
                                                  className="message-text-holder"
                                                  // style={{
                                                  //   backgroundColor: '#F3E779'
                                                  // }}
                                                >
                                                  <p>{data.note}</p>
                                                </div>
                                              </div>
                                              <div className="messageProfile">
                                                <label>N</label>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        {this.state.activeConversation?.messages
                                          ?.filter(
                                            el => el.message_type === 'Log'
                                          )
                                          .slice(-3)
                                          .includes(data) && (
                                          <Typography m={2}>
                                            {' - ' +
                                              moment(
                                                data.log.time + 'Z'
                                              ).fromNow() +
                                              ' ' +
                                              data.log.event_name}
                                          </Typography>
                                        )}
                                      </div>
                                    )
                                  }
                                )}
                              </div>
                              <div className="message-footer">
                                <Tab
                                  menu={{ secondary: true, pointing: true }}
                                  panes={ChatSendContent}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="conversation-content-messages min-width">
                          <div className="conversation-content-messages-holder">
                            <div className="content-text">
                              <div className="content-assign">
                                <span>Assign to:</span>
                                <Dropdown
                                  className="icon add-filter"
                                  options={this.state.membersList}
                                  value={this.state.selectedValueAssign}
                                  onChange={this.handleChangeAssign}
                                  placeholder="Sales"
                                />
                              </div>
                              <div className="content-assign">
                                <span>Priority:</span>
                                <Dropdown
                                  className="icon add-filter"
                                  options={priority}
                                  value={this.state.selectedValuePriority}
                                  onChange={this.handleChangePriority}
                                  placeholder="Closed"
                                />
                              </div>
                            </div>
                            <div>
                              <div className="content-icons">
                                <Icon name="ban" className="icon-message" />

                                {/*  <Popup
                                  trigger={
                                    <Icon
                                      name="check"
                                      className="icon-message"
                                    />
                                  }
                                  content="Mark as done"
                                  position="bottom center"
                                /> */}
                                <div>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.updateStatus(3)}
                                    sx={{
                                      marginLeft: 1,
                                      marginRight: 1,
                                      fontSize: 14
                                    }}
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>
                            </div>

                            <div className="content-message-body">
                              <div className="message-content">
                                {dataMessage[
                                  activeItemMessage
                                ].dataMessageContent.map((data, x) => {
                                  return (
                                    <div key={x}>
                                      <div className="message-text message-send">
                                        <div className="message-text-holder">
                                          {data.sender.map((finData, y) => {
                                            return (
                                              <>
                                                <p key={y}>
                                                  {finData.message1}
                                                </p>
                                              </>
                                            )
                                          })}
                                        </div>
                                      </div>
                                      <div className="message-text message-receive">
                                        <div className="message-text-holder">
                                          {data.receiver.map((finData, y) => {
                                            return <p>{finData.message1}</p>
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                              <div className="message-footer">
                                <TextArea placeholder="Shift + enter to add new line" />
                                <div className="message-save">
                                  <div className="message-footer-items">
                                    <div className="content-icons">
                                      {/* <Icon name="clipboard outline" />
                                    <Icon name="file audio outline" />
                                    <Icon name="tag" /> */}
                                      <Icon name="attach" />
                                      <Icon name="smile outline" />
                                    </div>
                                    <CommonButtons
                                      content="Send"
                                      background="green"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Segment>
                  ) : (
                    <Segment>
                    <div
                
                      className='no-conversation'  
                    >
                      {' '}
                      <div>

                          <img src={relax} alt=''/>
                          <Typography variant='h6' component='h6'> No open conversation here </Typography>
                      <Typography variant='body1' component='p'> You're all caught up </Typography>
                        </div>
                    </div>
                  </Segment>
                  )}
                </Sidebar.Pusher>
              </div>
            </Sidebar.Pushable>
            {/* <div className="conversation-content-tab">
               <div className="content-icons">
                <Icon
                  name="user outline"
                  disabled={vertical}
                  onClick={this.handleAnimationChangeContact('uncover')}
                />

                <Icon
                  name="add user"
                  disabled={vertical}
                  onClick={this.handleAnimationChange()}
                />
                 <Icon
                  name="search"
                  disabled={vertical}
                  onClick={this.handleAnimationChangeSearch()}
                /> 
              </div> 
            </div> */}
            <Modal
              className="conversation-content-modal"
              open={isModalOpen}
              onClose={this.handleCloseModal}
            >
              <Modal.Header>
                <h2>Make a Call</h2>
                <i
                  onClick={this.handleModal}
                  className="fa fa-times"
                  aria-hidden="true"
                ></i>
              </Modal.Header>
              <Modal.Content scrolling>
                <p>Find a contact or enter a new phone number </p>
                <Input placeholder="Enter a number or phone number" />
                <div className="modal-enter">
                  <p>
                    Press <span>enter</span> to call
                  </p>
                  <CommonButtons btnClass="btn-start sky-blue" content="Call" />
                </div>
              </Modal.Content>
            </Modal>
            <Modal
              className="conversation-content-modal"
              open={isModalMessageOpen}
              onClose={this.handleCloseModalMessage}
            >
              <Modal.Header>
                <h2>New Message</h2>
                <i
                  onClick={this.handleModalMessage}
                  className="fa fa-times"
                  aria-hidden="true"
                ></i>
              </Modal.Header>
              <Modal.Content scrolling>
                <p>Find a contact or enter a new phone number </p>
                <Input
                  onChange={e =>
                    this.setState({
                      newNumber: e.target.value
                    })
                  }
                  placeholder="Enter a number or phone number"
                />
                <div className="modal-enter">
                  <p>
                    Press <span>enter</span> to call
                  </p>
                  <CommonButtons
                    onClick={this.setupConversation}
                    btnClass="btn-start sky-blue"
                    content="Message"
                  />
                </div>
              </Modal.Content>
            </Modal>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.settings.profile,
  allConversations: state.conversation.allConversations
})

export default connect(mapStateToProps, { getProfile, getConversations })(
  ConversationContent
)
