import React from 'react'
import PersonAddAltSharpIcon from '@mui/icons-material/PersonAddAltSharp'
import { IconButton } from '@mui/material'
import compliance1 from '../../assets/images/compliance1.png'
import compliance2 from '../../assets/images/compliance2.png'
import compliance3 from '../../assets/images/compliance3.png'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const Compliance = () => {
  return (
    <div className="compliance-container">
      <div className="container">
        <h2 className="mt-3 mb-2">Compliance</h2>
        <div className="compliance-card">
          <div className="left-part">
            <div className="warpper">
              <IconButton>
                <PersonAddAltSharpIcon />
              </IconButton>
            </div>
            <div className="text-center mt-3">
              <h6 className="md-head mb-2">Suspended</h6>
              <p className="mb-0 desc">
                We've suspended your account because you did not respect our
                Terms of use
              </p>
            </div>
          </div>
          <div className="right-part pl-4">
            <h6 className="md-head mb-2">
              Your account requires additional verification
            </h6>
            <p className="mb-0 desc">
              We ask you to take a minute to review our best practices below. If
              you meet our requirements, please contact our Customer Experience
              team through your Support page for us to review your account.
              Learning best practices
            </p>
          </div>
        </div>
        <h3 className="my-3">Learning best practices</h3>
        <div className="content-card compliance-card mb-3">
          <div className="img-wrapper">
            <img src={compliance1} alt="sss" />
          </div>
          <div className="right-part pl-4">
            <h5>Opt-in is Essential</h5>

            <ul>
              <li>
                <span style={{ fontWeight: 600 }}> Explicit Consent:</span> Just
                like email, ensure every recipient has explicitly opted in to
                receive your SMS messages. Avoid buying or scraping lists.
              </li>
              <li>
                <span style={{ fontWeight: 600 }}> Double Opt-in:</span>{' '}
                Consider a double opt-in system where users confirm their
                subscription via a reply text or link.
              </li>

              <li>
                <span style={{ fontWeight: 600 }}>Opt-out:</span> Clear Always
                provide an easy way for users to unsubscribe, such as replying
                with "STOP" or a clear link in the message.
              </li>
            </ul>
            <h5>Relevance and Value</h5>

            <ul>
              <li>
                <span style={{ fontWeight: 600 }}> Targeted Content:</span> Just
                Segment your audience and tailor messages to their interests and
                behavior, just like email segmentation.
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>Concise and Actionable:</span>{' '}
                SMS has a character limit, so keep messages short, to the point,
                and include a clear call to action (CTA).
              </li>
            </ul>
          </div>
        </div>
        <div className="content-card compliance-card mb-3">
          <div className="img-wrapper">
            <img src={compliance2} alt="sss" />
          </div>
          <div className="right-part pl-4">
            <h5>Timing and Frequency</h5>

            <ul>
              <li>
                <span style={{ fontWeight: 600 }}> Respectful Timing:</span>{' '}
                Just Avoid sending messages too early or late in the day.
                Consider time zones and local customs.
              </li>
              <li>
                <span style={{ fontWeight: 600 }}> Frequency:</span> Don't
                overwhelm your subscribers. Find the right balance based on your
                content and audience.
              </li>
            </ul>
            <h5>Sender Identity</h5>
            <ul>
              <li>
                <span style={{ fontWeight: 600 }}> Brand Recognition:</span>{' '}
                Just Clearly identify your brand or company at the start of the
                message.
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>
                  Short Codes or Keywords:
                </span>{' '}
                Use dedicated short codes or keywords to make it easier for
                users to recognize and interact with your brand.
              </li>
            </ul>
          </div>
        </div>
        <div className="content-card compliance-card">
          <div className="img-wrapper">
            <img src={compliance3} alt="sss" />
          </div>
          <div className="right-part pl-4">
            <h5>Compliance</h5>
            <ul>
              <li>
                <span style={{ fontWeight: 600 }}> Regulations:</span> Just Be
                aware of and follow all relevant SMS marketing regulations in
                your region.
              </li>
              <li>
                <span style={{ fontWeight: 600 }}> Privacy:</span> Data Handle
                user data responsibly and securely.
              </li>
            </ul>
            <h5>Additional Tips</h5>
            <ul>
              <li>
                <span style={{ fontWeight: 600 }}> Personalization:</span> Just
                When possible, use personalization tokens to address recipients
                by name or reference their interests.
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>
                  Testing and Optimization:
                </span>
                A/B test different message variations, CTAs, and send times to
                improve results.
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>Link Shorteners:</span>
                Use link shorteners to save space and track click-through rates
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Compliance