import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import { getSubscription } from '../config/subscription'
import CommonButton from '../common/CommonButtons'
import CommonInput from '../common/CommonInput'
import { CommonNotify } from '../common/CommonNotify'
import Heading from '../components/loginregister/Heading'
import GoogleButton from '../components/loginregister/GoogleButton'
import LoginFooter from '../components/loginregister/LoginFooter'
import { Dimmer, Loader } from 'semantic-ui-react'
import { setLoginLocalStorage } from '../helpers/common'
import { deviceDetect, isMobile } from 'react-device-detect'

const existingTokens = localStorage.getItem('access_token')
const mobile_verification_status = localStorage.getItem(
  'mobile_verification_status'
)
const quick_setup = localStorage.getItem('quick_setup')
const role_name = localStorage.getItem('role_name')

const Login = props => {
  const history = useHistory()

  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  })
  const [isDevice, setIsDevice] = useState()

  useEffect(() => {
    setIsDevice(deviceDetect()?.isBrowser)
    document.title = 'Sign In'
    if (window.$isloogedOut !== true) {
      if (existingTokens != null) {
        if (role_name === 'Admin') {
          if (
            parseInt(quick_setup) >= 5 ||
            parseInt(mobile_verification_status) === 1
          ) {
            localStorage.setItem('quick_setup', 5)
            redirectPage()
          } else {
            localStorage.setItem('quick_setup', 1)
            redirectPage('/QuickSetup')
          }
        } else {
          redirectPage()
        }
      }
    }
  }, [])

  const [error, setError] = useState({})
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [validate, setValidate] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const isDailer = sessionStorage?.getItem('isDialer')

  const handleInput = e => {
    const { name, value } = e.target
    if (name === 'email' && value === '') {
      setError({ ...error, [name]: 'Please Enter Valid Email' })
    } else if (name === 'password' && value === '') {
      setError({ ...error, [name]: 'Please Enter Valid Password' })
    } else {
      setError({
        email: '',
        password: ''
      })
    }
    setCredentials({
      ...credentials,
      [name]: value
    })
  }

  const redirectPage = value => {
    if (isMobile) {
      window.location.href = 'https://dialer.limephone.io'
      history.go(1)
    } else if (+isDailer === 1) {
      window.location.href = '/dialer'
    } else {
      window.location.href = value ? value : '/'
    }
  }

  const handleLogin = async event => {
    event.preventDefault()

    // check if input fields are filled or not
    if (!credentials.email) {
      setError({ email: 'Please Enter Valid Email' })
      return false
    } else if (!credentials.password) {
      setError({ password: 'Please Enter Valid Password' })
      return false
    }
    setError({ email: '', password: '' })

    setLoading(true)

    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/oauth/token`

    const form = new FormData()
    form.append('client_id', `${process.env.REACT_APP_CLIENT_ID}`)
    form.append('client_secret', `${process.env.REACT_APP_CLIENT_SECRET}`)
    form.append('grant_type', 'password')
    form.append('email', credentials.email)
    form.append('password', credentials.password)

    const settings = {
      url: URL,
      method: 'POST',
      timeout: 0,
      headers: {
        Accept: 'application/json'
      },
      processData: false,
      mimeType: 'multipart/form-data',
      contentType: false,
      data: form
    }

    return await axios(settings)
      .then(async res => {
        window.$isloogedOut = false
        window.$userData = res.data.data
        setLoading(false)
        setLoginLocalStorage(res)
        localStorage.setItem(
          'phone_numbers',
          JSON.stringify(res.data.data.phone_numbers)
        )

        localStorage.setItem('is_appsumo', res.data.data.is_appsumo)
        localStorage.setItem(
          'default_meeting_place',
          res.data.data.default_meeting_place
        )
        localStorage.setItem(
          'user_type',
          res.data.data.is_appsumo ? 'appsumo' : 'regular'
        )
        localStorage.setItem('api_key', res.data.data.api_key)
        localStorage.setItem('access_token', res.data.data.access_token)
        localStorage.setItem('widget_id', res.data.data.widget_id)
        localStorage.setItem('plan_name', res.data.data.plan_name)
        localStorage.setItem(
          'working_hours_status',
          res.data.data.working_hours_status
        )
        localStorage.setItem('signed_up_at', res.data.data.signed_up_at)
        localStorage.setItem('status', res.data.data.status)
        localStorage.setItem(
          'profile_pic',
          res.data.data.profile_image
            ? `${process.env.REACT_APP_BASE_APP_URL}/storage` +
                res.data.data.profile_image
            : null
        )
        localStorage.setItem('quick_setup', res.data.data.onboarding_step + 1)
        localStorage.setItem('first_name', res.data.data.first_name)
        localStorage.setItem('role_name', res.data.data.role.name)
        if (res.data.data?.role_type?.toLocaleLowerCase() !== 'owner') {
          localStorage.setItem('flowType', 'invited')
        } else {
          localStorage.setItem('flowType', 'virtualPhone')
        }
        localStorage.setItem(
          'full_name',
          res.data.data.first_name + res.data.data.last_name
        )
        localStorage.setItem('last_name', res.data.data.last_name)
        localStorage.setItem('skip_onboarding', res.data.data.skip_onboarding)
        localStorage.setItem('email', res.data.data.email)
        localStorage.setItem('id', res.data.data.id)
        localStorage.setItem(
          'mobile_verification_status',
          res.data.data.mobile_verification_status
        )
        localStorage.setItem(
          'admin_verification_status',
          res.data.data.admin_verification_status
        )

        if (res.data.data.account_status) {
          localStorage.setItem('account_status', '1')
        } else {
          localStorage.setItem('account_status', '0')
        }

        if (res.data.data.admin_verification_status) {
          localStorage.setItem('admin_verification_status', '1')
        }

        if (!res.data.data.admin_verification_status) {
          localStorage.setItem('admin_verification_status', '0')
        }

        if (res.data.data.verified) {
          localStorage.setItem('email_verification_status', '1')
        }

        if (!res.data.data.verified) {
          localStorage.setItem('email_verification_status', '0')
          localStorage.setItem('quick_setup', 1)
          window.location.href = '/email-verify'
          return
        }

        setIsLoggedIn(true)

        getSubscription().then(async res1 => {
          const obj = {}
          const d = res1.data.data
          const months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ]

          obj.ends = d.current_term_end
          if (d.status == 'in_trial') {
            obj.ends = d.trial_end
          }
          //obj.ends = new Date(obj.ends)
          let dt = obj.ends.split(' ')
          dt = dt[0]

          let dts = dt.split('-')
          dts[1] = parseInt(dts[1]) - 1

          dt = dts[2] + '  ' + months[dts[1]] + ' ' + dts[0]
  if (res.data.data.skip_onboarding === 1) {
    localStorage.setItem('quick_setup', 5)
    redirectPage()
  }

  if (res.data.data.role.name === 'Admin') {
    if (
      res.data.data.onboarding_step >= 3 ||
      res.data.data.mobile_verification_status === 1
    ) {
      localStorage.setItem('quick_setup', 5)
      redirectPage()
    } else {
      localStorage.setItem('quick_setup', 1)
      if (d?.plan_status === 'paid_active') {
        localStorage.setItem('quickSetupAc', 2)
        redirectPage('/QuickSetup?paid')
      } else {
        redirectPage('/QuickSetup')
      }
    }
  } else {
    if (
      parseInt(res.data.data.onboarding_step) >= 2 ||
      parseInt(res.data.data.mobile_verification_status) === 1
    ) {
      localStorage.setItem('quick_setup', 5)
      redirectPage()
    } else {
      if (d?.plan_status === 'paid_active') {
        localStorage.setItem('quickSetupAc', 2)
        redirectPage('/QuickSetup?paid')
      } else {
        redirectPage('/QuickSetup')
      }
    }
  }
          await localStorage.setItem('subscription_nextbill', dt)
        })
      
      })
      .catch(err => {
        const errors = { ...err }

        if (!errors.response) {
          CommonNotify('Wrong Credentials', 'error')
          setLoading(false)
          return
        }

        if (errors.response.data.errors) {
          CommonNotify(
            errors.response.data.errors[0] ===
              `Your account is Not Verified, Please contact system admin for further details. <a href=\"${process.env.REACT_APP_BASE_APP_URL}/api/resend-verification-email?email=sumit%2B543%40betacloud.ai\">Click Here to Resend Verification Email</a>`
              ? 'Your account is Not Verified, Please contact system admin for further details.'
              : errors.response.data.errors[0]
          )
          setLoading(false)
        } else {
          CommonNotify('Wrong Credentials', 'error')
          setLoading(false)
        }
      })
  }

  return (
    <>
      <Dimmer active={isLoading} style={{ position: 'fixed' }}>
        <Loader />
      </Dimmer>
      <div className="login">
        <div className="login-box">
          <form onSubmit={handleLogin}>
            <Heading title="" />
            <div className="login-content">
              <div className="login-btn-wrapper">
                <GoogleButton
                  loading={setLoading}
                  content="Sign in with Google"
                />{' '}
              </div>{' '}
              <div className="or">
                <p className="or-text"> or </p>{' '}
              </div>{' '}
              <CommonInput
                onChange={handleInput}
                name="email"
                title="Email"
                type="email"
                error={error.email}
                pattern="^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,4}$"
              />
              <CommonInput
                onChange={handleInput}
                name="password"
                title="Password"
                type="password"
                error={error.password}
              />
              <CommonButton
                type="submit"
                style={{}}
                btnClass={validate ? 'btn-login enable' : 'btn-login'}
                content="Sign In"
              />
              <p className="login-to-register">
                Forgot Password ?{' '}
                <Link to="/forgot-password"> Reset Password </Link>{' '}
              </p>{' '}
              <CommonButton
                type="button"
                btnClass="btn-login-register enable"
                content="Sign Up"
                onClick={() => history.push('/signup')}
              />
            </div>{' '}
            <LoginFooter validate={validate} />{' '}
          </form>{' '}
        </div>{' '}
      </div>{' '}
    </>
  )
}

export default Login
