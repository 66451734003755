import { Delete } from '@mui/icons-material'
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField
} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CommonNotify } from '../../../common/CommonNotify'
import { apiSlice, useGetLeadNotesQuery } from '../../../features/api/apiSlice'

const LeadNotes = ({ id, openLead }) => {
  const res = useGetLeadNotesQuery(id)
  const dispatch = useDispatch()
  const [leadNotes, setLeadNotes] = useState([])
  const [note, setNote] = useState('')
  const [response, setResponse] = useState({})
  // useEffect(() => {
  //   if (res.isSuccess) {
  //     if (res.data.data?.length) {
  //       setLeadNotes(res.data.data)
  //     } else {
  //       setLeadNotes([res.data.data])
  //     }
  //   }
  //   return (() => {
  //       setLeadNotes([])
  //   })
  // }, [res])
  const getConvo = async id => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${id}`
    const res = await axios.get(url)
    setLeadNotes([...res?.data?.data?.conversation?.agent_notes])
  }
  useEffect(() => {
    getConvo(id)
  }, [id])

  const onClickAddNote = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${id}/agent-note`

    const postData = {
      note: note
    }
    axios
      .post(url, postData)
      .then(res => {
        getConvo(id)
      })
      .catch(err => {
        CommonNotify('Cant add note ')
      })
  }
  console.log('leadNotes', leadNotes)
  const onClickRemoveNote = index => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/note/${index}`

    axios
      .delete(url)
      .then(res => {
        getConvo(id)
      })
      .catch(err => {
        CommonNotify('Cant delete note ')
      })
  }

  return (
    <Collapse in={openLead} timeout="auto" unmountOnExit>
      <List>
        {leadNotes?.map((data, index) => {
          return (
            <ListItem
              sx={{
                backgroundColor: '#F3E779',
                borderTop: '1px solid #00000075'
              }}
              secondaryAction={
                <IconButton
                  onClick={() => {
                    // setLeadNotes(leadNotes?.filter((el, ind) => ind !== index))
                    onClickRemoveNote(data?.id)
                  }}
                  edge="end"
                  aria-label="delete"
                >
                  <Delete />
                </IconButton>
              }
            >
              <ListItemText
                primary={data.note}
                secondary={
                  <Box display="flex" justifyContent="space-between">
                    <span>{moment(data.date).format('LLL')}</span>
                    <span>{data.userName}</span>
                  </Box>
                }
              />
            </ListItem>
          )
        })}
      </List>
      <div className="add-contact-new info-note-add">
        <TextField
          id="outlined-basic"
          label="Write A note"
          variant="outlined"
          value={note}
          onChange={e => setNote(e.target.value)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              onClickAddNote()
            }
          }}
          sx={{
            width: '100%'
          }}
        />
      </div>
    </Collapse>
  )
}

export default LeadNotes
