import axios from 'axios'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Confirm, Dimmer, Loader } from 'semantic-ui-react'
//redux
import {
  getAllTeams,
  getMemberRoles,
  getTeamMembers
} from '../../actions/settings'
import {
  updateMemberAvailability,
  updateMemberState
} from '../../api/manageTeamMates'
import CommonInput from '../../common/CommonInput'
import Modal from '../../common/CommonNodeModal2'
import { CommonNotify } from '../../common/CommonNotify'
import CommonTable from '../../common/CommonTable3'
import MemberModal from '../../common/MemberModal'
import { getSubscription } from '../../actions/home'


const apiToken = localStorage.getItem('access_token')
const plan_name = localStorage.getItem('plan_name')

const modalDefaultData = [
  {
    headerTitle: 'Add Team Members',
    type: 'input',
    modalContent: [
      {
        placeholder: 'Full Name',
        input: 'full_name'
      },
      {
        placeholder: 'Email',
        input: 'email'
      }
    ]
  },
  {
    headerTitle: 'Member Teams',
    type: 'checkbox',
    modalContent: [
      {
        checkbox: 'Item 1'
      },
      {
        checkbox: 'Item 2'
      },
      {
        checkbox: 'Item 3'
      },
      {
        checkbox: 'Item 4'
      },
      {
        checkbox: 'Item 5'
      }
    ]
  },
  {
    headerTitle: 'Member Role',
    type: 'dropdown',
    name: 'role',
    modalContent: ''
  }
]

const modalWrapper = {
  wrapper: 'modal2-wrapper',
  btnText: 'Add Team Members',
  inputStyle: 'modal-input-style'
}

class ManageTeamMates extends Component {
  state = {
    allTeams: [],
    data: null,
    isLoading: false,
    dataTable: {
      type: '8',
      header: [
        {
          headerTitle: 'ID'
        },

        {
          headerTitle: 'Name'
        },
        {
          headerTitle: 'Email'
        },
        {
          headerTitle: 'Role'
        },
        {
          headerTitle: 'Account Status'
        },

        {
          headerTitle: 'Availability'
        },
        {
          headerTitle: 'Action'
        }
      ],
      tableContentData: [],
      tableActualData: [],
      deleteHandle: this.deleteHandle
    },
    members: {
      total: 0,
      active: 0,
      away: 0,
      notAvailable: 0,
      availabile: 0
    },
    open: false,
    openInvite: false,
    show: false,
    data: false,
    dataId: null,
    roleData: [],
    apiLoaded: false,
    modalData: modalDefaultData,
    isModalOpen: false,
    closeModal: false,
    dataName: null
  }

  componentDidUpdate(prevProps) {
    if (this.props.teamMembers !== prevProps.teamMembers) {
      this.props.loading(false)
      const data = this.props.teamMembers.data.members
      this.getLength(data)
      this.setMembers(this.props.teamMembers.data)
    }
    if (this.props.allTeams !== prevProps.allTeams) {
      this.props.loading(false)
      var t = []
      if (this.props.allTeams.data) {
        this.props.allTeams.data &&
          this.props.allTeams.data.map(function (item, index) {
            var d = {
              label: item.name,
              value: item.id
            }
            t.push(d)
          })

        let modalData = this.state.modalData
        modalData[1].modalContent = this.props.allTeams.data
        this.setState({ apiLoaded: true, allTeams: t })
      }
    }
    if (this.props.memberRoles !== prevProps.memberRoles) {
      let modalData = this.state.modalData
      const localData = this.props.memberRoles.data.map(item => {
        return { text: item.name, value: item.id }
      })
      modalData[2].modalContent = localData
      this.setState({ roleData: localData })
    }
  }

  componentWillMount = () => {
    const { loading } = this.props
    if (this.props.teamMembers) {
      loading(false)
      const data = this.props.teamMembers.data.members
      this.getLength(data)
      this.setMembers(this.props.teamMembers.data)
    } else {
      this.fetchData()
    }
    if (this.props.allTeams) {
      loading(false)
      var t = []
      if (this.props.allTeams.data) {
        this.props.allTeams.data &&
          this.props.allTeams.data.map(function (item, index) {
            var d = {
              label: item.name,
              value: item.id
            }
            t.push(d)
          })

        let modalData = this.state.modalData
        modalData[1].modalContent = this.props.allTeams.data
        this.setState({ apiLoaded: true, allTeams: t })
      }
    } else {
      this.fetchTeam()
    }
    if (this.props.memberRoles) {
      let modalData = this.state.modalData
      const localData = this.props.memberRoles.data.map(item => {
        return { text: item.name, value: item.id }
      })
      modalData[2].modalContent = localData
      this.setState({ roleData: localData })
    } else {
      this.getRoleData()
    }
  }

  searchHandle = term => {
    let { dataTable } = this.state

    if (term === '') {
      dataTable.tableContentData = dataTable.tableActualData
      this.setState({ dataTable })
      return
    }

    let items = []

    dataTable.tableActualData.map((row, index) => {
      if (
        row.name.toLowerCase().includes(term.toLowerCase()) ||
        row.email.toLowerCase().includes(term.toLowerCase()) ||
        row.phone.toLowerCase().includes(term.toLowerCase())
      ) {
        items.push(row)
      }
    })
    dataTable.tableContentData = items
    this.setState({ dataTable })
  }

  changeInput = () => { }

  fetchTeam = () => {
    const { widget, loading } = this.props

    loading(true)
    this.props.getAllTeams()

    /*  const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/teams`

    axios
      .get(url)
      .then(res => {
        loading(false)
        var t = []
        var f;
        if (res.data.data) {
          res.data.data && res.data.data.map(function(item,index){
             var d = {
               label : item.name,
               value : item.id
             }
            t.push(d) 
          })
         
          let modalData = { ...this.state.modalData }
          modalData[1].modalContent = res.data.data
          this.setState({ apiLoaded: true, allTeams: t })
        }
      })
      .catch(error => {
        loading(false)
      }) */
  }

  getLength(arr) {
    return Object.keys(arr)?.length
  }

  fetchData = () => {
    const { loading } = this.props
    loading(true)
    this.props.getTeamMembers()

    /* 
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`

    axios
      .get(url)
      .then(res => {
        loading(false)
        if (res.data.data) {
          const data = res.data.data.members
          this.getLength(data)
          this.setMembers(res.data.data)
        }
      })
      .catch(error => {
        const { response = {} } = error || {}
        const { data = {} } = response
        const { message = '' } = data
        CommonNotify(message, 'error')
        loading(false)
      }) */
  }

  setMembers = data => {
    let { members, dataTable } = this.state
    let user = []
    let admin = data.admin
    let users = [...data.members]

    users.unshift(admin)
    members.total = data.available_members
    members.active = data.active_members
    const notAvailableCount = data.members?.filter(item => item.status === 0)
      ?.length
    const availableCount = data.members?.filter(item => item.status === 1)?.length
    members.availabile = availableCount
    members.notAvailable = notAvailableCount
    members.away = data.active_members
    const tempobj = {
      id: 0,
      status: '',
      name: '',
      email: '',
      role: {},
      accountStatus: 0,
      phoneNumberStatus: '',
      phone: '',
      availability: 0,
      state: 0
    }

    var statusLine = ''

    users.map((row, index) => {
      var userTeams = []
      let obj = { ...tempobj }

      obj.id = row.id
      obj.state = 'Not Active'
      if (row.state == 1) {
        obj.state = 'Active'
      }

      row.get_teams &&
        row.get_teams.map(function (item, index) {
          var d = {
            label: item.name,
            value: item.id
          }
          userTeams.push(d)
        })

      if (row.verified) {
        if (row.status) {
          statusLine = 'Active'
        } else {
          statusLine = 'Suspended'
        }
      } else {
        statusLine = 'Invited'
      }
      obj.statusLine = statusLine
      obj.userTeams = userTeams
      obj.name = row.first_name + ' ' + row.last_name
      obj.email = row.email
      // if (row.role.name === 'Client' || row.role.name === 'Owner') {
      //   row.role.name = 'Owner'
      // }
      // if (row.role.name === 'Manager' || row.role.name === 'Admin') {
      //   row.role.name = 'Admin'
      // }
      // if (row.role.name === 'Team Leader' || row.role.name === 'Member') {
      //   row.role.name = 'Member'
      // }
      row.role.name = row?.role_type
      obj.role = { value: row.role.id, text: row.role.name }
      obj.accountStatus = row.account_status
      obj.availability = row.status
      obj.blocked = row.blocked

      if (row.user_phone_numbers?.length) {
        obj.phone = row.user_phone_numbers[0].phone_number
      }

      obj.phoneNumberStatus = 'Not Verified'
      if (row.mobile_verification_status == 1) {
        obj.phoneNumberStatus = 'Verified'
      }

      user.push(obj)
    })

    dataTable.tableContentData = user
    dataTable.tableActualData = user
    this.setState({ members, dataTable })
  }
  onClickOpenModal = id => {
    const { widget, loading } = this.props
    this.setState({ isLoading: true })

    this.setState({ isModalOpen: false, isLoading: false })
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/member/${id}`
    axios
      .get(url)
      .then(res => {
        this.setState({ ...this.state, data: res.data.data }, () =>
          this.setState({ show: true })
        )
      })
      .catch(error => {
        this.setState({ isLoading: false })
        if ({ ...error }.response) {
        } else {
        }
      })
  }

  handleClose = () => this.setState({ ...this.state, show: false })

  handleData = async data => {
    const plan_name = localStorage.getItem('plan_name')
    const len = this.state.dataTable.tableContentData?.length

    if (
      !data.role ||
      data.full_name == '' ||
      data.email == '' ||
      !data.teams?.length
    ) {
      CommonNotify('Please select all fields', 'warning')
      return
    }

    const names = data.full_name.split(' ')

    if (names?.length < 2) {
      CommonNotify('Please enter full name', 'warning')
      return
    }

    if (names[1].match(/\d+/g) != null) {
      CommonNotify('Please enter valid name', 'warning')
      return
    }

    const { widget, loading } = this.props

    loading(true)

    this.setState({ isModalOpen: false, isLoading: true })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`
    const body = {
      full_name: data.full_name,
      email: data.email,
      role: data.role
    }
    axios
      .post(url, body)
      .then(res => {
        loading(false)
        if (res.data.message[0] == 'Successfully') {
          this.setState({ isModalOpen: false, isLoading: false })
          CommonNotify('Team Member Added', 'success')
          this.handleDataRef()
          this.fetchData()
          this.props.getSubscription()
        }
      })
      .catch(error => {
        loading(false)
        this.setState({ isLoading: false })
        if ({ ...error }.response) {
          if ({ ...error }.response.data.errors[0] != '') {
            CommonNotify({ ...error }.response.data.errors[0])
          }
          CommonNotify('Cant load data from server. please reload page')
        } else {
          CommonNotify('System error Or Email is already Exists in data base')
        }
      })
  }

  getRoleData = () => {
    this.props.getMemberRoles()
    /* const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/member-roles`
    
    axios
      .get(url)
      .then(res => {
        let modalData = { ...this.state.modalData }

       
        const localData = res.data.data.map(item => {
          return { text: item.name, value: item.id }
        })
        modalData[2].modalContent = localData
        this.setState({ roleData: localData })      
        
      })
      .catch(err => {}) */
  }
  getMemberData = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/member/1`

    axios
      .get(url)
      .then(res => {
        let modalData = { ...this.state.modalData }

        const localData = res.data.data.map(item => {
          return { text: item.name, value: item.id }
        })
        modalData[2].modalContent = localData
        this.setState({ roleData: localData })
      })
      .catch(err => { })
  }

  openConfirm = (id, full_name) => {
    this.setState({ open: true, dataId: id, dataName: full_name })
  }

  openConfirmInvite = (id, full_name) => {
    this.setState({ openInvite: true, dataId: id, dataName: full_name })
  }

  sendVerification = (id, full_name) => {
    this.setState({ isLoading: true })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/resend-invitation-mail`

    const form = new FormData()
    form.append('member_id', id)

    axios
      .post(url, form)
      .then(res => {
        this.setState({ isLoading: false })
        CommonNotify('Email sent successfully.', 'success')
      })
      .catch(error => {
        this.setState({ isLoading: false })
        if ({ ...error }.response)
          CommonNotify({ ...error }.response.data.errors[0])
        CommonNotify('System error Or Email is already Exists in data base')
      })
  }

  close = () => this.setState({ open: false, openInvite: false })

  deleteTeam = id => {
    const { widget, loading } = this.props
    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/delete-member/${this.state.dataId}`

    axios
      .delete(url)
      .then(res => {
        loading(false)
        if (res.data.data) {
          CommonNotify('Team Member Removed', 'success')
          this.fetchData()
          this.props.getSubscription()
          this.setState({ open: false })

        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Remove Team Member')
      })
  }

  deleteInvite = id => {
    const { widget, loading } = this.props
    loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/delete-member/${this.state.dataId}`
    axios
      .delete(url)
      .then(res => {
        loading(false)
        if (res.data.data) {
          CommonNotify('Invited User Removed', 'success')
          this.fetchData()
          this.setState({ open: false, openInvite: false })
          this.props.getSubscription()

        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Remove Invited User')
      })
  }

  isModalOpenHandler = data => {
    this.setState({
      isModalOpen: data
    })
  }
  handleDataRef = (DataState, key, value) => {
    const { loading } = this.props
    let data = 1
    if (value === 1) {
      data = 0
    }
    loading(true)
    updateMemberAvailability(key, data)
      .then(res => {
        loading(false)
        if (res) {
          let mainaccid = localStorage.getItem('id')
          if (parseInt(mainaccid) === key) {
            localStorage.setItem('status', data)
            window.location.reload()
          }
          this.fetchData()
        }
      })
      .catch(error => {
        loading(false)
      })
  }

  handleMemberState = (DataState, key, value) => {
    const { loading } = this.props
    let data = 1
    if (value === 1) {
      data = 0
    }
    loading(true)
    updateMemberState(key, data)
      .then(res => {
        loading(false)
        if (res) {
          this.fetchData()
        }
      })
      .catch(error => {
        loading(false)
      })
  }

  render() {
    const { max_users, users_used } = this.props.subscription?.data || {}

    return (
      <>
        <Dimmer active={this.state.isLoading} style={{ position: 'fixed' }}>
          <Loader />
        </Dimmer>
        <div className="manage-teammates-wrapper manage_teammates_main">
          <div className="input-search-wrapper">
            <p className="sub-text default-text input-description">
              <span>
                {this.state.members.availabile} Active /
                {this.state.members.notAvailable} Away
              </span>
              <span>
                {'Your plan has ' +
                  (max_users
                    ? max_users - (users_used || 0)
                    : plan_name === 'ADVANCED'
                      ? 10
                      : plan_name === 'PRO'
                        ? 5
                        : 3) +
                  ' seats available'}
              </span>
            </p>
            <div className="input-search-holder">
              <div className="input-search-box">
                <CommonInput
                  onChange={e => this.searchHandle(e.target.value)}
                  icon="search"
                  disabled={this.state.isModalOpen}
                  iconPosition="left"
                  placeholder="Search Name or Email"
                  background="gray"
                />
              </div>
              {this.state.apiLoaded && (
                <Modal
                  modalWrapper={modalWrapper}
                  handleData={this.handleData}
                  modalData={this.state.modalData}
                  isModalOpen={this.isModalOpenHandler}
                  modalOpen={this.state.isModalOpen}
                  isFilter
                />
              )}
            </div>
          </div>
          <div className="manage-teammates-table">
            <MemberModal
              show={this.state.show}
              handleClose={this.handleClose}
              modalData={this.state.modalData}
              data={this.state.data}
            />
            <CommonTable
              allTeams={this.state.allTeams}
              sendVerification={this.sendVerification}
              dataTable={this.state.dataTable}
              deleteHandle={this.openConfirm}
              handleDataRef={this.handleDataRef}
              handleMemberState={this.handleMemberState}
              roleData={this.state.roleData}
              fetchData={this.fetchData}
              onClickOpenModal={this.onClickOpenModal}
              deleteInvite={this.openConfirmInvite}
            />
            <Confirm
              className="confirmBox-wrapper confirmBox_wrapper"
              open={this.state.open}
              header="Delete ManageTeam!"
              content={`Do you want to delete Manage Team -  ${this.state.dataName} ?`}
              onCancel={this.close}
              onConfirm={this.deleteTeam}
            />
            <Confirm
              className="confirmBox-wrapper confirmBox_wrapper"
              open={this.state.openInvite}
              header="Delete Invited User!"
              content={`Do you want to delete the user invite -  ${this.state.dataName} ?`}
              onCancel={this.close}
              onConfirm={this.deleteInvite}
            />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  teamMembers: state.settings?.teamMembers,
  allTeams: state.settings.allTeams,
  memberRoles: state.settings.memberRoles,
  subscription: state.home.subscription
})

export default connect(mapStateToProps, {
  getTeamMembers,
  getAllTeams,
  getMemberRoles,
  getSubscription: getSubscription
})(ManageTeamMates)
