import React, { useEffect, useState } from 'react'
import {
  Dimmer,
  Loader,
  Modal,
  Pagination,
  Segment,
  Tab,
  Table
} from 'semantic-ui-react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DialerImage from '../../assets/images/flaticons/dialer.png'
import hostImg from '../../assets/images/host-img.png'
import zoomImg from '../../assets/images/Zoom.png'
import blockIcon from '../../assets/images/block.png'
import celenderIcon from '../../assets/images/celender.png'
import bellImg from '../../assets/images/bell.png'
import moment from 'moment'
import SendMessageModal from '../../common/SendMessageModal'
import { Tooltip } from '@mui/material'
import { connect } from 'react-redux'
import { getDialer } from '../../actions/dialer'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import Axios from 'axios'
import CallbackModal from '../../common/CallbackModal'
import schedule from '../../assets/images/schedule.png'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

const MeetingTypeTeam = ({ setDetailPopUp, status, ...props }) => {
  console.log('props', status, props)
  const [selectedLead, setSelectedLead] = useState(0)
  const [open, setOpen] = React.useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [tableFullData, setTableFullData] = useState({})
  const [pageNumber, setPageNumber] = useState(1)
  const statusOption = {
    1: 'Pending',
    2: 'Assigned',
    3: 'Closed',
    4: 'Qualified'
  }
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
    sort_field: 'id',
    sort_value: 'DESC',
    sort: '-id',
    'filter[type][!in]': 'TEXT_MESSAGE',
    'filter[direction][is]': 'Inbound',
    'filter[final_status][!is]': status
  })
  const deleteModalView = async (bool, lead = 0) => {
    if (lead) {
      setSelectedLead(lead)
    }
    setDeleteModal(bool)
  }

  const isModalClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    const data = tableFullData?.leads
    setTotalPages(data?.last_page)
  }, [tableFullData])

  const trimUrl = (url = '') => {
    if (!url || url == '') return ''
    const newUrl = url.split('/', 3)
    if (!url?.length) return ''

    url = newUrl[newUrl?.length - 1]
    return url
  }
  const openDialer = () => {
    return props?.getDialer(!props.dialerStatus)
  }
  const isModalOpen = () => {
    setOpen(!open)
  }
  const onDeleteLeadHandler = async () => {
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/delete-leads`
    var postId = selectedLead

    const postData = {
      ids: postId
    }

    Axios.post(URL, postData)
      .then(res => {
        CommonNotify('Lead Deleted Successfully', 'success')
        setSelectedLead(0)
      })
      .catch(err => {
        CommonNotify('Not able to Delete Lead')
      })
  }
  const fetchLeadData = async (page, status) => {
    var url

    const para = {
      ...params,
      page: page || params?.page,
      'filter[final_status][!is]': status
    }

    setLoading(true)
    url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`
    Axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params: para
    })
      .then(res => {
        setLoading(false)
        setTableFullData(res.data.data)
      })
      .catch(err => {
        setLoading(false)
      })
  }
  const onPageChange = (e, a) => {
    setPageNumber(a.activePage)
    setParams({ ...params, page: a.activePage })
    fetchLeadData(a.activePage)
  }

  useEffect(() => {
    fetchLeadData(1, status)
  }, [status])

  const getPlaceholder = {
    missed: {
      title: 'No missed bookings',
      subTitle:
        'You have no missed bookings. Your missed bookings will show up here'
    },
    scheduled: {
      title: 'No upcoming bookings',
      subTitle:
        'There are no upcoming scheduled calls. Your scheduled calls and meetings will show up here'
    },
    completed: {
      title: 'No past bookings',
      subTitle:
        'You have no past bookings. Your past bookings will show up here'
    },
    canceled: {
      title: 'No canceled bookings',
      subTitle:
        'You have no canceled bookings. Your canceled bookings will show up here'
    }
  }

  return (
    <>
      <Dimmer active={loading} style={{ position: 'fixed' }}>
        <Loader />
      </Dimmer>
      <Tab.Pane attached={false}>
        {tableFullData?.leads?.data?.length > 0 ? (
          <Table singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Contact</Table.HeaderCell>
                <Table.HeaderCell>Source</Table.HeaderCell>
                <Table.HeaderCell>Meeting Details</Table.HeaderCell>
                <Table.HeaderCell>Call Status</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {tableFullData?.leads?.data?.map(obj => (
                <Table.Row>
                  <Table.Cell className="date-set-table">{obj?.id}</Table.Cell>
                  <Table.Cell className="time-set-table">
                    {moment(obj?.created_at)?.format('MMMM, DD, YYYY HH:hh A')}
                  </Table.Cell>
                  <Table.Cell className="metting-name">
                    {obj?.contact_name}
                  </Table.Cell>
                  <Table.Cell>
                    <div
                      className={
                        obj?.source === 'Webpage' ? 'webpage' : 'facebook'
                      }
                    >
                      <Tooltip title={obj?.source || ''}>
                        <Link to={obj?.source}>
                          {obj.source === null
                            ? 'Unknown'
                            : trimUrl(obj?.source) || ''}
                        </Link>
                      </Tooltip>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    {obj.agent}
                    {/* <br></br>
            {tableFullData?.leads?.data[index]?.request_call_day
              ? tableFullData?.leads?.data[index]?.request_call_day
              : ''} */}
                    <br></br>
                    {obj.request_call_time
                      ? moment
                          .utc(obj.request_call_time)
                          .local()
                          // .utc(data.request_call_time)
                          .format('dddd, MMMM, DD, YYYY')
                      : ''}
                    <br></br>
                    {obj.request_call_time
                      ? moment
                          .utc(obj.request_call_time)
                          .local()
                          // .utc(data.request_call_time)
                          .format('HH:hh A') +
                        ' ' +
                        moment.tz.guess()
                      : // ' to ' +
                        // moment(data.request_call_time)
                        //   // .utc(data.request_call_time)
                        //   .add(tableFullData?.meeting_duration, 'seconds')
                        //   .format('HH:hh A')
                        ''}{' '}
                    <br></br>
                    {obj?.meeting_location}
                  </Table.Cell>

                  <Table.Cell className="social-icon-set">
                    {obj?.final_status}
                  </Table.Cell>
                  <Table.Cell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title={'Cancel Meeting'}>
                        <DeleteForeverIcon
                          fontSize="large"
                          color="action"
                          onClick={() => deleteModalView(true, obj?.id)}
                        />
                      </Tooltip>

                      <>
                        <a
                          onClick={() => setOpen(true)}
                          data-tip="Open dialer"
                          style={{
                            marginLeft: 10,
                            marginRight: 10
                          }}
                        >
                          <Tooltip title={'Reschedule meeting'}>
                            <img style={{ width: '30px' }} src={schedule} />
                          </Tooltip>
                        </a>
                        {/* <a
                  onClick={() => setOpen(true)}
                  data-tip="Send SMS & MMS"
                  href="javascript:void(0)"
                >
                  <Tooltip title={'Send SMS'}>
                   <MoreVertIcon/>
                  </Tooltip>
                </a> */}
                      </>

                      {/* <Popup
              className="profile_popup"
              content={<AddTagPopup tags={allTags} addTag={addTag} />}
              size="large"
              position="bottom center"
              flowing
              on="click"
              trigger={
                <div style={{ marginTop: '15px' }}>
                  {!tags?.length && (
                    <a href="javascript:void(0)"> + Add New Tag</a>
                  )}{' '}
                </div>
              }
            /> */}
                    </div>
                    {open && (
                      <CallbackModal
                        closeAfterSend={isModalClose}
                        open={open}
                        isModalClose={isModalOpen}
                        // dropDownData={dropDownData}
                        // leadData={data}
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <Segment style={{ display: 'flex', minHeight: '400px' }}>
            <div className="no-leads">
              <div className="icon">
                <CalendarTodayIcon />
              </div>
              <h5>{getPlaceholder?.[status]?.title}</h5>
              <p>{getPlaceholder?.[status]?.subTitle}</p>
            </div>
          </Segment>
        )}
        {totalPages > 1 && (
          <Pagination
            defaultActivePage={pageNumber}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        )}
      </Tab.Pane>

      <Modal
        className="local-number-modal"
        open={deleteModal}
        onClose={e => deleteModalView(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to cancel the meeting {selectedLead}?
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={() => deleteModalView(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={onDeleteLeadHandler}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </>
  )
}
const mapStateToProps = state => ({
  widget: state.home.widget,
  subscription: state.home.subscription,
  dialerStatus: state.dialer.currentStatus
})

export default connect(mapStateToProps, {
  getDialer
})(MeetingTypeTeam)
