import React, { Component } from 'react'
import CommonSubscriptionModal from './CommonSubscriptionModal'
import AppsumoSubscriptionModal from './AppsumoSubscriptionModal'

import moment from 'moment'

import { connect } from 'react-redux'
import { getSubscription } from '../actions/home'

const isAppsumo = localStorage.getItem('is_appsumo')

class PlanExpired extends Component {
  state = {
    subscriptionData: {
      tableContentData: [
        { ends: 0, max_calls: 0, calls_used: 0, max_sms: 0, sms_used: 0 }
      ]
    },
    showExpiredModel: false,
    isTrial: false,
    trialDays: 0,
    isLoading: false,
    phoneNumbers: null,
    showSubscriptionModal: false,
    pleaseUpgrade: '',
    plan_name: null,
    subscriptionModalVisibility: false
  }

  componentDidMount = async () => {
    if (this.props.subscription) {
      this.GetSubscribeData(this.props.subscription)
    } else {
      this.props.getSubscription()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.subscription !== prevProps.subscription) {
      this.GetSubscribeData(this.props.subscription)
    }
  }

  GetSubscribeData = async res => {
    var upgradeStatus = false
    var titleLine = ''
    var upgradeLine = ''
    const data = res.data
    if (data) {
    let trialEndDate = moment(data.trial_end)
    let planEndDate = moment(
      data.current_term_end ? data.current_term_end : null
    )
    let today = moment(new Date())
    var trialDays = trialEndDate.diff(today, 'days')
    var plan_expired = planEndDate.diff(today, 'days')
    var max_calls = data.max_calls
    var used_calls = data.calls_used
    var pleaseUpgrade = ''

    var customPlan =
      data.plan_name.charAt(0).toUpperCase() +
      data.plan_name.slice(1).toLowerCase()

    const tableData = {
      tableContentData: [
        {
          plan_name: data.plan_name,
          ends: 0,
          max_calls: data.max_calls,
          calls_used: data.calls_used,
          max_sms: data.max_sms,
          sms_used: data.sms_used
        }
      ]
    }

    //------------------- Trial Plan -------------------//
    if (+isAppsumo) {
      titleLine = ' '
    } else {
      if (data.status === 'in_trial') {
        if (trialDays > 0 && max_calls > used_calls) {
          upgradeStatus = true
          pleaseUpgrade = 'Upgrade now'
          titleLine =
            'See what your trial is all about. Your trial plan will expire in ' +
            trialDays +
            ' days .'
          upgradeLine = '  for a seamless service.'
        } else if (trialDays > 0 && max_calls <= used_calls) {
          upgradeStatus = true
          pleaseUpgrade = 'Please Upgrade '
          titleLine = `You have reached the limits of your trial plan.`
          upgradeLine = '  or contact us for add-ons to'
        } else if (trialDays <= 0) {
          upgradeStatus = true
          pleaseUpgrade = 'Please Upgrade '
          titleLine =
            'Your trial plan has expired on ' +
            moment(data.trial_end).format('LL') +
            '.'
          upgradeLine = ' .'
        } else {
          titleLine = ''
        }
      } else if (data.status === 'Active') {
        if (plan_expired > 0 && max_calls > used_calls) {
          upgradeStatus = true
          titleLine = ''
        } else if (plan_expired > 0 && max_calls <= used_calls) {
          upgradeStatus = true
          pleaseUpgrade = 'Please Upgrade '
          titleLine =
            'You have reached the limits of your current plan ' +
            data.plan_name +
            '.'
          upgradeLine = ' or contact us for add-ons to'
        } else if (plan_expired <= 0) {
          upgradeStatus = true
          pleaseUpgrade = 'Please Upgrade '
          titleLine =
            'Your current plan ' +
            data.plan_name +
            ' has expired on ' +
            moment(data.current_term_end).format('LL') +
            '.'
          upgradeLine = ' .'
        } else {
          titleLine = ''
        }
      } else if (
        data.status?.toLowerCase() === 'trial_expired' ||
        data.status?.toLowerCase() === 'canceled'
      ) {
        titleLine = 'Trial expired.'
        upgradeLine = ""
        this.setState({ showExpiredModel: true })
      } else {
        titleLine = ''
        this.setState({ showExpiredModel: true })
      }
    }

    localStorage.setItem('plan_name', res.data.plan_name)
    localStorage.setItem('max_users', res.data.max_users)

    this.setState({
      plan_name:
        data.status === 'Cancelled' || data.status === 'in_trial'
          ? ''
          : res.data.plan_name,
      pleaseUpgrade: pleaseUpgrade,
      isTrial: upgradeStatus,
      trialDays: trialDays,
      titleLine: titleLine,
      subscriptionData: tableData,
      upgradeLine: upgradeLine
    })
  }
}
  closeSubscriptionModal = () => {
    this.setState({ showExpiredModel: false })
  }

  render() {
    const account=localStorage?.getItem('account')

    return (
      <>
            
{account===3 ||  account==='3' ?
  <CommonSubscriptionModal
  open={this.state.showExpiredModel}
  onClose={this.closeSubscriptionModal}
  currentPlan={this.state.plan_name}
  fixed={true}
  title="Your existing plan expired, select new plan"
  dataTable={this.state.subscriptionData}
/>

:
isAppsumo === 1 || isAppsumo === '1' ? (
          <AppsumoSubscriptionModal
            open={this.state.showExpiredModel}
            onClose={this.closeSubscriptionModal}
            currentPlan={this.state.plan_name}
            title="Your existing plan expired, select new plan"
            fixed={true}
            dataTable={this.state.subscriptionData}
          />
        ) : (
          <>
            {/* <CommonSubscriptionModal
              open={this.state.showExpiredModel}
              onClose={this.closeSubscriptionModal}
              currentPlan={this.state.plan_name}
              fixed={true}
              title="Your existing plan expired, select new plan"
              dataTable={this.state.subscriptionData}
            /> */}
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  subscription: state.home.subscription
})

export default connect(mapStateToProps, { getSubscription })(PlanExpired)
