import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { connect } from 'react-redux'
import { Dimmer, Dropdown, Loader, Modal } from 'semantic-ui-react'
import { getWidgetCountries } from '../actions/settings'
import close from '../assets/images/close.png'
import CountryData from '../helpers/data/countryCode.json'
import CommonButtons from './CommonButtons'
import { CommonNotify } from './CommonNotify'
import CommonInput from './CommonInput'
import moment from 'moment'

const CallbackModal = ({
  open,
  isModalClose,
  leadData,
  getWidgetCountries,
  widgetCountries,
  phone_number,
  widgetData
}) => {
  const [allowedCountries, setAllowedCountries] = useState([])
  const [agentList, setAgentList] = useState([])
  const [loader, setLoader] = useState(false)
  const [type, setType] = useState('Call Now')
const [dateTime, setDateTime] = useState({})
const [date, setDate] = useState('')
const [time, setTime] = useState('')
const [email, setEmail] = useState('')
const [name, setName] = useState('')
const apiKey=localStorage?.getItem('data-api-key')
const widgetId=localStorage?.getItem('widget_id')

  const [sendMsgData, setSendMsgData] = useState({
    lead_id: leadData?.id,
    from: null,
    to: leadData?.phone_number || phone_number
  })
  useEffect(() => {
    getAgentList()
  }, [])

  const getAgentList = async () => {
    setLoader(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`
    await Axios.get(url)
      .then(res => {
        if (res?.data?.data) {
          setLoader(false)
          setAgentList([res?.data?.data?.admin,...res?.data?.data?.members] || [])
        }
      })
      .catch(error => {
        setLoader(false)
      })
  }

  useEffect(() => {
    if (sendMsgData?.close === true) {
      isModalClose()
      setSendMsgData({
        ...sendMsgData,
        close: false
      })
    }
  }, [sendMsgData])

  useEffect(() => {
    resetWidgetCountries(widgetCountries?.data?.allowed_countries)
  }, [widgetCountries])

  useEffect(() => {
    if (!open) return
    if (allowedCountries?.length) return

    getWidgetCountries()
  }, [open])

  const resetWidgetCountries = countries => {
    const allCountry = CountryData
    const allowed = allCountry
      ?.filter(item => {
        if (countries?.includes(item.name)) {
          return item
        }
      })
      .map(item => item.code.toLowerCase())

    setAllowedCountries(allowed)
  }

  const handleMakeCallback = async () => {
    if (agentList && !agentList?.length) {
      CommonNotify(
        'First you need to add agent from setting then after try again.'
      )
      return
    }
    if (!sendMsgData?.from) {
      CommonNotify('Please select Agent')
      return
    }
    if (!sendMsgData?.to) {
      CommonNotify('To number is required')
      return
    }

    const payload = {
      phone_number: sendMsgData?.to,
      country_code: contryCode(sendMsgData?.to),
      to_agent: sendMsgData?.from
    }
    setLoader(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/customer-direct-call`
    await Axios.post(url, payload)
      .then(res => {
        CommonNotify('Callback request is successfully')
        setLoader(false)
        isModalClose()
      })
      .catch(error => {
        setLoader(false)
        CommonNotify(error?.response?.data?.errors?.[0])
      })
    return
  }
  const handleMakeCallLater = async () => {
    if (agentList && !agentList?.length) {
      CommonNotify(
        'First you need to add agent from setting then after try again.'
      )
      return
    }
    if (!sendMsgData?.from) {
      CommonNotify('Please select Agent')
      return
    }
    if (!sendMsgData?.to) {
      CommonNotify('To number is required')
      return
    }

    const params = {
      phone: sendMsgData?.to,
      country_code: contryCode(sendMsgData?.to),
      to_agent: sendMsgData?.from,
      ...email && ({email:email}),
      ...name && ({name:name}),
      type:'SCHEDULE_CALL',
      // scheduled_at:`${date} ${time}`,
      scheduled_at:`2023-07-07 05:00:00`,
      pId:widgetData.script_id,
      // time_zone:moment.tz()
    }
    setLoader(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/request-call`
    await Axios.get(url,  {headers: {

      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    params: params})
      .then(res => {
        CommonNotify('Callback request is successfully')
        setLoader(false)
        isModalClose()
      })
      .catch(error => {
        setLoader(false)
        CommonNotify(error?.response?.data?.errors?.[0])
      })
    return
  }
  const getDateTime = async (id) => {
    setLoader(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/widget/modify-slots/${apiKey}/GMT+05:30?team_id=${id}`
    await Axios.get(url)
      .then(res => {
        if (res?.data?.data) {
          setLoader(false)
setDateTime(res?.data?.data)
        }
      })
      .catch(error => {
        setLoader(false)
      })
  }
  const contryCode = value => {
    let values = ''
    const count = value?.length - 10
    values = value?.substring(0, count)
    if (value?.substring(0, 1) !== '+') {
      values = '+' + values
    }
    return values
  }
  return (
    <Modal
      className="send-message-modal"
      onClose={isModalClose}
      closeIcon
      open={open}
      size="tiny"
    >
      <Dimmer active={loader}>
        <Loader />
      </Dimmer>
      <div className="modal-header align-items-center">
        <h2 className="mx-auto mb-0">{type==="Call Now" ? "Callback" :"Schedule Meeting"}</h2>
        <div className="close-icon" onClick={() => isModalClose(false)}>
          <img src={close} alt="close" />
        </div>
      </div>
      <Modal.Content style={{ padding: '30px' }}>
        <p>
          First, we'll call your phone. Once you answer, we'll call the
          customer's phone. From their perspective, it's like you called
          directly from your tracking number.
        </p>
        <div className="send-message__dropdown">
          <label>Call From *</label>
          <Dropdown
            name="from"
            placeholder="Select Agent"
            selection
            options={agentList?.flat().map(option => ({
              key: option?.id,
              text: `${option?.first_name} ${option?.last_name}`,
              value: option?.id,
              id: option?.id
            }))}
            onChange={(e, data) =>
           {   setSendMsgData({
                ...sendMsgData,
                from: data?.value,
                agentId: data?.value || data?.id
              })
              getDateTime(data?.value || data?.id)
              // getAvailable(data?.value || data?.id)
            }
            }
          />
        </div>
        {/* <div className="send-message__dropdown">
          <label>{' '}</label>
          <Dropdown
            name="from"
            placeholder="Call Now"
            selection
            options={[{
              key: 1,
              text:'Call Now',
              value: 'Call Now',
              id: 1
            },
            // {
            //   key: 2,
            //   text:'Call Later',
            //   value: 'Call Later',
            //   id: 2
            // },
          
          ]
             
        }
            onChange={(e, data) =>
              setType(data?.value)
            }
          />
        </div> */}

        {type==='Call Later'
        
         &&
         <>
         <div className='d-flex w-100'>

<div className="send-message__input w-100">
          <label>Date</label>
          <Dropdown
            name="from"

            placeholder="Select Date"
            selection
            options={[...Object?.keys(dateTime)?.map((obj,index)=>({
              key: index,
              text:obj,
              value: obj,
              id: index
            }))]
             
        }
            onChange={(e, data) =>
              setDate(data?.value)
            }
          />
        </div>
        <div className="send-message__input w-100">
          <label>Time</label>
          <Dropdown
            name="from"
            placeholder="Select Time"
            selection
            options={date ? [...dateTime?.[date]?.map((obj,index)=>({
              key: index,
              text:obj,
              value: obj,
              id: index
            }))]:[]
             
        }
            onChange={(e, data) =>
              setTime(data?.value)
            }
          />
        </div>
         </div>
         <div className="send-message__input">
          <label>Customer Email</label>
          <CommonInput
           style={{width:'100%'}}
            // placeholder="To"
            // className="text-color"
            // onlyCountries={allowedCountries}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
              </div>
              <div className="send-message__input">
          <label>Name</label>
          <CommonInput
         style={{width:'100%'}}
            // placeholder="To"
            // className="text-color"
            // onlyCountries={allowedCountries}
            value={name}
            onChange={e => setName(e.target.value)}

          />
        </div>
         </>
        }
        <div className="send-message__input">
          <label>Call To *</label>
          <PhoneInput
            placeholder="To"
            className="text-color"
            onlyCountries={allowedCountries}
            value={sendMsgData?.to}
            onChange={value => setSendMsgData({ ...sendMsgData, to: value })}
          />
        </div>
      </Modal.Content>
      <Modal.Actions className="send-message__btn">
        <CommonButtons
          content={type==="Call Now" ? "Begin Call" :"Schedule Meeting"}
          style={{ background: '#9da3b1', color: 'white' }}
          onClick={e => type==="Call Now" ? handleMakeCallback(): handleMakeCallLater()}
        />
      </Modal.Actions>
    </Modal>
  )
}

const mapStateToProps = state => {
  
  return { widgetCountries: state.settings.widgetCountries,widgetData: state.home?.widget?.data?.[0] }
}

export default connect(mapStateToProps, { getWidgetCountries })(CallbackModal)
