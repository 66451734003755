import AppShortcutIcon from '@mui/icons-material/AppShortcut'
import DashboardIcon from '@mui/icons-material/Dashboard'
import DialerSipIcon from '@mui/icons-material/DialerSip'
import DialpadIcon from '@mui/icons-material/Dialpad'
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import CommonButtons from '../../common/CommonButtons'

export default function MakeCall(props) {
  const [tabVal, setTabVal] = useState(0)
const history=useHistory()
  const handleChangeTab = (event, newValue) => {
    setTabVal(newValue)
  }
  const url={0: "https://play.google.com/store/apps/details?id=com.limecall&hl=en_IN&gl=US&pli=1",1 :'https://dialer.limephone.io/' ,2:'https://app.limephone.io/'}
  return (
    <>
      <div className="testcall-container">
        <div className="testcall-heading">
          <h5>Receiving calls</h5>
          <p>Decide how you want ro receive calls</p>
          {/* <h6>Default business hours:</h6>
                <div className="hours-box">                        
                    <h6>Your phone is setup to ring 24/7</h6>
                </div> */}
        </div>
        <div >
          <Tabs
            value={tabVal}
            onChange={handleChangeTab}
            aria-label="icon label tabs example"
            className="tab-main"
          >
            <Tab icon={<AppShortcutIcon />} label="Mobile App" />
            <Tab icon={<DialpadIcon />} label="Web Dialer" />
            <Tab icon={<DashboardIcon />} label="Dashboard" />
            <Tab icon={<PhoneForwardedIcon />} label="Forwarded Phone Number" />
            <Tab icon={<DialerSipIcon />} label="SIP device" />
          </Tabs>
        </div>
        <hr></hr>
        <div className="guide-box mb-3">
        <p>
          {tabVal===0&& "add hyperlink to the app download"  
        }{tabVal===1 && "Use our multi platform web dialer to receive and make calls/sms instantly."}{
          tabVal===2 && "Full suite web dashboard to manage calls, sms, campaigns and users"
        }{tabVal===3 &&<> <div>You will be able to manage call routing from the dashboard to setup forwarding to your external mobile or landline in all supported countries
         </div><div>1. Login to Manage numbers page on dashboard</div>
        <div>2. Click call forwarding</div>
        <div>3. Setup the forwarding</div>
        </>}
      </p>
          {url[tabVal] &&
          <CommonButtons
          onClick={()=> history.push(url[tabVal])}
            style={{ cursor: 'pointer' }}
            content={'Download App'}
            background="blue"
          />}
        </div>
        {/* <div className="d-flex important-box">
                <p><InfoIcon/></p>
                <div>
                    <p className="important-text">Important:</p>
                    <ul>
                      <li><span>Android devices </span> haev restrictions while in battery saver mode that prevent you form receiving incoming calls. <a href="#">Please read</a></li>
                      <li><span>Test call failed?</span><a href="#"> Chat with us</a> or <a href="#">book an onboarding session</a></li>
                    </ul>
                </div> 
            </div> */}
      </div>
      <div
        className="quicksetup-footer footer-button mt-auto"
        style={{ justifyContent: 'space-between' }}
      >
        {props?.btnBack === false ? (
          <span></span>
        ) : (
          <CommonButtons
            content="Back"
            btnClass="btn-modal-style btn-back"
            onClick={props.prev}
          />
        )}
        <CommonButtons
          type="button"
          content="Continue"
          background="blue"
          onClick={props.next}
          //   loading={loading}
        />
      </div>
    </>
  )
}
