import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Input, Modal, TextArea, Tab } from 'semantic-ui-react'
import moment from 'moment'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import AllTab from './ActivityTab/AllTab';
import EmailTab from './ActivityTab/EmailTab';
import PhoneTab from './ActivityTab/PhoneTab';
import SmsTab from './ActivityTab/SmsTab';
import NoteTab from './ActivityTab/NoteTab';
import MineTab from './ActivityTab/MineTab';
import { CommonNotify } from '../common/CommonNotify'



import {    
  _createActivity,
  _fetchActivity, 
  _deleteActivity,
  _updateActivity,
  _fetchActivityAuto
  
} from '../config/leadAPI'



const options = [
  {
    id: 0,
    text: 'All',
    value: 0
  },
  {
    id: 1,
    text: 'Call',
    value: 'CALL'
  },
  {
    id: 2,
    text: 'Note',
    value: 'NOTE'
  },
  {
    id: 3,
    text: 'Score',
    value: 'SCORE'
  },
  {
    id: 4,
    text: 'Assign',
    value: 'ASSIGN'
  },
  {
    id: 5,
    text: 'Sms',
    value: 'SMS'
  },
  {
    id: 6,
    text: 'Tag',
    value: 'TAG'
  },
  {
    id: 7,
    text: 'Mention',
    value: 'MENTION'
  }
]


const activity = [
  {
    key: 'email',
   text: 'Email',
   value: 'email',
  },
  {
    key: 'phone',
   text: 'Phone',
   value: 'phone',
  },
  {
    key: 'sms',
   text: 'SMS',
   value: 'sms',
  },
]

const activitiesType = [
  {
    id: 0,
    text: 'Phone Call',
    value: 'Phone Call'
  },
  {
    id: 1,
    text: 'Message',
    value: 'Message'
  },
  {
    id: 2,
    text: 'Meeting',
    value: 'Meeting'
  },
  {
    id: 3,
    text: 'Note',
    value: 'Note'
  },
]

const LeadActivitiesTab = ({ datas, leadId, reloadActivity, activityUpdated,  addActivity, showAddActivity, showActivityUpdateNew, closeAddActivity, showActivityType, showActivityTitle , addActivityBtn , logANoteBtn }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [selectedType, setSelectedType] = useState("email");
  const [filter, setFilter] = useState(datas)
  const [leadActivity, setLeadActivity] = useState([])
  const [leadActivityFilter, setLeadActivityFilter] = useState([])
  const [currentType, setCurrentType] = useState('all')
  const [type, setType] = useState('NOTE')
  const [actId, setActId] = useState(0)

  const [modalOpen, setModalOpen] = useState(false)
  const [activityType, setActivityType] = useState("Choose Activity Type")
  const [updateMode, setUpdateMode] = useState(false)


  const [startDate, setStartDate] = useState(new Date());
  const [summary, setSummary] = useState("");
  const [title, setTitle] = useState("");

  
  
  useEffect(() => {      
    fetchAllActivity()   
  }, [activityUpdated])

  useEffect(() => {     
    if(!leadActivity.length) return;
    filterAllActivity()
  }, [currentType, leadActivity])  

  
  const panes = (actData, activitiesColor, FetchLead, onActivityDelete) => {
      return [
        {
          menuItem: 'Activities',
          render: () => <Tab.Pane  attached={false}><AllTab  addActivityBtn={addActivityBtn}
          logANoteBtn={logANoteBtn} onActivityDelete={onActivityDelete} activity={actData} FetchLead={FetchLead} color={activitiesColor} /></Tab.Pane>,
      },
      {
        menuItem: 'Calls',
        render: () => <Tab.Pane attached={false}><PhoneTab onActivityDelete={onActivityDelete} activity={actData} FetchLead={FetchLead} color={activitiesColor}/></Tab.Pane>,
      },
      {
          menuItem: 'Emails',
          render: () => <Tab.Pane attached={false}><EmailTab onActivityDelete={onActivityDelete} activity={actData} FetchLead={FetchLead} color={activitiesColor}/></Tab.Pane>,
      },
      {
        menuItem: 'SMS',
        render: () => <Tab.Pane attached={false}><SmsTab onActivityDelete={onActivityDelete} activity={actData} FetchLead={FetchLead} color={activitiesColor}/></Tab.Pane>,
      },
      {
        menuItem: 'Logs',
        render: () => <Tab.Pane attached={false}><NoteTab onActivityDelete={onActivityDelete} activity={actData} FetchLead={FetchLead}  color={activitiesColor}/></Tab.Pane>,
      },
      {
        menuItem: 'My Updates',
        render: () => <Tab.Pane attached={false}><MineTab onActivityDelete={onActivityDelete} activity={actData} FetchLead={FetchLead} color={activitiesColor}/></Tab.Pane>,
     }
    ]
 } 

 


  const filterAllActivity = () => {
     if(currentType === 'all') {
       setLeadActivityFilter(leadActivity)
       return;
     }     

     const leads = leadActivity

     if(currentType === 'mine'){
      const filteredList = leads.filter( item => {
        if(item.auto)
          return item
      });
      setLeadActivityFilter(filteredList)
      return;
     }

     let filterText = currentType.toLowerCase()

     if(filterText == 'sms'){
      filterText = 'message'
     }else if(filterText == 'phone'){
      filterText = 'CALL'
     }



     const filteredList = leads.filter( item => {
       if(item.type.toLowerCase() == filterText)
         return item
     });
     setLeadActivityFilter(filteredList)
  }
  
    const fetchAllActivity = () => {
       _fetchActivity(leadId).then( res => { 

           const act1 = res.map( item => {
             return {
               ...item, auto : false, date: item.due_date
             }

            })

             _fetchActivityAuto(leadId).then( res => { 

              const act2 = res.map( item => {
                return {
                  ...item, auto : true, date: item.action.date
                }
              } ) 

              let allActivity = [...act1, ...act2];

              allActivity = allActivity.sort( (a,b) => {
                return new Date(b.date) - new Date(a.date)
              } )


              // sort here also neeed common date key

              setLeadActivity(allActivity) 
  
            }).catch( err => {
              setLeadActivity(act1) 
            } )        

          

         

          

          // fetch auto activity and append lead activity
                                      
          // sort entire array into date
               
          //create separate component for manual & auto activity ( auto : true / false )


       }).catch( err => {
          
       });
    }

  const activitiesColor = type => {
    var colorClass = ''
    var icon = ''

    switch (type) {
      case 'CALL':
        icon = 'fa fa-phone'
        colorClass = 'item-blue'
        break
      case 'Incoming Call':
          icon = 'fa fa-phone'
          colorClass = 'item-red'
          break
      case 'Outgoing Call':
            icon = 'fa fa-phone'
            colorClass = 'item-green'
            break

      
      case 'SCORE':
        icon = 'fa fa-thumbs-up'
        colorClass = 'item-warning'
        break
      case 'Assigned':
        icon = 'fa fa-globe'
        colorClass = 'item-red'
        break
      case 'NOTE':
        colorClass = 'item-green'
        icon = 'fas fa-edit'
        break
        case 'Lead Stage Updated':
          colorClass = 'item-mention'
          icon = 'fas fa-edit'
          break
        case 'Lead Score Updated':
            colorClass = 'item-tag'
            icon = 'fas fa-star'
            break

            case 'Access Shared':
              colorClass = 'item-mention'
              icon = 'fas fa-user'
              break 

              case 'Owner Changed':
                colorClass = 'item-tag'
                icon = 'fas fa-user'
                break 
      
      case 'SMS':
        icon = 'fa fa-envelope'
        colorClass = 'item-sms'
        break
        case 'EMAIL':
          icon = 'fa fa-envelope'
          colorClass = 'item-blue'
          break
      case 'TAG':
        colorClass = 'item-tag'
        icon = 'fa fa-quote-left'
        break
      case 'MENTION':
        colorClass = 'item-mention'
        icon = 'fa fa-share-alt'
        break
      default:
        colorClass = 'item-mention'
        icon = 'fa fa-star'    
        break
    }
    return { colorClass, icon }
  }

  const onActivityChange = (e, data) => {
    if (!data.value) return setFilter(datas)
    const filterData = datas.filter(obj => {
      return obj.type === data.value
    })
    setFilter(filterData)
  }

  const taskDate = (date) => {
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

if (month.length < 2) 
    month = '0' + month;
if (day.length < 2) 
    day = '0' + day;

return [year, month, day].join('-');
}


   const getActtype = type => {
     return type == 'PHONE' ?  'CALL' :  type == 'SMS' ? 'MESSAGE' :   showActivityType.toUpperCase()
   }

   const onActivityUpdate = async() => {
    const user_id = await localStorage.getItem('id')

    if(title == '' || startDate == '' || summary == ''){
      CommonNotify("All fields are required")
      return;
    }

    let fDate = taskDate(startDate)
   
   
    const leadType = getActtype(showActivityType.toUpperCase());
  
       const payload = {           
         type: leadType,
         summary: summary,
         lead_id: leadId,      
         desktop_reminder:1,
         title: title, 
         due_date: fDate + " 00:00"
       }
       payload[showActivityType] = title
  
  
       _updateActivity(payload, actId).then( data => { 
         closeAddActivity()
         CommonNotify("Lead activity saved", 'success')
         setUpdateMode(false);  
         reloadActivity()
       }).catch( err => {
            if( err.response ){
              setUpdateMode(false);
              closeAddActivity()
              CommonNotify(err.response.data?.errors[0])
          }     
       }); 


   }



   const onActivityDelete = async(actId) => {
       
  
  
       _deleteActivity(actId).then( data => {     
   
       
         CommonNotify("Lead activity deleted", 'success')
        
  
         reloadActivity()
       }).catch( err => {
            if( err.response ){
              setUpdateMode(false);
              closeAddActivity()
              CommonNotify(err.response.data?.errors[0])
          }   
         
       }); 


   }




  const onActivityAdd = async() => {

    if(updateMode){
      onActivityUpdate()
      return;
    }

    const user_id = await localStorage.getItem('id')

    if(title == '' || startDate == '' || summary == ''){
      CommonNotify("All fields are required")
      return;
    }

    
    let fDate = taskDate(startDate)
   
   
  let leadType = getActtype(showActivityType.toUpperCase());

  leadType =  leadType == 'CUSTOM' ? selectedType  : leadType

    if(leadType.toLowerCase() == 'sms' ){
      leadType = 'message'
    }

    if(leadType.toLowerCase() == 'phone' ){
      leadType = 'call'
    }

     const payload = {           
       type: leadType.toUpperCase(),
       summary: summary,
       lead_id: leadId,      
       desktop_reminder:1,
       title: title, 
       due_date: fDate + " 00:00"
     }
     payload[showActivityType] = title


     addActivity(payload).then( data => {

       setSummary('');
       setTitle('');
       setStartDate('');



      closeAddActivity()

       CommonNotify("Lead activity saved", 'success')

       fetchAllActivity()
     }).catch( err => {
          if( err.response ){

            closeAddActivity()
            if(err.response.data?.errors?.length){
              CommonNotify(err.response.data?.errors[0])
            }else{             
                CommonNotify("Server error")          
            }           
        }       
     });

  }

  useEffect(() => {
    setFilter(datas)
  }, [datas])

  const changeTab = ( e, {activeIndex}) => {
    const tabs = ['all', 'email', 'phone', 'sms', 'note', 'mine']

    setActiveIndex(activeIndex)
    setCurrentType(tabs[activeIndex])
  }

  const FetchLead = activity => {
    const { title, due_date,  type, summary, actId} = activity 
    setSummary(summary)
    setTitle(title)
    setType(type)
    setActId(actId)
    setStartDate(due_date)      
    setUpdateMode(true)
    showActivityUpdateNew()
  }

 
  return (
    <>
      <div className="activities-wrapper">          
        <Tab menu={{ secondary: true, className: "wrapped" }}  activeIndex={activeIndex} onTabChange={changeTab} panes={panes(leadActivityFilter, activitiesColor, FetchLead, onActivityDelete)} />
      </div>
      <Modal
        open={showAddActivity}
        onClose={closeAddActivity}
        style={{ zIndex: '1500 !important'}}
            >
              <Modal.Header>{ showActivityTitle }</Modal.Header>
              <Modal.Content style={{padding: '50px', paddingBottom: '100px'}}>
                  
                  <div style={{ display: 'block', width: '100%', marginBottom: '20px', marginTop: '20px' }}>
                    <SemanticDatepicker minDate={new Date()} placeholder="Enter activity date" style={{ width:'100%' }} showToday={true} format="YYYY-MM-DD" onChange={(event, data) => setStartDate(data.value)} />
                    <br/><br/>
                    <Input placeholder="Enter activity title" style={{ width:'100%' }} onChange={ e => setTitle(e.target.value) } value={title} ></Input>
                    <br/><br/>
                    { showActivityType == 'custom' && (
                        <Dropdown                           
                        placeholder='Select activity type'                    
                        onChange={ (e, { value }) => setSelectedType(value)}
                        options={activity}
                        selection
                       
                        value={selectedType}
                      />
                    ) }
                   

                  </div> 
                  
                  <TextArea  onChange={ e => setSummary(e.target.value) } value={summary}  placeholder='Start typing to leave a note' />
              </Modal.Content>
              <Modal.Actions>
                  <Button primary  onClick={() => onActivityAdd()}>
                      save
                  </Button>
                  <Button onClick={closeAddActivity}>
                      cancel
                  </Button>
              </Modal.Actions>
      </Modal>
    </>
  )
}

export default LeadActivitiesTab
