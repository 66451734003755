const CountryList = [
  {
    name: 'Argentina',
    regions: []
  },
  {
    name: 'Australia',
    regions: []
  },
  {
    name: 'Austria',
    regions: []
  },
  {
    name: 'Belgium',
    regions: []
  },
  { name: 'Brazil', regions: [] },
  {
    name: 'Bulgaria',
    regions: []
  },

  {
    name: 'Canada',
    regions: [
      {
        name: 'Alberta',
        code: 'AB'
      },
      {
        name: 'British Columbia',
        code: 'BC'
      },
      {
        name: 'Manitoba',
        code: 'MB'
      },
      {
        name: 'New Brunswick',
        code: 'NB'
      },
      {
        name: 'Newfoundland and Labrador',
        code: 'NL'
      },
      {
        name: 'Northwest Territories',
        code: 'NT'
      },
      {
        name: 'Nova Scotia',
        code: 'NS'
      },
      {
        name: 'Nunavut',
        code: 'NU'
      },
      {
        name: 'Ontario',
        code: 'ON'
      },
      {
        name: 'Prince Edward Island',
        code: 'PE'
      },
      {
        name: 'Quebec',
        code: 'QC'
      },
      {
        name: 'Saskatchewan',
        code: 'SK'
      },
      {
        name: 'Yukon',
        code: 'YT'
      }
    ]
  },
  {
    name: 'Colombia',
    regions: []
  },
  {
    name: 'Czech republic',
    regions: []
  },
  {
    name: 'Denmark',
    regions: []
  },
  {
    name: 'Finland',
    regions: []
  },
  { name: 'Hong Kong', regions: [] },
  { name: 'Hungary', regions: [] },
  { name: 'Indonesia', regions: [] },
  { name: 'Ireland', regions: [] },
  { name: 'Israel', regions: [] },
  { name: 'Japan', regions: [] },
  { name: 'Malaysia', regions: [] },
  { name: 'Mexico', regions: [] },
  {
    name: 'New Zealand',
    regions: []
  },
  { name: 'Philippines', regions: [] },
  { name: 'Romania', regions: [] },
  { name: 'Slovakia', regions: [] },
  { name: 'South Africa', regions: [] },
  { name: 'Thailand', regions: [] },

  {
    name: 'Uganda',
    regions: []
  },
  {
    name: 'United Kingdom',
    regions: [
      {
        name: 'Any',
        code: 'Any'
      },
      { name: 'London', code: 'London' },
      { name: 'Birmingham', code: 'Birmingham' },
      { name: 'Manchester', code: 'Manchester' },
      { name: 'Glasgow', code: 'Glasgow' },
      { name: 'Edinburgh', code: 'Edinburgh' },
      { name: 'Liverpool', code: 'Liverpool' },
      { name: 'Leeds', code: 'Leeds' },
      { name: 'Sheffield', code: 'Sheffield' },
      { name: 'Bristol', code: 'Bristol' },
      { name: 'Newcastle', code: 'Newcastle' },
      { name: 'Southampton', code: 'Southampton' },
      { name: 'Nottingham', code: 'Nottingham' },
      { name: 'Leicester', code: 'Leicester' },
      { name: 'Coventry', code: 'Coventry' },
      { name: 'Hull', code: 'HullHull' },
      { name: 'Cardiff', code: 'Cardiff' },
      { name: 'Belfast', code: 'Belfast' },
      { name: 'Stoke-on-Trent', code: 'Stoke-on-Trent' },
      { name: 'Wolverhampton', code: 'Wolverhampton' },
      { name: 'Derby', code: 'Derby' },
      { name: 'Swansea', code: 'Swansea' },
      { name: 'Bradford', code: 'Bradford' },
      { name: 'Reading', code: 'Reading' },
      { name: 'Kingston upon Hull', code: 'Kingston upon Hull' },
      { name: 'Plymouth', code: 'Plymouth' },
      { name: 'Luton', code: 'Luton' },
      { name: 'Warrington', code: 'Warrington' },
      { name: 'York', code: 'York' },
      { name: 'Newport', code: 'Newport' },
      { name: 'Preston', code: 'Preston' },
      { name: 'Aberdeen', code: 'Aberdeen' },
      { name: 'Dundee', code: 'Dundee' },
      { name: 'Milton Keynes', code: 'Milton Keynes' },
      { name: 'Sunderland', code: 'Sunderland' },
      { name: 'Norwich', code: 'Norwich' },
      { name: 'Woking', code: 'Woking' }
    ]
  },
  {
    name: 'United States',
    regions: [
      {
        name: 'Any',
        code: 'Any'
      },
      {
        name: 'Alabama',
        code: 'AL'
      },
      {
        name: 'Alaska',
        code: 'AK'
      },
      {
        name: 'Arizona',
        code: 'AZ'
      },
      {
        name: 'Arkansas',
        code: 'AR'
      },
      {
        name: 'California',
        code: 'CA'
      },
      {
        name: 'Colorado',
        code: 'CO'
      },
      {
        name: 'Connecticut',
        code: 'CT'
      },
      {
        name: 'Delaware',
        code: 'DE'
      },
      {
        name: 'District of Columbia',
        code: 'DC'
      },
      {
        name: 'Florida',
        code: 'FL'
      },
      {
        name: 'Georgia',
        code: 'GA'
      },
      {
        name: 'Hawaii',
        code: 'HI'
      },
      {
        name: 'Idaho',
        code: 'ID'
      },
      {
        name: 'Illinois',
        code: 'IL'
      },
      {
        name: 'Iowa',
        code: 'IA'
      },
      {
        name: 'Kansas',
        code: 'KS'
      },
      {
        name: 'Kentucky',
        code: 'KY'
      },
      {
        name: 'Louisiana',
        code: 'LA'
      },
      {
        name: 'Maine',
        code: 'ME'
      },
      {
        name: 'Maryland',
        code: 'MD'
      },
      {
        name: 'Massachusetts',
        code: 'MA'
      },
      {
        name: 'Michigan',
        code: 'MI'
      },
      {
        name: 'Minnesota',
        code: 'MN'
      },
      {
        name: 'Mississippi',
        code: 'MS'
      },
      {
        name: 'Missouri',
        code: 'MO'
      },
      {
        name: 'Montana',
        code: 'MT'
      },
      {
        name: 'Nebraska',
        code: 'NE'
      },
      {
        name: 'Nevada',
        code: 'NV'
      },
      {
        name: 'New Hampshire',
        code: 'NH'
      },
      {
        name: 'New Jersey',
        code: 'NJ'
      },
      {
        name: 'New Mexico',
        code: 'NM'
      },
      {
        name: 'New York',
        code: 'NY'
      },
      {
        name: 'North Carolina',
        code: 'NC'
      },
      {
        name: 'North Dakota',
        code: 'ND'
      },
      {
        name: 'Ohio',
        code: 'OH'
      },
      {
        name: 'Oklahoma',
        code: 'OK'
      },
      {
        name: 'Oregon',
        code: 'OR'
      },
      {
        name: 'Pennsylvania',
        code: 'PA'
      },
      {
        name: 'Rhode Island',
        code: 'RI'
      },
      {
        name: 'South Carolina',
        code: 'SC'
      },
      {
        name: 'South Dakota',
        code: 'SD'
      },
      {
        name: 'Tennessee',
        code: 'TN'
      },
      {
        name: 'Texas',
        code: 'TX'
      },
      {
        name: 'Utah',
        code: 'UT'
      },
      {
        name: 'Vermont',
        code: 'VT'
      },
      {
        name: 'Virginia',
        code: 'VA'
      },
      {
        name: 'Washington',
        code: 'WA'
      },
      {
        name: 'West Virginia',
        code: 'WV'
      },
      {
        name: 'Wisconsin',
        code: 'WI'
      },
      {
        name: 'Wyoming',
        code: 'WY'
      },
      {
        name: 'Puerto Rico',
        code: 'PR'
      }
    ],
    areaCodes: [
      {
        name: 'Any',
        code: 'Any'
      },
      { name: 907, code: 907 },
      { name: 205, code: 205 },
      { name: 251, code: 251 },
      { name: 256, code: 256 },
      { name: 334, code: 334 },
      { name: 479, code: 479 },
      { name: 501, code: 501 },
      { name: 870, code: 870 },
      { name: 480, code: 480 },
      { name: 520, code: 520 },
      { name: 602, code: 602 },
      { name: 623, code: 623 },
      { name: 928, code: 928 },
      { name: 209, code: 209 },
      { name: 213, code: 213 },
      { name: 310, code: 310 },
      { name: 323, code: 323 },
      { name: 408, code: 408 },
      { name: 415, code: 415 },
      { name: 510, code: 510 },
      { name: 530, code: 530 },
      { name: 559, code: 559 },
      { name: 562, code: 562 },
      { name: 619, code: 619 },
      { name: 626, code: 626 },
      { name: 650, code: 650 },
      { name: 661, code: 661 },
      { name: 707, code: 707 },
      { name: 714, code: 714 },
      { name: 760, code: 760 },
      { name: 805, code: 805 },
      { name: 818, code: 818 },
      { name: 831, code: 831 },
      { name: 858, code: 858 },
      { name: 909, code: 909 },
      { name: 916, code: 916 },
      { name: 925, code: 925 },
      { name: 949, code: 949 },
      { name: 951, code: 951 },
      { name: 303, code: 303 },
      { name: 719, code: 719 },
      { name: 970, code: 970 },
      { name: 203, code: 203 },
      { name: 860, code: 860 },
      { name: 202, code: 202 },
      { name: 302, code: 302 },
      { name: 239, code: 239 },
      { name: 305, code: 305 },
      { name: 321, code: 321 },
      { name: 352, code: 352 },
      { name: 386, code: 386 },
      { name: 407, code: 407 },
      { name: 561, code: 561 },
      { name: 727, code: 727 },
      { name: 772, code: 772 },
      { name: 813, code: 813 },
      { name: 850, code: 850 },
      { name: 863, code: 863 },
      { name: 904, code: 904 },
      { name: 941, code: 941 },
      { name: 954, code: 954 },
      { name: 229, code: 229 },
      { name: 404, code: 404 },
      { name: 478, code: 478 },
      { name: 706, code: 706 },
      { name: 770, code: 770 },
      { name: 912, code: 912 },
      { name: 808, code: 808 },
      { name: 319, code: 319 },
      { name: 515, code: 515 },
      { name: 563, code: 563 },
      { name: 641, code: 641 },
      { name: 712, code: 712 },
      { name: 208, code: 208 },
      { name: 217, code: 217 },
      { name: 309, code: 309 },
      { name: 312, code: 312 },
      { name: 618, code: 618 },
      { name: 630, code: 630 },
      { name: 708, code: 708 },
      { name: 773, code: 773 },
      { name: 815, code: 815 },
      { name: 847, code: 847 },
      { name: 219, code: 219 },
      { name: 260, code: 260 },
      { name: 317, code: 317 },
      { name: 574, code: 574 },
      { name: 765, code: 765 },
      { name: 812, code: 812 },
      { name: 316, code: 316 },
      { name: 620, code: 620 },
      { name: 785, code: 785 },
      { name: 913, code: 913 },
      { name: 270, code: 270 },
      { name: 502, code: 502 },
      { name: 606, code: 606 },
      { name: 859, code: 859 },
      { name: 225, code: 225 },
      { name: 318, code: 318 },
      { name: 337, code: 337 },
      { name: 504, code: 504 },
      { name: 985, code: 985 },
      { name: 413, code: 413 },
      { name: 508, code: 508 },
      { name: 617, code: 617 },
      { name: 781, code: 781 },
      { name: 978, code: 978 },
      { name: 301, code: 301 },
      { name: 410, code: 410 },
      { name: 207, code: 207 },
      { name: 231, code: 231 },
      { name: 248, code: 248 },
      { name: 269, code: 269 },
      { name: 313, code: 313 },
      { name: 517, code: 517 },
      { name: 586, code: 586 },
      { name: 616, code: 616 },
      { name: 734, code: 734 },
      { name: 810, code: 810 },
      { name: 906, code: 906 },
      { name: 989, code: 989 },
      { name: 218, code: 218 },
      { name: 320, code: 320 },
      { name: 507, code: 507 },
      { name: 612, code: 612 },
      { name: 651, code: 651 },
      { name: 763, code: 763 },
      { name: 952, code: 952 },
      { name: 314, code: 314 },
      { name: 417, code: 417 },
      { name: 573, code: 573 },
      { name: 636, code: 636 },
      { name: 660, code: 660 },
      { name: 816, code: 816 },
      { name: 228, code: 228 },
      { name: 601, code: 601 },
      { name: 662, code: 662 },
      { name: 406, code: 406 },
      { name: 252, code: 252 },
      { name: 336, code: 336 },
      { name: 704, code: 704 },
      { name: 828, code: 828 },
      { name: 910, code: 910 },
      { name: 919, code: 919 },
      { name: 701, code: 701 },
      { name: 308, code: 308 },
      { name: 402, code: 402 },
      { name: 603, code: 603 },
      { name: 201, code: 201 },
      { name: 609, code: 609 },
      { name: 732, code: 732 },
      { name: 856, code: 856 },
      { name: 908, code: 908 },
      { name: 973, code: 973 },
      { name: 505, code: 505 },
      { name: 575, code: 575 },
      { name: 702, code: 702 },
      { name: 775, code: 775 },
      { name: 212, code: 212 },
      { name: 315, code: 315 },
      { name: 516, code: 516 },
      { name: 518, code: 518 },
      { name: 585, code: 585 },
      { name: 607, code: 607 },
      { name: 631, code: 631 },
      { name: 716, code: 716 },
      { name: 718, code: 718 },
      { name: 845, code: 845 },
      { name: 914, code: 914 },
      { name: 216, code: 216 },
      { name: 330, code: 330 },
      { name: 419, code: 419 },
      { name: 440, code: 440 },
      { name: 513, code: 513 },
      { name: 614, code: 614 },
      { name: 740, code: 740 },
      { name: 937, code: 937 },
      { name: 405, code: 405 },
      { name: 580, code: 580 },
      { name: 918, code: 918 },
      { name: 503, code: 503 },
      { name: 541, code: 541 },
      { name: 215, code: 215 },
      { name: 412, code: 412 },
      { name: 570, code: 570 },
      { name: 610, code: 610 },
      { name: 717, code: 717 },
      { name: 724, code: 724 },
      { name: 814, code: 814 },
      { name: 401, code: 401 },
      { name: 803, code: 803 },
      { name: 843, code: 843 },
      { name: 864, code: 864 },
      { name: 605, code: 605 },
      { name: 423, code: 423 },
      { name: 615, code: 615 },
      { name: 731, code: 731 },
      { name: 865, code: 865 },
      { name: 901, code: 901 },
      { name: 931, code: 931 },
      { name: 210, code: 210 },
      { name: 214, code: 214 },
      { name: 254, code: 254 },
      { name: 281, code: 281 },
      { name: 325, code: 325 },
      { name: 361, code: 361 },
      { name: 409, code: 409 },
      { name: 432, code: 432 },
      { name: 512, code: 512 },
      { name: 713, code: 713 },
      { name: 806, code: 806 },
      { name: 817, code: 817 },
      { name: 830, code: 830 },
      { name: 903, code: 903 },
      { name: 915, code: 915 },
      { name: 936, code: 936 },
      { name: 940, code: 940 },
      { name: 956, code: 956 },
      { name: 972, code: 972 },
      { name: 979, code: 979 },
      { name: 435, code: 435 },
      { name: 801, code: 801 },
      { name: 276, code: 276 },
      { name: 434, code: 434 },
      { name: 540, code: 540 },
      { name: 703, code: 703 },
      { name: 757, code: 757 },
      { name: 804, code: 804 },
      { name: 802, code: 802 },
      { name: 206, code: 206 },
      { name: 253, code: 253 },
      { name: 360, code: 360 },
      { name: 425, code: 425 },
      { name: 509, code: 509 },
      { name: 262, code: 262 },
      { name: 414, code: 414 },
      { name: 608, code: 608 },
      { name: 715, code: 715 },
      { name: 920, code: 920 },
      { name: 304, code: 304 },
      { name: 307, code: 307 }
    ]
  }
]
export default CountryList
