import React, { useState, useEffect } from 'react'
import CardBox from '../../common/CardBox'
import axios from 'axios'
import moment from 'moment'

const cardboxData = [
  {
    type: 'flatRate',
    title: 'Avg. Lead Response Time',
    callLog: '00',
    callSup: 's'
  },
  {
    type: 'flatRate',
    title: 'AGENT ANSWER RATE',
    callLog: '00%'
  },
  {
    type: 'flatRate',
    title: 'CUSTOMER ANSWER RATE',
    callLog: '00%'
  },
  {
    type: 'flatRate',
    title: 'Avg Call Duration',
    callLog: '00',
    callSup: 's'
  }
]
const leadStatusData = [
  {
    type: 'flatRate',
    title: 'PENDING',
    callLog: '00',
    callSup: 's'
  },
  {
    type: 'flatRate',
    title: 'ASSIGNED',
    callLog: '00%'
  },
  {
    type: 'flatRate',
    title: 'TOTAL',
    callLog: '00%'
  },
  {
    type: 'flatRate',
    title: 'CLOSED',
    callLog: '00',
    callSup: 's'
  }
]

const toplead = {
  type: 'topLead',
  title: 'Top Lead Generating Pages'
}

const usage = [
  {
    type: 'usage',
    title: 'Calls',
    usageLog: '0'
    //usageDescription: 'in this subscription period'
  },
  {
    type: 'usage',
    title: 'Message',
    usageLog: '0'
    //usageDescription: 'in this subscription period'
  },
  {
    type: 'usage',
    title: 'Upcomming Call',
    usageLog: '0'
    //usageDescription: 'in this subscription period'
  }
]

const ActivityBox = ({ date, loading, type, filterDate }) => {
  const [boxData, setBoxData] = useState([])
  const [leadStatus, setLeadStatus] = useState()

  const startDateFilter = moment(filterDate?.startDateFilter).format(
    'YYYY-MM-DD'
  )
  const endDateFilter = moment(filterDate?.endDateFilter).format('YYYY-MM-DD')

  const fetchLeadData = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`
    var par = {
      page: 1,
      sort: '-id'
    }
    par[`filter[created_at][between]`] = `${startDateFilter}/${endDateFilter}`
    await axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: par
      })
      .then(res => {
        setLeadStatus(res?.data?.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (type === 'Performance') {
      setBoxData(cardboxData)
    } else {
      setBoxData(leadStatusData)
      fetchLeadData()
    }
  }, [type, filterDate])

  useEffect(() => {
    if (date) {
      if (type === 'Performance') {
        const updated =
          cardboxData &&
          cardboxData.length &&
          cardboxData.map(item => {
            // eslint-disable-next-line default-case
            switch (item.title) {
              case 'Avg. Lead Response Time':
                return {
                  ...item,
                  callLog: Number.isInteger(date.lead_response_time)
                    ? date.lead_response_time
                    : date.lead_response_time.toFixed(2)
                }
              case 'AGENT ANSWER RATE':
                return {
                  ...item,
                  callLog: Number.isInteger(date.agent_answer_rate)
                    ? date.agent_answer_rate
                    : date.agent_answer_rate.toFixed(2)
                }
              case 'CUSTOMER ANSWER RATE':
                return {
                  ...item,
                  callLog: Number.isInteger(date.customer_answer_rate)
                    ? date.customer_answer_rate
                    : date.customer_answer_rate.toFixed(2)
                }
              case 'Avg Call Duration':
                return { ...item, callLog: date.average_duration }
            }
          })
        setBoxData(updated)
      } else {
        const updated =
          leadStatusData &&
          leadStatusData.length &&
          leadStatusData.map(item => {
            // eslint-disable-next-line default-case
            switch (item.title) {
              case 'PENDING':
                return { ...item, callLog: leadStatus?.totalPendingLeads ?? 0 }
              case 'ASSIGNED':
                return { ...item, callLog: leadStatus?.totalAgentLeads ?? 0 }
              case 'TOTAL':
                return {
                  ...item,
                  callLog: leadStatus?.totalLeads ?? 0
                }
              case 'CLOSED':
                return { ...item, callLog: leadStatus?.totalClosedLeads ?? 0 }
            }
          })
        setBoxData(updated)
      }
    }
  }, [date, type, leadStatus])
  return (
    <div>
      <div className="cardbox-wrapper w-100">
        {date
          ? boxData &&
            boxData.length &&
            boxData?.map((item, i) => {
              return <CardBox key={i} cardContent={item} type={type} />
            })
          : null}
      </div>{' '}
    </div>
  )
}

export default ActivityBox
