import React, { useEffect, useState } from 'react'
import { CommonNotify } from '../../common/CommonNotify'
import Axios from 'axios'
import {
    Button,
    Dimmer,
    Dropdown,
    Input,
    Loader,
    Modal,
    Table,
    TextArea
} from 'semantic-ui-react'
import moment from 'moment'
import { Tooltip } from '@mui/material'
import { Col, Row } from 'reactstrap'
import CommonButtons from '../../common/CommonButtons'
import CommonInput from '../../common/CommonInput'
import PhoneInput from 'react-phone-input-2'
import PublishIcon from '@mui/icons-material/Publish';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { billingCountryList } from '../../helpers/billingCountryList'
import { connect } from 'react-redux'

const NewKycDetail = ({ profile }) => {
    const [kycList, setKycList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [showTemplate, setShowTemplate] = useState(false)
    const [availableTemplateList, setAvailableTemplateList] = useState([])
    const [availableTemplateListAll, setAvailableTemplateListAll] = useState([])
    const [loading, setLoading] = useState(false)
    const [newTemplates, setNewTemplates] = useState(false)
    const [intialState, setintialState] = useState({ end_user_type: 'individual' })
    const [selectedKYC, setSelectedKYC] = useState(intialState)
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
    const [searchValue, setSarchValue] = useState(null)
    const [personalise, setPersonalise] = useState(false)

    useEffect(() => {
        console.log('profile', profile)
        if (profile) {
            setintialState((prev) => ({ ...prev, first_name: profile?.first_name, last_name: profile?.last_name }))
        }
    }, [profile])

    useEffect(() => {

        setSelectedKYC({ ...intialState })
    }, [intialState])

    console.log('selectedKYC', selectedKYC)
    const handleDeleteTemplate = async () => {
        setOpenConfirmationModal(false)
        if (selectedKYC?.id) {
            setLoading(true)
            const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/kyc/${selectedKYC?.id}`
            await Axios.delete(url)
                .then(res => {
                    getKyc()
                    setSelectedKYC({ ...intialState })
                    CommonNotify('Deleted successfully')
                })
                .catch(err => {
                    setLoading(false)
                    CommonNotify(
                        err?.response?.data?.errors[0] || 'Somethign went to wron!1'
                    )
                })
        } else {
            CommonNotify('Please select any template first')
        }
    }

    useEffect(() => {
        if (newTemplates) {
            setSelectedKYC({ ...intialState })
        }
    }, [newTemplates])



    const handleChange = (name, value) => {
        console.log('name, value', name, value)
        setSelectedKYC((state) => ({
            ...state,
            [name]: value
        }))
    }

    const getKyc = async id => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/kyc`
        await Axios.get(url)
            .then(res => {
                setIsLoading(false)
                if (res?.data?.data && res?.data?.data?.length) {
                    let filterValue = []

                    setKycList(res?.data?.data || [])
                } else {
                    setKycList([])
                }
            })
            .catch(err => {
                setIsLoading(false)
                CommonNotify(
                    err?.response?.data?.errors[0] || 'Somethign went to wrong!4'
                )
            })
    }

    useEffect(() => {
        getKyc()
    }, [])

    const handleSaveKYC = async () => {
        const data = new FormData()
        data.append('number_type', 'local')
        const dd = await Object?.entries(selectedKYC)?.map(([key, value]) => {
            if (value) {

                data.append(key, value)
            }
        })
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/kyc${selectedKYC?.id ? `/${selectedKYC?.id}` : ''
            }`

        await Axios.post(url, data)
            .then(res => {
                CommonNotify(
                    selectedKYC?.id ? 'Updated successfully' : 'Created successfully'
                )
                setShowTemplate(!showTemplate)
                // setNewTemplates(false)
                setSelectedKYC({ ...intialState })
                getKyc()
            })
            .catch(err => {
                setLoading(false)
                CommonNotify(err?.response?.data?.errors[0] || 'dfwedwf')
            })
    }

    const handleSeletedKYC = list => {
        const {
            country,
            end_user_type,
            id,
            number_type,
            purpose,
            user_email,
            doc_info,
            business_name
        } = list
        const {
            id_info,
            passport_info,
            commercial_register_info,
            government_issued_info
        } = doc_info || {}
        const { first_name_on_id, last_name_on_id, id_file } = id_info || {}
        const { first_name_on_passport, last_name_on_passport, passport_file } =
            passport_info || {}
        const { commercial_register_file, commercial_register_business_name } =
            commercial_register_info || {}
        const {
            government_issued_address_friendly_name,
            government_issued_address1,
            government_issued_address2,
            government_issued_postal_code,
            government_issued_country,
            government_issued_city,
            government_issued_state,
            government_issued_customer_name,
            government_issued_id_file
        } = government_issued_info || {}
        setSelectedKYC({
            ...selectedKYC,
            country,
            end_user_type,
            id,
            number_type,
            purpose,
            user_email: user_email,

            first_name_on_id,
            last_name_on_id,
            id_image: id_file,

            first_name_on_passport,
            last_name_on_passport,
            passport: passport_file,
            business_name: business_name,
            commercial_register: commercial_register_file,
            commercial_register_business_name,
            government_issued_address_friendly_name,
            government_issued_address1,
            government_issued_address2,
            government_issued_postal_code,
            government_issued_country,
            government_issued_city,
            government_issued_state,
            government_issued_customer_name,
            government_issued_id: government_issued_id_file
        })
    }

    return (
      <div
        className="history-campaign"
        style={{ maxWidth: '1200px', margin: '0 auto' }}
      >
        <Dimmer style={{ position: 'fixed' }}>
          <Loader />
        </Dimmer>
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3 className="mb-4"></h3>
            <Button
              className="mb-4"
              onClick={() => {
                setSelectedKYC({ ...intialState })
                setShowTemplate(!showTemplate)
              }}
            >
              <i class="fas fa-plus"></i> New regulatory bundle
            </Button>
          </div>
          <div>
            <Table className="border-0">
              <Table.Header style={{ padding: '12px 15px' }}>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Created At</Table.HeaderCell>
                  <Table.HeaderCell>Country</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>

                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body style={{ padding: '10px 12px' }}>
                {kycList &&
                  kycList?.length > 0 &&
                  kycList?.map((list, index) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell>
                          {list?.first_name} {list?.last_name}
                        </Table.Cell>
                        <Table.Cell>
                          {moment(list?.created_at)?.format('LT')}
                        </Table.Cell>
                        <Table.Cell>{list?.country}</Table.Cell>
                        <Table.Cell>{list?.status}</Table.Cell>
                        <Table.Cell>{list?.end_user_type}</Table.Cell>
                        <Table.Cell>
                          <Table.Cell>
                            {
                              <div className="d-flex justify-space-between">
                                <Tooltip title="Edit KYC">
                                  <button>
                                    <i
                                      class="fas fa-edit"
                                      style={{ paddingRight: '5px' }}
                                      onClick={() => {
                                        handleSeletedKYC(list)
                                        setShowTemplate(!showTemplate)
                                      }}
                                    ></i>{' '}
                                  </button>
                                </Tooltip>

                                <Tooltip title="Delete KYC">
                                  <button>
                                    <i
                                      class="fas fa-trash"
                                      onClick={() => {
                                        setSelectedKYC(list)
                                        setOpenConfirmationModal(true)
                                      }}
                                      style={{
                                        color: 'red',
                                        paddingRight: '5px'
                                      }}
                                    ></i>
                                  </button>
                                </Tooltip>
                              </div>
                            }
                          </Table.Cell>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
              </Table.Body>
            </Table>
          </div>
        </>
        {showTemplate && (
          <Modal
            style={{ maxWidth: '50%' }}
            className="subscription-modal"
            open={showTemplate}
            onClose={() => setShowTemplate(!showTemplate)}
          >
            <Modal.Header>
              {' '}
              <p className="cancel-title">
                {' '}
                {selectedKYC?.id ? 'Update KYC' : 'Add KYC'}
              </p>
              <i
                onClick={() => setShowTemplate(!showTemplate)}
                className="fa fa-times"
                aria-hidden="true"
              ></i>
            </Modal.Header>
            <Modal.Content scrolling style={{ alignItems: 'normal' }}>
              {' '}
              <div className="template-box-right-wrap kyc-model w-100">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>User Type</label>
                    <Dropdown
                      defaultValue={selectedKYC?.end_user_type || 'individual'}
                      className="form-control kyc-dropdown"
                      options={[
                        {
                          key: 'business',
                          text: `Business`,
                          value: 'business'
                        },
                        {
                          key: 'individual',
                          text: `Individual`,
                          value: 'individual'
                        }
                      ]}
                      placeholder="Select Type"
                      onChange={(e, data) =>
                        handleChange('end_user_type', data?.value)
                      }
                    />
                  </div>
                </div>
                <h4>Your Details</h4>
                <p>Enter your personal details below</p>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>First Name</label>
                    <CommonInput
                      defaultValue={selectedKYC?.first_name_on_id}
                      onChange={e =>
                        handleChange('first_name_on_id', e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Last Name</label>
                    <CommonInput
                      defaultValue={selectedKYC?.last_name_on_id}
                      onChange={e =>
                        handleChange('last_name_on_id', e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="row">
                  {/* <div className="col-md-6 form-group">
                  <label>Phone Number</label>

                  <PhoneInput
                    defaultValue={selectedKYC?.phone_number}
                    onChange={value => handleChange('phone_number', value)}
                    // placeholder="To"
                    // className="text-color"
                    // onlyCountries={allowedCountries}
                    // value={sendMsgData?.to}
                    // onChange={value => setSendMsgData({ ...sendMsgData, to: value })}
                  />
                </div> */}
                </div>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>Purpose</label>
                    <CommonInput
                      defaultValue={selectedKYC?.purpose}
                      onChange={e => handleChange('purpose', e.target.value)}
                    />
                  </div>
                </div>

                {selectedKYC?.end_user_type === 'business' && (
                  <>
                    <h4>Business Details</h4>
                    <p>Enter your Business details below</p>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label>Name</label>
                        <CommonInput
                          defaultValue={
                            selectedKYC?.government_issued_address_friendly_name
                          }
                          onChange={e =>
                            handleChange(
                              'government_issued_address_friendly_name',
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Business Name</label>
                        <CommonInput
                          defaultValue={selectedKYC?.business_name}
                          onChange={e =>
                            handleChange('business_name', e.target.value)
                          }
                        />
                      </div>

                      {/* <div className="col-md-6 form-group">
                      <label>Company Registration Certificate</label>
                      <input
                        className="form-control"
                        type="file"
                        onChange={e =>
                          handleChange('commercial_register', e.target.files[0])
                        }
                      />
                    </div> */}
                    </div>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label>Legal Company Name</label>
                        <CommonInput
                          defaultValue={
                            selectedKYC?.commercial_register_business_name
                          }
                          onChange={e =>
                            handleChange(
                              'commercial_register_business_name',
                              e.target.value
                            )
                          }
                        />
                      </div>
                      {/* <div className="col-md-6 form-group">
                      <label>Government issued address friendly name</label>
                      <CommonInput
                        defaultValue={
                          selectedKYC?.government_issued_address_friendly_name
                        }
                        onChange={e =>
                          handleChange(
                            'government_issued_address_friendly_name',
                            e.target.value
                          )
                        }
                      />
                    </div> */}
                    </div>
                    <div className="row">
                      {/* <div className='col-md-6 form-group'>
                                            <label>Commercial registered business name</label>
                                            <CommonInput defaultValue={selectedKYC?.commercial_register_business_name} onChange={(e) => handleChange('commercial_register_business_name', e.target.value)} />
                                        </div> */}
                      <div className="col-md-6 form-group">
                        <label>Address 1</label>
                        <CommonInput
                          defaultValue={selectedKYC?.government_issued_address1}
                          onChange={e =>
                            handleChange(
                              'government_issued_address2',
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Address 2</label>
                        <CommonInput
                          defaultValue={selectedKYC?.government_issued_address2}
                          onChange={e =>
                            handleChange(
                              'government_issued_address1',
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label>Postal Code</label>
                        <CommonInput
                          defaultValue={
                            selectedKYC?.government_issued_postal_code
                          }
                          onChange={e =>
                            handleChange(
                              'government_issued_postal_code',
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Issued Country</label>

                        <select
                          className="w-100"
                          name="country"
                          onChange={e =>
                            handleChange(
                              'government_issued_country',
                              e?.target?.value
                            )
                          }
                          defaultValue={selectedKYC?.country}
                          placeholder="country"
                        >
                          {billingCountryList.map((country, key) => (
                            <option key={key} value={country.text}>
                              {country.text}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label>City</label>
                        <CommonInput
                          defaultValue={selectedKYC?.government_issued_city}
                          onChange={e =>
                            handleChange(
                              'government_issued_city',
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>State</label>
                        <CommonInput
                          defaultValue={selectedKYC?.government_issued_state}
                          onChange={e =>
                            handleChange(
                              'government_issued_state',
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label>Full Name on ID</label>
                        <CommonInput
                          defaultValue={
                            selectedKYC?.government_issued_customer_name
                          }
                          onChange={e =>
                            handleChange(
                              'government_issued_customer_name',
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
                <h4>Personal Address</h4>
                <p>Enter your Address details below</p>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>Address First Line</label>
                    <CommonInput
                      defaultValue={selectedKYC?.first_name_on_passport}
                      onChange={e =>
                        handleChange('first_name_on_passport', e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Address Second Line</label>
                    <CommonInput
                      defaultValue={selectedKYC?.last_name_on_passport}
                      onChange={e =>
                        handleChange('last_name_on_passport', e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>City</label>
                    <CommonInput
                      // defaultValue={selectedKYC?.first_name_on_passport}
                      onChange={e => handleChange('city', e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>State</label>
                    <CommonInput
                      // defaultValue={selectedKYC?.last_name_on_passport}
                      onChange={e => handleChange('state', e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>Country</label>
                    <select
                      className="w-100"
                      name="country"
                      onChange={(data, value) =>
                        handleChange('country', data?.target?.value)
                      }
                      defaultValue={selectedKYC?.country}
                      placeholder="country"
                    >
                      {billingCountryList.map((country, key) => (
                        <option key={key} value={country.text}>
                          {country.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Post Code</label>
                    <CommonInput
                      // defaultValue={selectedKYC?.country}
                      onChange={e =>
                        handleChange('postal_code', e.target.value)
                      }
                    />
                  </div>
                </div>

                <h4>Documents</h4>
                <div className="row align-items-center mb-3">
                  <div className="col-md-6">
                    <p className="mb-0">
                      Proof of Identity{' '}
                      <Tooltip title="Proof of Identity">
                        <InfoOutlinedIcon />
                      </Tooltip>{' '}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-control d-none"
                      type="file"
                      id="id_image"
                      // defaultValue={selectedKYC?.id_image}
                      onChange={e =>
                        handleChange('id_image', e.target.files[0])
                      }
                    />
                    <label for="id_image" className="upload-section mb-0">
                      <PublishIcon />
                      <span>Upload File</span>
                      {selectedKYC?.id_image &&
                        typeof selectedKYC?.id_image !== 'string' && (
                          <img
                            width={30}
                            height={30}
                            src={URL?.createObjectURL(selectedKYC?.id_image)}
                            alt="proof of indentity"
                          />
                        )}
                    </label>
                  </div>
                </div>
                {selectedKYC?.end_user_type === 'business' && (
                  <div className="row align-items-center mb-3">
                    <div className="col-md-6">
                      <p className="mb-0">
                        Business Proof of Identity{' '}
                        <Tooltip title="Business Proof of Identity">
                          <InfoOutlinedIcon />
                        </Tooltip>{' '}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <input
                        className="form-control d-none"
                        type="file"
                        id="government_issued_id"
                        // defaultValue={selectedKYC?.id_image}
                        onChange={e =>
                          handleChange(
                            'government_issued_id',
                            e.target.files[0]
                          )
                        }
                      />
                      <label
                        for="government_issued_id"
                        className="upload-section mb-0"
                      >
                        <PublishIcon />
                        <span>Upload File</span>
                        {selectedKYC?.government_issued_id &&
                          typeof selectedKYC?.government_issued_id !==
                            'string' && (
                            <img
                              width={30}
                              height={30}
                              src={URL?.createObjectURL(
                                selectedKYC?.government_issued_id
                              )}
                              alt="proof of indentity"
                            />
                          )}
                      </label>
                    </div>
                  </div>
                )}
                <div className="row align-items-center mb-3">
                  <div className="col-md-6">
                    <p className="mb-0">
                      Proof of Address{' '}
                      <Tooltip title="Proof of Address">
                        <InfoOutlinedIcon />
                      </Tooltip>{' '}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-control d-none"
                      type="file"
                      id="passport"
                      // defaultValue={selectedKYC?.id_image}
                      onChange={e =>
                        handleChange('passport', e.target.files[0])
                      }
                    />
                    <label for="passport" className="upload-section mb-0">
                      <PublishIcon />
                      <span>Upload File</span>
                      {selectedKYC?.passport &&
                        typeof selectedKYC?.passport !== 'string' && (
                          <img
                            width={30}
                            height={30}
                            src={URL?.createObjectURL(selectedKYC?.passport)}
                            alt="proof of indentity"
                          />
                        )}
                    </label>
                  </div>
                </div>
                {selectedKYC?.end_user_type === 'business' && (
                  <div className="row align-items-center mb-3">
                    <div className="col-md-6">
                      <p className="mb-0">
                        Business proof of certification{' '}
                        <Tooltip title="Business proof of certification">
                          <InfoOutlinedIcon />
                        </Tooltip>{' '}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <input
                        className="form-control d-none"
                        type="file"
                        id="commercial_register"
                        // defaultValue={selectedKYC?.id_image}
                        onChange={e =>
                          handleChange('commercial_register', e.target.files[0])
                        }
                      />
                      <label
                        for="commercial_register"
                        className="upload-section mb-0"
                      >
                        <PublishIcon />
                        <span>Upload File</span>
                        {selectedKYC?.commercial_register &&
                          typeof selectedKYC?.commercial_register !==
                            'string' && (
                            <img
                              width={30}
                              height={30}
                              src={URL?.createObjectURL(
                                selectedKYC?.commercial_register
                              )}
                              alt="proof of indentity"
                            />
                          )}
                      </label>
                    </div>
                  </div>
                )}

                <div className="button_container">
                  <div>
                    <Button
                      className="btn save-button mr-3"
                      onClick={handleSaveKYC}
                    >
                      {selectedKYC?.id ? 'Update KYC' : 'Add KYC'}
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Content>
          </Modal>
        )}
        <Modal
          className="local-number-modal"
          open={openConfirmationModal}
          onClose={e => setOpenConfirmationModal(false)}
        >
          <Modal.Content>
            <div className="modal-content">
              <p style={{ marginTop: 20 }}>
                Are you sure you want to delete this KYC ?
              </p>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                  paddingTop: 10,
                  paddingBottom: 10
                }}
              >
                <CommonButtons
                  onClick={e => setOpenConfirmationModal(false)}
                  type="button"
                  content="Cancel"
                  background="red"
                  style={{
                    borderRadius: 0,
                    backgroundColor: '#e0e1e2',
                    color: '#5a5a5a'
                  }}
                />

                <CommonButtons
                  onClick={handleDeleteTemplate}
                  type="button"
                  content="Confirm"
                  background="blue"
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                    borderRadius: 0,
                    backgroundColor: '#1071eb',
                    color: '#ffffff'
                  }}
                />
              </div>
            </div>
          </Modal.Content>
        </Modal>
      </div>
    )
}


const mapStateToProps = state => ({
    profile: state.settings.profile?.data,
})

export default connect(mapStateToProps)(
    NewKycDetail
)
