import React from 'react'
import axios from 'axios'

import Draggable, { DraggableCore } from 'react-draggable' // Both at the same time
import './style.scss'
import CallSummaryComponent from './CallSummaryComponent'
import { CommonNotify } from '../../common/CommonNotify'
import TagsComponent from './Tags'
import NotesComponent from './Notes'
import history from 'history/browser'
// import rg4js from 'raygun4js';
import AccountInfoComponent from './AccountInfo'

import { connect } from 'react-redux'
import { getDialer } from '../../actions/dialer'

import PhoneDialer from './phone-dialer/Dialer'
import Pusher from 'pusher-js'

import { withRouter } from 'react-router'
import NewAccountInfoComponent from './NewAccountInfo'

const API_URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1`
const apiToken = localStorage.getItem('access_token')

class Dialer extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.initialState
    // rg4js('apiKey', '71pSno4MdPm3xJPkPP7fGA');
    // rg4js('enablePulse', true);
    // rg4js('enableCrashReporting', true);
  }

  initialState = {
    status: 'start',
    displayIncoming: false,
    callStatus: 'idle',
    currentCallData: {},
    timerData: 0,
    displayAdditionalTools: null,
    callMuteStatus: false,
    systemMic: false,
    outboundCall: {
      error: false,
      connected: false,
      ringing: true,
      disconnected: false,
      intiate: false,
      leadId: 0,
      errmsg: '',
      msg: ''
    }
    // outboundCall:""
  }

  setCallStatus(key, value) {
    const temp = { ...this.state.outboundCall }
    temp[key] = value
    this.setState({ ...this.state, outboundCall: temp })
  }

  updateEvents = data => {
    // customer picked the call
    if (
      data.agent_status == 'agent_connected' &&
      data.customer_status == 'initiated'
    ) {
      this.setCallStatus('ringing', true)
      // this.setState({outboundCall: 'connected'})
    }

    if (
      data.agent_status == 'agent_connected' &&
      data.customer_status == 'customer_connected'
    ) {
      // console.log("Customer connected with agent")
      this.setCallStatus('connected', true)
      this.setState({ displayIncoming: true, callStatus: 'connected' })
      this.startTimeRecording()
      //this.state.displayIncoming
      // this.setState({outboundCall: 'connected'})
    }

    if (data.customer_status == 'Failed busy') {
      this.setCallStatus('disconnected', true)
      this.rejectCall(true)
    }
  }

  componentDidMount() {
    window.pusher = new Pusher(process.env.REACT_APP_PUSHER_ID, {
      cluster: 'eu',
      forceTLS: false,
      disableStats: true
    })

    // console.log('pusher', window.pusher)

    window.pusher.connection.bind('error', function(err) {
      // if( err.error.data.code === 4004 ) {
      console.log('Over limit!', err)
      // }
    })
  }

  setCallData = async leadId => {
    window.channel = window.pusher.subscribe(`call-channel-${leadId}`)
    window.channel.bind('change-call-status', this.updateEvents)
    axios
      .get(
        `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/get-call-by-id?id=${leadId}`
      )
      .then(res => {
        this.setState({ currentCallData: res.data.data })
        this.setCallStatus('leadId', leadId)
      })
      .catch(error => {
        console.log({ error })
      })
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // rg4js('trackEvent', { type: 'pageView', path: this.props.location.pathname });
    }
  }
  timerInterval = null

  setupDialer = token => {
    if (token === null || token === '' || !token) {
      return null
    }
    let twilioToken = this.fetchToken(token)
    if (twilioToken === '') {
      return null
    }
    return null
  }

  requestTokenForOutbound() {
    return axios.get(`${API_URL}/generate-token-for-outbound-calls`, {})
    //return axios.post(`${API_URL}/customer-generate-call-token`, {})
  }

  requestOutboundCall = param => {
    //device.current.connect(param)
    const a = window?.Twilio?.Device?.connect(param)

    return
  }

  runOutboundDialer = tkn => {
    window.Twilio.Device.setup(tkn, {
      codecPreferences: ['opus', 'pcmu'],
      fakeLocalDTMF: true,
      enableRingingState: true
    })

    window.Twilio.Device.on('ready', device => {})

    window.Twilio.Device.on('error', error => {
      console.log('error', error)
    })
    window.Twilio.Device.on('disconnect', conn => {
      this.rejectCall(true)
    })

    window.Twilio.Device.on('cancel', conn => {
      this.rejectCall()
    })

    window.Twilio.Device.on('connect', conn => {
      this.setState({
        callStatus: 'connected'
      })

      this.connection = conn
    })
  }

  runDialer = tkn => {
    const twilio = window.Twilio
    if (!this.device) {
      this.device = new twilio.Device()
      this.device.setup(tkn, {
        codecPreferences: ['opus', 'pcmu'],
        fakeLocalDTMF: true,
        enableRingingState: true
      })
    }

    this.device.on('ready', device => {})

    this.device.on('error', error => {
      console.log('error', error)
    })

    this.device.on('disconnect', conn => {
      this.rejectCall(true)
    })

    this.device.on('cancel', conn => {
      this.rejectCall()
    })

    this.device.on('connect', conn => {
      this.setState({
        callStatus: 'connected'
      })
    })

    this.device.incoming(conn => {
      this.setState({ ...this.initialState }, () => {
        this.setState({
          displayIncoming: true
        })
        // this.requestTokenForOutbound().then(r => {
        //   this.runOutboundDialer(r.data.data.token)
        // })

        this.connection = conn

        const leadID = conn.customParameters.get('lead')
        if (leadID) {
          axios
            .get(
              `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/get-call-by-id?id=${leadID}`
            )
            .then(res => {
              this.setState({ currentCallData: res.data.data })
            })
            .catch(error => {})
        }
      })
    })
  }

  disconnectCall = () => {
    if (window?.Twilio?.Device) {
      window.Twilio.Device._disconnectAll()
      this.device._disconnectAll()
    }
    if (this.device) {
      this.device._disconnectAll()
      if (this.connection) {
        this.connection.ignore()
      }
    }
  }

  startTimeRecording = (stopTimer = false) => {
    clearInterval(this.timerInterval)
    if (stopTimer) {
      return false
    } else {
      this.timerInterval = setInterval(() => {
        this.setState(previousState => {
          return {
            timerData: previousState.timerData + 1
          }
        })
      }, 1000)
    }
  }

  do_load = () => {
    var self = this
    var my_script = this.new_script(
      'https://media.twiliocdn.com/sdk/js/client/v1.13/twilio.min.js'
    )
    my_script
      .then(function() {
        self.setState({ status: 'done' })
      })
      .catch(function() {
        self.setState({ status: 'error' })
      })
  }

  new_script = src => {
    return new Promise(function(resolve, reject) {
      var script = document.createElement('script')
      script.src = src
      script.addEventListener('load', function() {
        resolve()
      })
      script.addEventListener('error', function(e) {
        reject(e)
      })
      document.body.appendChild(script)
    })
  }

  fetchToken = token => {
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/customer-generate-call-token`
    var tkn = ''
    axios
      .post(URL, null)
      .then(res => {
        if (res.data.data) {
          tkn = res.data.data.token
          this.runDialer(tkn)
        }
      })
      .catch(function(error) {
        if (error?.response?.status == 401) {
          localStorage.clear()
          window.location.href = '/login'
        }
      })
    return tkn
  }

  renderDialer = token => {
    if (this.state.status === 'done') {
      this.setupDialer(token)
    }
  }

  rejectCall = (displaySummaryBlock = false) => {
    this.disconnectCall()
    let newCallStatus = 'idle'
    let newDisplayIncoming = false

    if (this.timerInterval) {
      this.startTimeRecording(true)
    }
    if (displaySummaryBlock) {
      newCallStatus = 'disconnected'
      newDisplayIncoming = true
    }
    this.setState({
      displayIncoming: newDisplayIncoming,
      callStatus: newCallStatus
    })
  }

  acceptCall = () => {
    // this.setState({

    // })
    if (this.connection) {
      this.connection.accept()
    }
    this.startTimeRecording()
  }

  updateCallMuteStatus = mute => {
    this.connection.mute(mute)
    this.setState({ callMuteStatus: mute })
  }

  handleCallSummaryPost = data => {
    if (data) {
      data['lead_id'] = this.state.currentCallData.id
      data['agent_feedback_text'] = data['agent_feedback_text'] || 'N/A'
      let token = localStorage.getItem('access_token')
      this.setState({ callStatus: 'idle' })
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${this.state.currentCallData.id}/agent-feedback`
      axios
        .post(url, data)
        .then(res => {
          CommonNotify('Feedback Saved Successfully', 'success')
        })
        .catch(error => {
          CommonNotify(`Can't Save Feedback, Error Occured`)
        })
    }
    if (this.props?.always) {
      this.props.getDialer && this.props.getDialer()
    }
    this.setState({
      displayIncoming: false,
      callStatus: 'idle'
    })
  }

  saveCallNotesData = notesData => {
    const leadID = this.state.currentCallData.id

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${leadID}/add-note`
    axios
      .post(url, notesData)
      .then(res => {
        CommonNotify('Notes Saved Successfully', 'success')
      })
      .catch(error => {
        CommonNotify(`Can't Save Notes, Please try again`)
      })
  }
  fetchContact = async id => {
    this.setState({ loading: true, leadNotes: [] })
    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${id}`

    axios
      .get(url)
      .then(res => {
        this.setState({
          loading: false,
          selectedContact: res.data.data,
          updateContact: res.data.data
        })
      })
      .catch(err => {
        this.setState({ loading: false })
      })
    const urlLeads = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${this.state.activeConversation.lead_id}/note`

    axios
      .get(urlLeads)
      .then(res => {
        if (Array.isArray(res.data.data)) {
          this.setState({
            leadNotes: res.data.data
          })
        } else {
          this.setState({
            leadNotes: []
          })
        }
      })
      .catch(err => {
        this.setState({ loading: false, leadNotes: [] })
      })
    const urlLogs = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${this.state.activeConversation.id}/logs?logs_segment_by=month`
  }

  defaultNewDialerUi = () => {
    const tags =
      this.state.currentCallData?.contact?.tags ||
      this.state.currentCallData?.tags ||
      []
    return (
      <div className="dialer-component-main">
        <div className="hor-row heading-main">
          {this.state.callStatus !== 'idle' ? 'Ongoing ' : 'Incoming '}
          Call
        </div>
        <div className="hor-row call-info-container-main">
          <div className="user-image-container">
            <div className="user-image"></div>
          </div>
          <div className="chip-outside">
            {tags?.length > 0 &&
              tags?.map(
                (val, index) => index < 3 && <div className="chip">{val}</div>
              )}
            {tags?.length > 0 && (
              <div className="chip">
                {tags?.length > 3 && `+${tags?.length - 3}`}
              </div>
            )}
          </div>
          <div className="hor-row user-name-container">
            {this.state.currentCallData.customer_name &&
            this.state.currentCallData.customer_name !== 'undefined'
              ? this.state.currentCallData.customer_name
              : ' Unknown'}
          </div>
          {this.state.currentCallData.type !== 'DIGITAL_CALL' && (
            <div className="hor-row mobile-number-container">
              {this.state.currentCallData.phone_number}
            </div>
          )}
          <NewAccountInfoComponent
            currentCallData={this.state.currentCallData}
          />
          {this.state.callStatus === 'idle' ? (
            <div className="hor-row call-tool-container">
              <span
                className="accept-call-container"
                onClick={e => this.acceptCall()}
              >
                <i className="material-icons accept-call-icon">call_end</i>{' '}
                <span>Accept</span>
              </span>
              <span
                className="end-call-container"
                onClick={e => this.rejectCall()}
              >
                <i className="material-icons end-call-icon">call_end</i>
                <span>Decline</span>
              </span>
            </div>
          ) : (
            <div className="hor-row call-tools-after-call-pick-container">
              <div className="hor-row basic-tools-container">
                <span
                  className="basic-tool-icon-container"
                  onClick={() =>
                    this.updateCallMuteStatus(!this.state.callMuteStatus)
                  }
                >
                  <i className="material-icons basic-tool-icon">
                    {this.state.callMuteStatus ? 'mic_off' : 'mic_none_outline'}
                  </i>
                </span>

                <span
                  className="basic-tool-icon-container call-button-icon-container"
                  onClick={e => this.rejectCall(true)}
                >
                  <i className="material-icons basic-tool-icon">call_end</i>
                </span>
              </div>
              <div className="hor-row extra-tool-container">
                <div>
                  {' '}
                  {this.state.callStatus !== 'idle' ? (
                    <span
                      className="timer-component-main"
                      style={{ color: '#fff' }}
                    >
                      {new Date(this.state.timerData * 1000)
                        .toISOString()
                        .substr(11, 8)}
                    </span>
                  ) : (
                    ''
                  )}
                </div>

                <span
                  className={
                    'extra-tool-card' +
                    (this.state.displayAdditionalTools === 'notes'
                      ? ' active-extra-tool-card'
                      : '')
                  }
                  onClick={() =>
                    this.setState({ displayAdditionalTools: 'notes' })
                  }
                >
                  <i className="material-icons extra-tool-icon">
                    create_outline
                  </i>
                  Notes
                </span>
                <span
                  className={
                    'extra-tool-card' +
                    (this.state.displayAdditionalTools === 'tags'
                      ? ' active-extra-tool-card'
                      : '')
                  }
                  onClick={() =>
                    this.setState({ displayAdditionalTools: 'tags' })
                  }
                >
                  <i className="material-icons extra-tool-icon">
                    local_offer_outline
                  </i>
                  Tags
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
  defaultNewDialerUi = () => {
    const tags =
      this.state.currentCallData?.contact?.tags ||
      this.state.currentCallData?.tags ||
      []
    return (
      <div className="dialer-component-main">
        <div className="hor-row heading-main">
          {this.state.callStatus !== 'idle' ? 'Ongoing ' : 'Incoming '}
          Call
        </div>
        <div className="hor-row call-info-container-main">
          <div className="user-image-container">
            <div className="user-image"></div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {tags?.length > 0 &&
              tags?.map(
                (val, index) => index < 3 && <div className="chip">{val}</div>
              )}
            <div className="chip">
              {tags?.length > 3 && `+${tags?.length - 3}`}
            </div>
          </div>
          <div className="hor-row user-name-container">
            {this.state.currentCallData.customer_name &&
            this.state.currentCallData.customer_name !== 'undefined'
              ? this.state.currentCallData.customer_name
              : ' Unknown'}
          </div>
          {this.state.currentCallData.type !== 'DIGITAL_CALL' && (
            <div className="hor-row mobile-number-container">
              {this.state.currentCallData.phone_number || '+03958098563948'}
            </div>
          )}
          <NewAccountInfoComponent
            currentCallData={this.state.currentCallData}
          />
          {this.state.callStatus === 'idle' ? (
            <div className="hor-row call-tool-container">
              <span
                className="accept-call-container"
                onClick={e => this.acceptCall()}
              >
                <i className="material-icons accept-call-icon">call_end</i>{' '}
                {/* Accept Call */}
              </span>
              <span
                className="end-call-container"
                onClick={e => this.rejectCall()}
              >
                <i className="material-icons end-call-icon">call_end</i>
              </span>
            </div>
          ) : (
            <div className="hor-row call-tools-after-call-pick-container">
              <div className="hor-row basic-tools-container">
                <span
                  className="basic-tool-icon-container"
                  onClick={() =>
                    this.updateCallMuteStatus(!this.state.callMuteStatus)
                  }
                >
                  <i className="material-icons basic-tool-icon">
                    {this.state.callMuteStatus ? 'mic_off' : 'mic_none_outline'}
                  </i>
                </span>

                <span
                  className="basic-tool-icon-container call-button-icon-container"
                  onClick={e => this.rejectCall(true)}
                >
                  <i className="material-icons basic-tool-icon">call_end</i>
                </span>
              </div>
              <div className="hor-row extra-tool-container">
                <div>
                  {' '}
                  {this.state.callStatus !== 'idle' ? (
                    <span
                      className="timer-component-main"
                      style={{ color: '#fff' }}
                    >
                      {new Date(this.state.timerData * 1000)
                        .toISOString()
                        .substr(11, 8)}
                    </span>
                  ) : (
                    ''
                  )}
                </div>

                <span
                  className={
                    'extra-tool-card' +
                    (this.state.displayAdditionalTools === 'notes'
                      ? ' active-extra-tool-card'
                      : '')
                  }
                  onClick={() =>
                    this.setState({ displayAdditionalTools: 'notes' })
                  }
                >
                  <i className="material-icons extra-tool-icon">
                    create_outline
                  </i>
                  Notes
                </span>
                <span
                  className={
                    'extra-tool-card' +
                    (this.state.displayAdditionalTools === 'tags'
                      ? ' active-extra-tool-card'
                      : '')
                  }
                  onClick={() =>
                    this.setState({ displayAdditionalTools: 'tags' })
                  }
                >
                  <i className="material-icons extra-tool-icon">
                    local_offer_outline
                  </i>
                  Tags
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    var self = this
    let token = localStorage.getItem('access_token')
    if (self.state.status === 'start') {
      self.state.status = 'loading'
      setTimeout(function() {
        self.do_load()
      }, 0)
    }

    return (
      <React.Fragment>
        <PhoneDialer
          outboundCall={this.state.outboundCall}
          setCallData={this.setCallData}
          setCallStatus={this.setCallStatus}
          hangupCall={this.rejectCall}
          runDialer={this.runOutboundDialer}
          requestOutboundCall={this.requestOutboundCall}
          requestTokenForOutbound={this.requestTokenForOutbound}
          always={this.props?.always}
        />

        {this.state.displayIncoming && (
          <Draggable>
            <div className="draggalbe-container-main">
              <div className="draggable-content-main">
                {this.state.callStatus === 'disconnected' ? (
                  <CallSummaryComponent
                    CommonNotify={CommonNotify}
                    currentCallData={this.state.currentCallData}
                    timerData={this.state.timerData}
                    handleCallSummaryPost={this.handleCallSummaryPost}
                  />
                ) : (
                  // this.defaultDialerUi()
                  this.defaultNewDialerUi()
                )}
              </div>
            </div>
          </Draggable>
        )}

        {this.state.displayAdditionalTools && (
          <Draggable>
            <div className="draggalbe-container-main tags-draggable-container-main">
              <div className="draggable-content-main">
                {this.state.displayAdditionalTools === 'tags' && (
                  <TagsComponent
                    toggleDisplayTag={() =>
                      this.setState({ displayAdditionalTools: false })
                    }
                    leadID={this.state.currentCallData.id}
                  />
                )}

                {this.state.displayAdditionalTools === 'notes' && (
                  <NotesComponent
                    toggleDisplayTag={() =>
                      this.setState({ displayAdditionalTools: false })
                    }
                    saveCallNotesData={this.saveCallNotesData}
                  />
                )}
              </div>
            </div>
          </Draggable>
        )}
        {this.renderDialer(token)}
      </React.Fragment>
    )
  }
}

export default withRouter(Dialer)
