// import { Switch } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'

import {
  Button,
  Dimmer,
  Dropdown,
  FormGroup,
  Input,
  Loader,
  Modal,
  Table,
  TextArea
} from 'semantic-ui-react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { getSubscription } from '../actions/home'
import close from '../assets/images/close.png'
import CommonButtons from '../common/CommonButtons'
import campaign from '../assets/images/campaign.png'
import CommonInput from '../common/CommonInput'
import { CommonNotify } from '../common/CommonNotify'
import SendMessageModal from '../common/SendMessageHeader'
import Title from '../common/Title'
import { useGetUserIdQuery } from '../features/api/apiSlice'
import SMSCampaignsHistory from './SMSCampaignsHistory'
import { Popover } from '@mui/material'
import TeamplateCampaign from './TeamplateCampaign'
import ScheduleCampaign from './ScheduleCampaign'
import Axios from 'axios'
import ListCampaign from './ListCampaign'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const title = {
  titleTwo: 'Campaign'
}

// const IOSSwitch = styled(props => (
//   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(() => ({
//   width: 64,
//   height: 30,
//   padding: '3px 2px',
//   '& .MuiSwitch-switchBase': {
//     padding: 0,
//     margin: 2,
//     transitionDuration: '300ms',
//     '&.Mui-checked': {
//       transform: 'translateX(36px)',
//       color: '#fff',
//       '& + .MuiSwitch-track': {
//         backgroundColor: '#65C466',
//         opacity: 1,
//         border: 0,
//         '&:before': {
//           content: "'On'",
//           left: 10,
//           right: 'auto'
//         }
//       }
//     }
//   },
//   '& .MuiSwitch-thumb': {
//     boxSizing: 'border-box',
//     width: 26,
//     height: 26,
//     border: '1px solid #A2A2A1'
//   },
//   '& .MuiSwitch-track': {
//     borderRadius: 26 / 2,
//     backgroundColor: '#c7c7c7',
//     opacity: 1,
//     transition: 'all 0.5s ease-in-out',
//     '&:before': {
//       content: "'Off'",
//       position: 'absolute',
//       right: 9,
//       top: 6,
//       textTransform: 'uppercase',
//       color: '#fff'
//     }
//   }
// }))

const Campaign = ({ subscription, getSubscription }) => {
  const user = useGetUserIdQuery()
  const navigate = useHistory()
  
  const [isLoading, setIsLoading] = useState(false)
  const [personalise, setPersonalise] = useState(false)
  const [detailPopUp, setDetailPopUp] = useState(false)
  const [sideTab, setSideTab] = useState(1)
  const [schedulePopUp, setSchedulePopUp] = useState(false)
  const [track, setTrack] = useState(false)
  const [replies, setReplies] = useState(false)
  const [menuOpen, setMenu] = useState(false)
  const [templates, setTemplates] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [creditAmt, setCreditAmt] = useState(0)
  // const [repeat, setRepeat] = useState(false)
  const [sendModal, setSendModal] = useState(false)
  const [cardData, setCardData] = useState({})
  const [schedule, setSchedule] = useState(false)
  const [message, setMessage] = useState(null)
  const [name, setName] = useState(null)
  const [from, setFrom] = useState(null)
  const [sendTo, setSendTo] = useState(null)
  const [visible, setVisible] = useState(null)
  const [messageTemplate, setMessageTemplate] = useState(null)
  const [dropDownData, setDropdownData] = useState([])
  const [groupList, setGroupList] = useState([])
  const [templateList, setTemplateList] = useState([])
  const [selectedFile, setSelectedFile] = useState({})
  const [model, setModel] = useState(false)
  const [scheduleDateAndTime, setScheduleDateAndTime] = useState({
    date: null,
    time: null
  })
  const [repeatDayAndDate, setRepeatDayAndDate] = useState({
    day: null,
    date: null
  })
  const [messageConfirmation, setMessageConfirmation] = useState(false)

  useEffect(() => {
    getGroup()
    getTemplates()
    fetchCard()
  }, [])

  useEffect(() => {
    if (user?.isSuccess) {
      setUserId(user)
    }
  }, [user])

  const refreshData = () => {
    setSchedule(false)
    setMessage(null)
    setName(null)
    setFrom(null)
    setSendTo(null)
    setMessageTemplate(null)
  }

  const setUserId = res => {
    const data = []
    res.data.data.map(number => {
      data.push({
        key: number[0],
        value: number[0],
        text: `${number[0]}`
      })
    })
    setDropdownData(data)
  }

  const handleAppendMessage = value => {
    setMessage(`${message ? message : ''} ${value}`)
  }

  const handlePersonalise = () => {
    setPersonalise(!personalise)
  }

  const getTemplates = async () => {
    setIsLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/quick-responses?target=Conversation`
    await Axios.get(url)
      .then(res => {
        setIsLoading(false)
        if (res?.data?.data && res?.data?.data?.length) {
          setTemplateList(res?.data?.data || [])
        } else {
          setTemplateList([])
        }
      })
      .catch(err => {
        setIsLoading(false)
        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Something went to wrong!'
        )
      })
  }

  // get the contact group list
  const getGroup = async id => {
    setIsLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/lists`
    await axios
      .get(url)
      .then(res => {
        setIsLoading(false)
        if (res?.data?.data && res?.data?.data?.length) {
          let filterValue = []
          const data = res?.data?.data.map(list => {
            list?.contacts_count > 0 &&
              filterValue.push({
                text: `${list?.name} (${list?.contacts_count || 0})`,
                id: list?.id,
                key: list?.id,
                value: list?.id
              })
          })
          setGroupList(filterValue || [])
        } else {
          setGroupList([])
        }
      })
      .catch(err => {
        setIsLoading(false)
        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Somethign went to wrong!'
        )
      })
  }

  const fetchCard = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/get-customer-payment-methods`
    axios
      .get(url)
      .then(res => {
        if (res.data.data[0]) {
          setCardData(res?.data?.data?.[0])
        }
      })
      .catch(error => {})
  }
  const fetachNumbers = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/get-customer-payment-methods`
    axios
      .get(url)
      .then(res => {
        if (res.data.data[0]) {
          setCardData(res?.data?.data?.[0])
        }
      })
      .catch(error => {})
  }

  const handleSave = async () => {
    if (!name) {
      CommonNotify('Please enter name')
      return
    }
    if (!sendTo) {
      CommonNotify('Please select sent To value')
      return
    }
    if (!from) {
      CommonNotify('Please select from value')
      return
    }
    if (!message) {
      CommonNotify('Message is required')
      return
    }
    if (schedule) {
      if (!scheduleDateAndTime?.date) {
        CommonNotify('Please select sechedule date')
        return
      }
      if (!scheduleDateAndTime?.time) {
        CommonNotify('Please select sechedule time')
        return
      }
    }

    setIsLoading(true)
    let payload = {
      name: name,
      from: from,
      content: [message],
      list_ids: [sendTo],
      // contact_ids: [1910],
      type: schedule ? 'SCHEDULE' : 'ON_TIME',
      scheduled_at: `${
        scheduleDateAndTime?.date
          ? moment(scheduleDateAndTime?.date).format('yyyy-MM-DD')
          : ''
      } ${
        scheduleDateAndTime?.time
          ? moment(scheduleDateAndTime?.time).format('hh:mm')
          : ''
      }`
    }
    if (!schedule) {
      delete payload?.scheduled_at
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/campaign`
    await axios
      .post(url, payload)
      .then(res => {
        refreshData()
        setIsLoading(false)
        setMessageConfirmation(true)
        CommonNotify('Send message successfully')
      })
      .catch(err => {
        setIsLoading(false)
        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Somethign went to wrong!'
        )
      })
  }

  const onFileChange = event => {
    setSelectedFile(event.target.files[0])
    // Update the state
  }

  const onFileUpload = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/upload-contact-file`
    await axios
      .post(url, { have_header: 0, file: selectedFile })
      .then(res => {
        refreshData()
        setIsLoading(false)
        setMessageConfirmation(true)
        CommonNotify('Send message successfully')
      })
      .catch(err => {
        setIsLoading(false)
        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Somethign went to wrong!'
        )
      })
  }
  const handleLoadData = () => {
    switch (sideTab) {
      case 0:
        return (
          <>
            {!messageConfirmation ? (
              <>
                <Modal
                  onClose={() => setTrack(false)}
                  onOpen={() => setTrack(true)}
                  open={track}
                  className="message-modal"
                >
                  <div className="close-icon">
                    <img
                      src={close}
                      alt="close"
                      onClick={() => setTrack(false)}
                    />
                  </div>
                  <div className="title">
                    <h2>Shorten & Track URL</h2>
                  </div>
                  <p>
                    Simply enter a URL that you would like to shorten and track
                    clicks within FireText.{' '}
                    <Link to="/">NEW: Brand your URL?</Link>
                  </p>
                  <FormGroup className="mb-3 pt-2">
                    <label>Please enter a URL:</label>
                    <Input fluid placeholder="http://www.example.com" />
                  </FormGroup>
                  <Button secondary>Insert URL</Button>
                </Modal>
                <Modal
                  onClose={() => setReplies(false)}
                  onOpen={() => setReplies(true)}
                  open={replies}
                  className="message-modal"
                >
                  <div className="close-icon">
                    <img
                      src={close}
                      alt="close"
                      onClick={() => setReplies(false)}
                    />
                  </div>
                  <div className="title">
                    <h2>Allow Replies</h2>
                  </div>
                  <p>
                    To receive replies into your account, you'll need to add a
                    reply number.
                  </p>
                  <Button>Buy Reply Number</Button>
                </Modal>
                <div className="content-wrap">
                  <SendMessageModal
                    isModalClose={() => setSendModal(false)}
                    open={sendModal}
                  />

                  <Row className="flex-row-reverse">
                    <Col lg={4}>
                      <h5 className="text-center">Preview</h5>
                      <div className="sms-preview">
                        <h6>{from || ''}</h6>
                        {message && (
                          <div className="sms-box">
                            <p>{message}</p>
                          </div>
                        )}
                      </div>
                      <div className="credit-box">
                        <h3>
                          {subscription?.data?.credits_in_dollars || 0}
                          <span>Credits Remaining:</span>
                        </h3>
                        <Button onClick={() => setIsCancelModalOpen(true)}>
                          Top UP Now
                        </Button>
                      </div>
                      <div className="link-box">
                        <h3>A Little Help?</h3>
                        <Link to="/">Chat with us</Link>
                        <Link to="/">Campaign checklist</Link>
                        <Link to="/">Go to FAQs</Link>
                        <h3>Refer A Friend</h3>
                        <Link to="/">Get Rewards</Link>
                      </div>
                    </Col>
                    <Col lg={8}>
                      <h2 className="font-weight-bold mb-4">
                        <i class="fas fa-comment-alt align-middle mr-2"></i>{' '}
                        Create an SMS campaign{' '}
                        <VisibilityIcon
                          onMouseEnter={e => setVisible(e.currentTarget)}
                          onMouseLeave={() => setVisible(null)}
                        />
                        <Popover
                          id="mouse-over-popover"
                          sx={{
                            pointerEvents: 'none'
                          }}
                          open={visible}
                          anchorEl={visible}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                          }}
                          onClose={() => setVisible(null)}
                          disableRestoreFocus
                        >
                          <img src={campaign} alt="campaign" />
                        </Popover>
                      </h2>
                      <p style={{ fontWeight: 300 }}>
                        Keep subscribers engaged by sharing your latest news,
                        promoting your bestselling products, or announcing an
                        upcoming event.
                      </p>
                      {!dropDownData?.length ? (
                        <div
                          style={{
                            padding: '10px',
                            backgroundColor: '#fefdde'
                          }}
                        >
                          👋 Hey friend! You need a phone number before you can
                          send a message.{' '}
                          <Link
                            style={{
                              color: '#007bff',
                              textDecoration: 'underline'
                            }}
                            to="/addNumbers"
                          >
                            Get your number
                          </Link>
                        </div>
                      ) : null}
                      <hr />
                      <div className="send-message-form">
                        <Row>
                          <Col xl={8} md={9}>
                            <FormGroup>
                              <label>Campaign Name:</label>
                              <Input
                                fluid
                                value={name}
                                onChange={e => setName(e?.target?.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={8} md={9}>
                            <FormGroup>
                              <label>Send to list:</label>
                              <div className="d-flex">
                                <Dropdown
                                  placeholder={'Select List...'}
                                  fluid
                                  search
                                  selection
                                  name="sendTo"
                                  value={sendTo}
                                  onChange={(e, result) => {
                                    if (result?.value === 'Add New List') {
                                      setModel(!model)
                                    } else {
                                      setSendTo(result?.value)
                                    }
                                  }}
                                  options={
                                    [
                                      ...groupList

                                      //                   ,{
                                      //                   text: 'Add New List',
                                      // id:'Add New List' ,
                                      // key:'Add New List',
                                      // value: 'Add New List',
                                      //                 }
                                    ] || []
                                  }
                                />
                              </div>
                              <span className="d-block mt-2">
                                <b onClick={() => setSendModal(true)}>
                                  or send an individual message
                                </b>
                              </span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="align-items-end">
                          <Col xl={8} md={9}>
                            <FormGroup>
                              <label>From:</label>
                              <Dropdown
                                fluid
                                name="from"
                                placeholder="Select Number"
                                selection
                                value={from}
                                options={dropDownData}
                                onChange={(e, data) => setFrom(data.value)}
                              />
                              <p style={{ fontWeight: 300 }}>
                                In some countries, display names are not allowed
                                and only a phone number or a short code is
                                displayed to recipients.
                              </p>

                              <div
                                style={{
                                  backgroundColor: '#f9f7ff',
                                  padding: '15px'
                                }}
                              >
                                <p style={{ fontWeight: 500 }}>
                                  <InfoIcon style={{ color: '#584ed9' }} />{' '}
                                  Sending to US requires a toll-free or 10DLC
                                  number
                                </p>
                                <p style={{ marginLeft: '15px' }}>
                                  To send an SMS campaign to recipients in the
                                  United States or Canada, you must complete
                                  this
                                  <a
                                    style={{
                                      color: 'blue',
                                      cursor: 'pointer',
                                      textDecoration: 'underline'
                                    }}
                                    href="https://forms.gle/n9RqpDQQ4UFnn2dTA"
                                    target="_blank"
                                  >
                                    {' '}
                                    form
                                  </a>{' '}
                                  to be registered with a toll-free number and
                                  comply with the regulations.
                                </p>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="align-items-end">
                          <Col xl={8} md={9}>
                            <FormGroup>
                              {templates && (
                                <>
                                  <label>Template:</label>

                                  <Row className="align-items-center">
                                    <Col xl={6} md={7}>
                                      <FormGroup>
                                        <Dropdown
                                          placeholder={'Select template...'}
                                          fluid
                                          search
                                          selection
                                          name="messageTemplate"
                                          options={templateList?.map(
                                            ({ title, id }, index) => ({
                                              text: title,
                                              key: index + 1,
                                              value: id
                                            })
                                          )}
                                          value={messageTemplate}
                                          onChange={handleTemplateChange}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col xl={6} md={5}>
                                      <span
                                        className="mb-3 d-block cursor-pointer"
                                        onClick={() => {
                                          setMessageConfirmation(false)
                                          setSideTab(2)
                                        }}
                                      >
                                        <i className="fa fa-gear"></i> Add/Edit
                                      </span>
                                    </Col>
                                  </Row>
                                </>
                              )}
                              <label>Message:</label>
                              <TextArea
                                fluid
                                rows={4}
                                className="d-block"
                                value={message}
                                onChange={e => setMessage(e?.target?.value)}
                              />
                              <p className="mt-2">
                                <b>{(message && message?.length) || 0}</b>{' '}
                                characters used (
                                <b>
                                  {message && message?.length
                                    ? Math?.ceil(message?.length / 160)
                                    : 0}
                                </b>{' '}
                                SMS per recipient)
                              </p>
                            </FormGroup>
                          </Col>
                          <Col xl={4} md={3}>
                            <FormGroup className="mb-4 pb-2">
                              <ul className="msgOpts">
                                <li
                                  className={`${personalise ? 'show' : ''}`}
                                  onClick={() => handlePersonalise()}
                                >
                                  <span>Personalise</span>

                                  <div className="personalise-tags">
                                    <span
                                      onClick={() =>
                                        handleAppendMessage(`{NAME}`)
                                      }
                                    >
                                      Name
                                    </span>
                                    <span
                                      onClick={() =>
                                        handleAppendMessage(`{EMAIL}`)
                                      }
                                    >
                                      Email
                                    </span>
                                    <span
                                      onClick={() =>
                                        handleAppendMessage(`{COMPANY_NAME}`)
                                      }
                                    >
                                      Company Name
                                    </span>
                                    <span
                                      onClick={() =>
                                        handleAppendMessage(`{MEETING_LINK}`)
                                      }
                                    >
                                      Meeting Link
                                    </span>
                                  </div>
                                </li>
                                <li
                                  onClick={() => setTrack(true)}
                                  className={`${track ? 'show' : ''}`}
                                >
                                  Track URL
                                </li>
                                <li
                                  onClick={() => setTemplates(!templates)}
                                  className={`${templates ? 'show' : ''}`}
                                >
                                  Templates
                                </li>
                              </ul>
                            </FormGroup>
                          </Col>
                        </Row>
                        {schedule && (
                          <>
                            <Row className="align-items-end">
                              <Col md={8}>
                                <Row className="align-items-end mb-3" noGutters>
                                  <Col md={6} className="pr-md-1">
                                    <FormGroup>
                                      <label>Schedule:</label>
                                      <DatePicker
                                        selected={scheduleDateAndTime?.date}
                                        onChange={date =>
                                          setScheduleDateAndTime({
                                            ...scheduleDateAndTime,
                                            date: date
                                          })
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        wrapperClassName="w-100"
                                        todayButton={'Today'}
                                        customInput={
                                          <Input
                                            label={
                                              <i className="fa fa-calendar ui"></i>
                                            }
                                            fluid
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} className="pl-md-1">
                                    <FormGroup>
                                      <DatePicker
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        wrapperClassName="w-100"
                                        selected={scheduleDateAndTime?.time}
                                        onChange={date =>
                                          setScheduleDateAndTime({
                                            ...scheduleDateAndTime,
                                            time: date
                                          })
                                        }
                                        customInput={
                                          <Input
                                            label={
                                              <i className="fa fa-clock ui"></i>
                                            }
                                            fluid
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            {/* <Row className="align-items-end">
                          <Col md={8}>
                            <FormGroup className="mb-3">
                              <label>Options:</label>
                              <span className="mr-md-2">Repeat:</span>
                              <IOSSwitch
                                checked={repeat}
                                onChange={() => setRepeat(!repeat)}
                              />
                            </FormGroup>
                            {repeat && (
                              <Row className="align-items-end mb-3" noGutters>
                                <Col md={6}>
                                  <FormGroup>
                                    <Input labelPosition="right" type="text">
                                      <Label>Every</Label>
                                      <input
                                        className="w-100"
                                        value={repeatDayAndDate?.day}
                                        onChange={e =>
                                          setRepeatDayAndDate({
                                            ...repeatDayAndDate,
                                            day: e?.target?.value
                                          })
                                        }
                                      />
                                      <Label>days</Label>
                                    </Input>
                                  </FormGroup>
                                </Col>
                                <Col md={6} className="pl-md-2">
                                  <FormGroup>
                                    <DatePicker
                                      dateFormat="dd/MM/yyyy"
                                      wrapperClassName="w-100"
                                      onChange={date =>
                                        setRepeatDayAndDate({
                                          ...repeatDayAndDate,
                                          date: date
                                        })
                                      }
                                      selected={repeatDayAndDate?.date}
                                      todayButton={'Today'}
                                      customInput={
                                        <Input label="until" fluid />
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row> */}
                          </>
                        )}

                        <Row className="mt-4">
                          <Col xs={12}>
                            <FormGroup>
                              <Button secondary onClick={handleSave}>
                                {schedule ? 'Schedule Send' : 'Send Now'}
                              </Button>
                              <span
                                className="ml-3 cursor-pointer py-3 d-inline-block"
                                onClick={() => setSchedule(!schedule)}
                              >
                                {schedule
                                  ? 'or Cancel scheduling'
                                  : 'or Schedule For Later'}
                              </span>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <div className="setting-tab">
                <div className="message-box">
                  <h2>Message Sent</h2>
                  <p>Your message has been sent successfully</p>
                  <p>
                    Click{' '}
                    <Link
                      className="cursor-pointer"
                      onClick={() => setSideTab(1)}
                    >
                      here
                    </Link>{' '}
                    to track the status of this sms broadcast
                  </p>
                </div>
              </div>
            )}
          </>
        )

      case 1:
        return <SMSCampaignsHistory onBroadCast={() => setSideTab(0)} />

      case 2:
        return (
          <div>
            <TeamplateCampaign />
          </div>
        )
      case 3:
        return (
          <div>
            <ScheduleCampaign onBroadCast={() => setSideTab(0)} />
          </div>
        )
      case 4:
        return (
          <div>
            <ListCampaign onBroadCast={() => setSideTab(0)} />
          </div>
        )
      default:
        break
    }
  }

  const handleAddCredit = async () => {
    setIsLoading(true)
    if (+creditAmt < 10) {
      CommonNotify(`The amount must be at least 10$`)
      setIsLoading(false)
      return
    }

    const data = { amount: creditAmt, card: cardData?.id }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/subscription/funds`
    axios
      .post(url, data)
      .then(res => {
        CommonNotify(`Successfully added ${creditAmt} to the credit`, 'success')
        setIsCancelModalOpen(false)
        setIsLoading(false)
        getSubscription()
      })
      .catch(error => {
        setIsLoading(false)
        setIsCancelModalOpen(false)
        getSubscription()
        CommonNotify(error?.response?.data?.errors?.[0])
      })
  }

  const handleTemplateChange = (e, result) => {
    setMessageTemplate(result?.value)
    const templateMessage =
      templateList.find(({ id }) => id === result?.value)?.description || ''
    setMessage(templateMessage)
  }

  return (
    <>
      <div className="sidebarWrap">
        <div className="meetingSidebar">
          <div className="sideHeader">{/* <h1>Campaign</h1> */}</div>
          <label
            className={sideTab == 0 ? 'sideTab' : ''}
            onClick={() => {
              setMessageConfirmation(false)
              setSideTab(0)
            }}
          >
            Send
          </label>
          <label
            className={sideTab == 1 ? 'sideTab' : ''}
            onClick={() => {
              setMessageConfirmation(false)
              setSideTab(1)
            }}
          >
            Campaigns
          </label>
          <label
            className={sideTab == 3 ? 'sideTab' : ''}
            onClick={() => {
              setMessageConfirmation(false)
              setSideTab(3)
            }}
          >
            Scheduled
          </label>
          <label
            className={sideTab == 2 ? 'sideTab' : ''}
            onClick={() => {
              setMessageConfirmation(false)
              setSideTab(2)
            }}
          >
            Templates
          </label>
          <label
            className={sideTab == 4 ? 'sideTab' : ''}
            onClick={() => {
              setMessageConfirmation(false)
              setSideTab(4)
            }}
          >
            Lists
          </label>
          <label
            className={sideTab == 5 ? 'sideTab' : ''}
            onClick={() => {
              navigate.push('/compliance')
            }}
          >
            Compliance
          </label>
        </div>
        <div className="meetWrapper">
          <div className="meet-container">
            <Dimmer active={isLoading} style={{ position: 'fixed' }}>
              <Loader />
            </Dimmer>
            <Title
              data={title}
              setMenu={setMenu}
              menuOpen={menuOpen}
              detailPopUp={detailPopUp}
              schedulePopUp={schedulePopUp}
            />
          </div>

          {handleLoadData()}

          <Modal
            className="add-credit-modal-wrapper"
            open={isCancelModalOpen}
            onClose={() => setIsCancelModalOpen(false)}
          >
            <Modal.Header>
              <p className="cancel-title">Add Credits</p>
            </Modal.Header>
            <Modal.Content>
              <div className="modal-content" style={{ padding: '12px 15px' }}>
                <Table style={{ padding: '10px 12px' }}>
                  <Table.Header style={{ padding: '12px 15px' }}>
                    <Table.Row>
                      <Table.HeaderCell>Card Number</Table.HeaderCell>
                      <Table.HeaderCell>Added Date</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body style={{ padding: '10px 12px' }}>
                    {cardData && (
                      <Table.Row>
                        <Table.Cell>
                          **** {cardData?.card?.last_four_digits}
                        </Table.Cell>
                        <Table.Cell>
                          {cardData?.card?.expiry_month} /{' '}
                          {cardData?.card?.expiry_year}
                        </Table.Cell>
                        <Table.Cell>
                          {cardData?.status === 'valid'
                            ? 'Active'
                            : 'Not active'}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
                <div
                  className="add-credit-field"
                  style={{ padding: '12px 15px' }}
                >
                  <CommonInput
                    title="Add Amount"
                    onChange={e => setCreditAmt(e.target.value)}
                    placeholder="Add Amount"
                    name="addAmount"
                    type="text"
                  />
                  <CommonButtons
                    content="Add"
                    background="blue"
                    btnClass="btn-send"
                    onClick={handleAddCredit}
                  />
                </div>
                <CommonButtons
                  content="Add"
                  background="blue"
                  btnClass="btn-send-2"
                  onClick={handleAddCredit}
                  style={{ marginLeft: '15px', display: 'none' }}
                />
              </div>
            </Modal.Content>
          </Modal>

          {model && (
            <Modal
              className="add-credit-modal-wrapper"
              open={model}
              onClose={() => setModel(!model)}
            >
              <Modal.Header>
                <p className="cancel-title">Add List</p>
              </Modal.Header>
              <Modal.Content>
                <div className="modal-content" style={{ padding: '12px 15px' }}>
                  Ultimate Header Subtitle
                </div>
              </Modal.Content>
            </Modal>
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  subscription: state.home.subscription
})

export default connect(mapStateToProps, {
  getSubscription
})(Campaign)
