import React, { Component } from 'react'
import InnerNavigation from '../common/InnerNavigation'
import icon from '../assets/images/settingIcon.png'
import { withRouter } from 'react-router'

const titleContent = {
  type: 'image',
  titleOne: icon,
  titleTwo: 'Settings'
}

const freePlan = [
  'Account',
  'Notifications',
  'Working Hours',
  'Calendars'
  // 'My Page'
]

const paidPlan = [
  'Account',
  'Notifications',
  'Call Forwarding',
  'Working Hours',
  'Calendars'
  // 'Schedules'
  // 'My Page'
]

const settingsData = [
  {
    mainSidebar: 'Personal',
    defaultTab: 'Account',
    innerTabs: [
      '',
      'Notifications',
      'Call Forwarding',
      'Working Hours',
      'Calendars'

      // 'My Page'
    ]
  },
  {
    mainSidebar: 'Company',
    defaultTab: 'Company',
    innerTabs: ['Company', 'Notifications']
    // innerTabs: ['Company', 'Business Hours', 'Notifications']
  },
  {
    mainSidebar: 'Platform',
    innerTabs: [
      'Calls',
      'Schedules',
      'Countries',
      // 'Auto Response',
      'BlackList',
      'Caller Id'
    ]
  },
  {
    mainSidebar: 'Quick Replies',
    innerTabs: []
  },
  {
    mainSidebar: 'Lead Qualification',
    innerTabs: []
  },
  {
    mainSidebar: 'Users & Teams',
    innerTabs: ['Users', 'Teams']
  },
  {
    mainSidebar: 'Billing',
    innerTabs: [
      'Subscription',
      'Invoices',
      'Payments',
      'Usage',
      'Account Details',
      'Payment Methods'
    ]
  },
  {
    mainSidebar: 'Integration',
    innerTabs: ['Integration']
  },
  {
    mainSidebar: 'KYC',
    defaultTab: 'KYC',
    innerTabs: ['Phone regulatory bundles']
  },
  // {
  //   mainSidebar: 'Business',
  //   defaultTab: 'Business',
  //   innerTabs: ['Business']
  // },
  {
    mainSidebar: 'API',
    innerTabs: ['API', 'Inbound webhook', 'Outbound webhook']
  },
  {
    mainSidebar: 'Audit Log',
    innerTabs: []
  },
  // {
  //   mainSidebar: 'Installation',
  //     innerTabs: ['Installation', 'Embed']
  // },
  {
    mainSidebar: 'Referrals',
    innerTabs: []
  },
  {
    mainSidebar: 'My Page',
    innerTabs: []
  }
]

const ManagersettingsData = [
  {
    mainSidebar: 'Personal',
    defaultTab: 'Personal',
    innerTabs: [
      '',
      'Notifications',
      'Call Forwarding',
      'Working Hours',
      'Calendars'
      // 'My Page'
    ]
  },
  {
    mainSidebar: 'Company',
    innerTabs: ['Company', 'Business Hours', 'Notifications']
  },
  {
    mainSidebar: 'Platform',
    innerTabs: [
      'Calls',
      'Schedules',
      // 'Countries',
      'Auto Response',
      'BlackList',
      'Caller Id',
      'Lead Qualification'
    ]
  },
  {
    mainSidebar: 'Users & Teams',
    innerTabs: ['Users', 'Teams']
  },
  {
    mainSidebar: 'Integration',
    innerTabs: ['Integration']
  },
  {
    mainSidebar: 'KYC',
    innerTabs: ['Phone regulatory bundles']
  },
  {
    mainSidebar: 'Business',
    innerTabs: ['Business']
  },
  {
    mainSidebar: 'API',
    innerTabs: ['API', 'Inbound webhook', 'Outbound webhook']
  },

  {
    mainSidebar: 'Audit Log',
    innerTabs: []
  },
  {
    mainSidebar: 'Referrals',
    innerTabs: []
  }
  // {
  //   mainSidebar: 'Installation',
  //     innerTabs: ['Installation', 'Embed']
  // }
]

const OperatorsettingsData = [
  {
    mainSidebar: 'Personal',
    innerTabs: [
      'Account',
      'Notifications',
      'Call Forwarding',
      'Working Hours',
      'Calendars'
      // 'My Page'
    ]
  }
]
class Settings extends Component {
  state = {
    navigationUser: settingsData
  }

  componentDidMount = async () => {
    document.title = 'Personal'
    var finalNavigation = settingsData
    const role_name = await localStorage.getItem('role_name')
    const plan_name = await localStorage.getItem('plan_name')
    const is_appSumo = await localStorage.getItem('is_appsumo')

    if (role_name === 'Admin') {
      finalNavigation = settingsData
    } else if (
      role_name === 'Operation Manager' ||
      role_name === 'Widget Manager' ||
      role_name === 'Manager'
    ) {
      finalNavigation = ManagersettingsData
    } else {
      finalNavigation = OperatorsettingsData
    }

    if (is_appSumo === 1 || is_appSumo === '1') {
      finalNavigation[0].innerTabs = paidPlan
    } else {
      if (plan_name === 'STARTER') {
        finalNavigation[0].innerTabs = freePlan
      } else {
        finalNavigation[0].innerTabs = paidPlan
      }
    }

    this.setState({ navigationUser: finalNavigation })
  }
  render() {
    return (
      <div className="settings-container">
        <InnerNavigation
          dataComponent={this.state.navigationUser}
          activeTab={
            this.props.settingActiveTab
              ? this.props.settingActiveTab
              : this.props.activeTab
          }
          activeComponent={
            this.props.settingActiveComponent
              ? this.props.settingActiveComponent
              : this.props.activeComponent
          }
          dataTitle={titleContent}
          settingActiveTab={this.props.settingActiveTab}
          settingActiveComponent={this.props.settingActiveComponent}
        />
      </div>
    )
  }
}

export default withRouter(Settings)
